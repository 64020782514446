import { useTranslation } from 'react-i18next';
import {
  ArrowWrapper,
  ButtonsWrapper,
  CardsWrapper,
  Container,
  DescriptionCard,
  DescriptionCardTitle,
  DescriptionLabel,
  FromToLabel,
  ResourcesButtonWrapper,
  ResourcesIcon,
  Row,
  WorkersButton,
  WorkersButtonWrapper,
  WorkersIcon,
} from './FwSingleAppointmentGeneralPage.styled';
import Button from 'components/Button/Button';
import { useState } from 'react';
import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import { useParams } from 'react-router-dom';
import { useGetSingleFwAppointment } from '../hooks';
import moment from 'moment';
import PreviewWorkersModal from './PreviewWorkersModal/PreviewWorkersModal';
import PreviewResourcesModal from './PreviewResourcesModal/PreviewResourcesModal';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { AppointmentType } from 'types/Appointment.types';
import { CaretDown, Factory, UsersThree } from '@phosphor-icons/react';

const FwSingleAppointmentGeneralPage = () => {
  const { t } = useTranslation();
  const [isWorkersModalOpen, setIsWorkersModalOpen] = useState<boolean>(false);
  const [isResourcesModalOpen, setIsResourcesModalOpen] =
    useState<boolean>(false);

  const { id } = useParams();

  const { data: appointmentData, isLoading: isLoadingAppointment } =
    useGetSingleFwAppointment(id!);

  const appointment = appointmentData?.appointment;

  const customerDetailsCardLeftData = {
    ...(appointment?.quotation && {
      Name:
        appointment?.quotation?.customer_snapshot?.shipping_data?.first_name +
        ' ' +
        appointment?.quotation?.customer_snapshot?.shipping_data?.last_name,
      'E-mail': appointment?.quotation?.customer_snapshot?.shipping_data?.email,
      Address:
        appointment?.quotation?.customer_snapshot?.shipping_data?.address
          ?.full_address,
      'Company name':
        appointment?.quotation?.customer_snapshot?.shipping_data?.company_name,
    }),
    ...(appointment?.sales_order && {
      Name:
        appointment?.sales_order?.customer_snapshot?.shipping_data?.first_name +
        ' ' +
        appointment?.sales_order?.customer_snapshot?.shipping_data?.last_name,
      'E-mail':
        appointment?.sales_order?.customer_snapshot?.shipping_data?.email,
      Address:
        appointment?.sales_order?.customer_snapshot?.shipping_data?.address
          ?.full_address,
      'Company name':
        appointment?.sales_order?.customer_snapshot?.shipping_data
          ?.company_name,
    }),
    ...(!appointment?.quotation &&
      !appointment?.sales_order &&
      appointment?.customer && {
        Name:
          appointment?.customer?.name + ' ' + appointment?.customer?.last_name,
        'E-mail': appointment?.customer?.email,
        Address: appointment?.customer?.address?.full_address,
        'Company name': appointment?.customer?.company_name,
      }),
  };

  const customerDetailsCardRightData = {
    'E-mail':
      appointment?.quotation?.customer_snapshot?.shipping_data?.email ||
      appointment?.sales_order?.customer_snapshot?.shipping_data?.email ||
      appointment?.customer?.email,
    'Phone number':
      appointment?.quotation?.customer_snapshot?.shipping_data?.phone ||
      appointment?.sales_order?.customer_snapshot?.shipping_data?.phone ||
      appointment?.customer?.phone,
  };

  const orderDetailsCardLeftData = {
    ...(appointment?.quotation && {
      'Quotation number': appointment?.quotation?.number,
      'Due date': appointment?.quotation?.valid_to
        ? moment(appointment?.quotation?.valid_to.split('T')[0]).format(
            'MMM DD, YYYY'
          )
        : null,
      'Creation date': appointment?.quotation?.created_at
        ? moment(appointment?.quotation?.created_at.split('T')[0]).format(
            'MMM DD, YYYY'
          )
        : '',
      'Closed date': appointment?.quotation?.closed_at
        ? moment(appointment?.quotation?.closed_at.split('T')[0]).format(
            'MMM DD, YYYY'
          )
        : '',
      'Sales person':
        appointment?.quotation?.user?.name +
        ' ' +
        appointment?.quotation?.user?.last_name,
      Reference: appointment?.quotation?.reference,
      'Payment condition': appointment?.quotation?.payment_condition,
    }),
    ...(appointment?.sales_order && {
      'Sales order number': appointment?.sales_order?.number,
      'Creation date': appointment?.sales_order?.date
        ? moment(appointment?.sales_order?.date?.split('T')[0]).format(
            'MMM DD, YYYY'
          )
        : null,
      'Closed date': appointment?.sales_order?.closed_date?.split('T')?.[0],
      'Sales person':
        appointment?.sales_order?.user?.name +
        ' ' +
        appointment?.sales_order?.user?.last_name,
      Reference: appointment?.sales_order?.reference,
      'Payment condition': appointment?.sales_order?.payment_condition,
    }),
  };

  return (
    <Container>
      <Row>
        <FromToLabel>
          {t('From')} {moment(appointment?.date_from).format('HH:mm')} {t('To')}{' '}
          {moment(appointment?.date_to).format('HH:mm')}
        </FromToLabel>
        <ButtonsWrapper>
          <WorkersButtonWrapper>
            <WorkersIcon>
              <Icon svg={UsersThree} size={20} color={COLORS.BLACK} />
            </WorkersIcon>
            <WorkersButton
              label={t('Workers')}
              width="200rem"
              secondary
              onClick={() => setIsWorkersModalOpen(true)}
            />
            <ArrowWrapper>
              <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
            </ArrowWrapper>
          </WorkersButtonWrapper>
          <ResourcesButtonWrapper>
            <ResourcesIcon>
              <Icon svg={Factory} size={20} color={COLORS.BLACK} />
            </ResourcesIcon>
            <Button
              onClick={() => setIsResourcesModalOpen(true)}
              label={t('Resources')}
              width="200rem"
              secondary
            />
            <ArrowWrapper>
              <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
            </ArrowWrapper>
          </ResourcesButtonWrapper>
        </ButtonsWrapper>
      </Row>
      <CardsWrapper>
        <SimpleWideCard
          cardTitle={t('Customer details')}
          leftSubtitle={t('Ship-to address').toUpperCase()}
          rightSubtitle={t('CONTACT')}
          leftData={customerDetailsCardLeftData}
          rightData={customerDetailsCardRightData}
          isLoading={isLoadingAppointment}
        />
        {appointment?.type?.name === AppointmentType.SERVICE &&
        appointment?.description ? (
          <DescriptionCard>
            <DescriptionCardTitle>
              {t('Service description')}
            </DescriptionCardTitle>
            <DescriptionLabel>{appointment?.description}</DescriptionLabel>
          </DescriptionCard>
        ) : null}
        <SimpleWideCard
          cardTitle={
            appointment?.quotation ? t('Quotation details') : t('Order details')
          }
          leftSubtitle={null}
          rightSubtitle={t('PRICE')}
          leftData={orderDetailsCardLeftData}
          rightData={null}
          isLoading={isLoadingAppointment}
        />
      </CardsWrapper>
      <PreviewWorkersModal
        isOpen={isWorkersModalOpen}
        setIsOpen={setIsWorkersModalOpen}
        onCancel={() => setIsWorkersModalOpen(false)}
        workers={appointment?.workers || []}
      />
      <PreviewResourcesModal
        setIsOpen={setIsResourcesModalOpen}
        onCancel={() => setIsResourcesModalOpen(false)}
        isOpen={isResourcesModalOpen}
        resources={appointment?.resources || []}
      />
    </Container>
  );
};

export default FwSingleAppointmentGeneralPage;
