export enum EditCustomerDetailsTab {
  GENERAL = 'General',
  CONTACT = 'Contact',
  SHIP_TO = 'Ship to',
}

export interface IEditCustomerDetails {
  name: string;
  last_name: string;
  gender: string | undefined;
  country: string;
  zip_code: string;
  house_number: string | undefined;
  street: string;
  city: string | undefined | null;
  company_name: string | undefined;
  vat_number: string | undefined;
  title: string | undefined;
}

export interface IEditCustomerContactDetails {
  email: string;
  phone: string;
}

export interface IEditCustomerShipToDetails {
  name: string;
  last_name: string;
  email: string;
  country: string;
  zip_code: string;
  house_number: string | undefined;
  street: string;
  city: string | undefined | null;
  company_name: string | undefined | null;
  title: string;
  phone: string;
}

export interface IEditCustomerDetailsDTO {
  phone_number: string;
  company_vat: string;
  title: string;
  company_name: string;
  first_name: string;
  last_name: string;
  address: {
    country: string;
    street: string;
    zip_code: string;
    city: string;
    house_number: string;
  };
  shipping_data: {
    company_name: string;
    first_name: string;
    last_name: string;
    address: {
      country: string;
      street: string;
      zip_code: string;
      city: string;
      house_number: string;
    };
    email: string;
    phone_number: string;
  };
  email: string;
}

export enum EditCustomerDetailsType {
  QUOTATION = 'QUOTATION',
  SALES_ORDER = 'SALES_ORDER',
}

export interface IEditCustomerDetailsDTO {
  phone_number: string;
  company_vat: string;
  title: string;
  company_name: string;
  first_name: string;
  last_name: string;
  address: {
    country: string;
    street: string;
    zip_code: string;
    city: string;
    house_number: string;
  };
  shipping_data: {
    company_name: string;
    first_name: string;
    last_name: string;
    address: {
      country: string;
      street: string;
      zip_code: string;
      city: string;
      house_number: string;
    };
    email: string;
    phone_number: string;
  };
  email: string;
}
