import { IQuotation, QuotationTypeIdsEnum } from 'types/Quotations.types';
import { IsDraftLabel, SwitchWrapper } from './DraftQuotationSwitch.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import { Switch } from '@mui/material';
import { useChangeQuotationType } from './hooks';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';

interface IDraftQuotationSwitchProps {
  quotation: IQuotation;
  editable?: boolean;
  canHandleMeasurements?: boolean;
}

const DraftQuotationSwitch = ({
  quotation,
  editable,
  canHandleMeasurements = false,
}: IDraftQuotationSwitchProps) => {
  const { mutate: changeQuotationType, isLoading } = useChangeQuotationType();
  const { t } = useTranslation();
  const typeId: QuotationTypeIdsEnum | undefined = quotation?.type?.id;
  const [isMakeDefinitiveModalOpen, setIsMakeDefinitiveModalOpen] =
    useState<boolean>(false);

  if (!quotation) {
    return <div />;
  }

  const getIsDraftLabel = () => {
    switch (typeId) {
      case QuotationTypeIdsEnum.DRAFT:
        return t('Draft quotation');
      case QuotationTypeIdsEnum.DEFINITIVE:
        return t('Definitive quotation');
      case QuotationTypeIdsEnum.RECURRING:
        return t('Recurring quotation');
      case undefined:
        return t('Undetermined quotation type');
    }
  };

  const getTooltipText = () => {
    switch (typeId) {
      case QuotationTypeIdsEnum.DRAFT:
        return t('This quotation is a draft');
      case QuotationTypeIdsEnum.DEFINITIVE:
        return t('This quotation is a definitive quotation');
      case QuotationTypeIdsEnum.RECURRING:
        return t('This quotation is a recurring quotation');
      case undefined:
        return t(
          `This quotation does not have a type 'draft' or 'definitive' because it was made before the update. You can handle this as you normally would, or indicate wether it's a 'draft' or 'definitive' quotation and continue in the new method`
        );
    }
  };

  const getIsChecked = () => {
    switch (typeId) {
      case QuotationTypeIdsEnum.DRAFT:
        return false;
      case QuotationTypeIdsEnum.DEFINITIVE:
        return true;
      case QuotationTypeIdsEnum.RECURRING:
        return true;
      case undefined:
        return false;
    }
  };

  const handleClick = () => {
    if (isLoading) {
      return;
    }
    switch (typeId) {
      case QuotationTypeIdsEnum.DRAFT: {
        if (canHandleMeasurements) setIsMakeDefinitiveModalOpen(true);
        else
          changeQuotationType({
            quotationId: quotation.id,
            quotationTypeId: QuotationTypeIdsEnum.DEFINITIVE,
          });
        break;
      }
      case QuotationTypeIdsEnum.DEFINITIVE: {
        changeQuotationType({
          quotationId: quotation.id,
          quotationTypeId: QuotationTypeIdsEnum.DRAFT,
        });
        break;
      }
      case QuotationTypeIdsEnum.RECURRING: {
        break;
      }
      case undefined: {
        changeQuotationType({
          quotationId: quotation.id,
          quotationTypeId: QuotationTypeIdsEnum.DRAFT,
        });
      }
    }
  };

  return (
    <>
      <SwitchWrapper>
        <IsDraftLabel isDraft={typeId === QuotationTypeIdsEnum.DRAFT}>
          {getIsDraftLabel()}
        </IsDraftLabel>
        {editable && typeId !== QuotationTypeIdsEnum.RECURRING && (
          <Tooltip content={getTooltipText()}>
            <Switch
              data-testid={'draft-switch'}
              checked={getIsChecked()}
              onChange={handleClick}
            />
          </Tooltip>
        )}
      </SwitchWrapper>
      <ConfirmModal
        title={t('Make Definitive')}
        description={`${t(
          'Are you sure you want to make this quotation definitive'
        )}?`}
        isOpen={isMakeDefinitiveModalOpen}
        setIsOpen={setIsMakeDefinitiveModalOpen}
        onConfirm={() => {
          changeQuotationType({
            quotationId: quotation.id,
            quotationTypeId: QuotationTypeIdsEnum.DEFINITIVE,
          });
          setIsMakeDefinitiveModalOpen(false);
        }}
        onCancel={() => setIsMakeDefinitiveModalOpen(false)}
        warningMessage={t(
          'Please note: When creating the draft quotation, it was indicated that not only the measurements but all fields must be checked.'
        )}
      />
    </>
  );
};

export default DraftQuotationSwitch;
