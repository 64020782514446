import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deleteProductGroup,
  getProductGroups,
} from 'services/Settings/SettingsService';

export const useGetProductGroupsInfinite = (
  perPage: number,
  searchBy?: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_PRODUCT_GROUPS_INFINITE, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getProductGroups(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useDeleteProductGroup = () => {
  const { t } = useTranslation();
  return useMutation(
    (productGroupId: number) => deleteProductGroup(productGroupId),
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_PRODUCT_GROUPS_INFINITE,
        ]);
        toast.success(t('Product group deleted'));
      },
      mutationKey: ReactMutationKeys.DELETE_PRODUCT_GROUP,
    }
  );
};
