import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TitleContainer,
  Title,
  Description,
  Date,
  BottomRow,
} from './HomePageNotificationCard.styled';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { INotification } from 'types/Notification.types';
import { RoutesConfig } from 'navigation/routes';
import { useGetNotificationTitleFromNotificationType } from './hooks';
import { useLocale } from 'utils/hooks/useLocale';
import {
  useGetNotificationDescriptionFromNotification,
  useMarkAsRead,
} from 'pages/Shared/NotificationsPage/NotificationCard/hooks';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

const HomePageNotificationCard = ({
  notification,
}: {
  notification: INotification;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { mutate: markAsRead } = useMarkAsRead();

  const handleViewClick = () => {
    markAsRead(notification.id);
    // Optimistically update the cache
    // We can't wait for isSuccessMarkAsRead because the component gets dismounted due to navigating away
    queryClient.setQueryData<number>(
      [ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT],
      (oldCount) => (oldCount != null ? oldCount - 1 : 0)
    );
    if (notification.sales_order_id) {
      navigate(
        RoutesConfig.SingleSalesOrder.fullPath.replace(
          ':id/*',
          notification.sales_order_id.toString()
        )
      );
    } else if (notification.quotation_id) {
      navigate(
        RoutesConfig.SingleQuotation.fullPath.replace(
          ':id/*',
          notification.quotation_id.toString()
        )
      );
    } else if (notification.sales_invoice_id) {
      navigate(
        RoutesConfig.SingleSalesInvoice.fullPath.replace(
          ':id/*',
          notification.sales_invoice_id.toString()
        )
      );
    } else if (notification.purchase_order_id) {
      navigate(
        RoutesConfig.SinglePurchaseOrder.fullPath.replace(
          ':id/*',
          notification.purchase_order_id.toString()
        )
      );
    } else if (notification.todo_id) {
      navigate(RoutesConfig.ToDo.fullPath, {
        state: {
          todoId: notification.todo_id,
        },
      });
    }
  };

  const notificationTitle = useGetNotificationTitleFromNotificationType(
    notification.notification_type_id
  );
  const notificationDescription =
    useGetNotificationDescriptionFromNotification(notification);

  return (
    <Container>
      <TitleContainer>
        <Title>{notificationTitle && t(notificationTitle)}</Title>
        <Date>
          {moment(notification.created_at)
            .locale(locale)
            .format('MMM DD, YYYY')}
        </Date>
      </TitleContainer>
      <BottomRow>
        <Description>{notificationDescription}</Description>
        <Button primary label={t('View')} onClick={handleViewClick} />
      </BottomRow>
    </Container>
  );
};

export default HomePageNotificationCard;
