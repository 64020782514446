import { IUpdateFrontendSettingsDTO } from 'providers/UploadPlanningPageFiltersProvider/hooks';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IGetCityAndAddressDTO } from 'utils/hooks/useGetCityAndAddress';

const BASE_URL = '/v1/common';

export const getCityAndAddress = async (
  getCityAndAddressDTO: IGetCityAndAddressDTO
): Promise<any> => {
  const queryParamsObj = {
    country_code: getCityAndAddressDTO.countryCode,
    zip_code: getCityAndAddressDTO.zipCode,
    house_number: getCityAndAddressDTO.houseNumber,
    city: getCityAndAddressDTO.city,
    street: getCityAndAddressDTO.street,
  };
  const fullUrl = getUrlWithQueryParams(`${BASE_URL}/address`, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const fetchCommonData = async (): Promise<any> => {
  const url = `${BASE_URL}/data`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const fetchCountries = async (): Promise<any> => {
  const url = `${BASE_URL}/countries`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const updateFrontendSettings = async (
  dto: IUpdateFrontendSettingsDTO
): Promise<any> => {
  const url = `${BASE_URL}/frontend-settings`;
  const { data } = await apiClient.patch<any>(url, dto);

  return data.data;
};
