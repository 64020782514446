import Button from 'components/Button/Button';
import { Field, Formik } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddProductLabel,
  ButtonsWrapper,
  Container,
  FieldWrapper,
  RowSpaceBetween,
  StyledCustomSelect,
  Margin,
  FilterTypeLabel,
  RowMarginBottomMd,
  FormikContainer,
  Row,
} from './AddNewProduct.styled';
import {
  FilterType,
  PRODUCTS_PER_PAGE,
  QUANTITY_DEFAULT_VALUE,
  STOCK_ITEMS_PER_PAGE,
} from './constants';
import {
  extractSalesPriceFromIntent,
  formatSupplierData,
  ICreateQuotationSupplierOption,
  filterAttributesAndInjectValue,
  extractExpectedPurchasePriceFromIntent,
} from './helpers';
import { useGetProductsInfinite, useGetSuppliersInfinite } from './hooks';
import { addProductSchema } from './validation';
import { useGetStockItemsInfinite } from 'pages/Manager/StockItemsPage/hooks';
import { ConfigureLogyxModal } from './ConfigureLogyxModal/ConfigureLogyxModal';
import { ICreateQuotationProductFormDTO } from '../constants';
import { DiscountType } from 'types/Discount.types';
import { toast } from 'utils/toast';
import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetSingleQuotation } from 'pages/Manager/SingleQuotation/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SUPPLIERS_PER_PAGE } from 'pages/Manager/SuppliersPage/constants';
import { useManageProductGroupsSelect } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/hooks';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { IProductGroup } from 'pages/Manager/ProductGroupsPage/types';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { RoutesConfig } from 'navigation/routes';
import { Pen } from '@phosphor-icons/react';

interface IAddNewProductProps {
  onBack: () => void;
  addProductFormDto: (product: any) => void;
  productsLength: number;
  canAddOnlyStockItems: boolean;
}

const AddNewProduct = ({
  onBack,
  addProductFormDto,
  productsLength,
  canAddOnlyStockItems,
}: IAddNewProductProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    isConfigureThroughLogyxModalOpen,
    setIsConfigureThroughLogyxModalOpen,
  ] = useState<boolean>(false);
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(
    canAddOnlyStockItems ? FilterType.STOCK_ITEMS : FilterType.PRODUCTS
  );
  const [searchBySupplier, setSearchBySupplier] = useState<string>('');
  const debouncedSearchBySupplier = useDebounce(searchBySupplier);
  const [searchByProductsStockItems, setSearchByProductsStockItems] =
    useState<string>('');
  const debouncedSearchByProductsStockItems = useDebounce(
    searchByProductsStockItems
  );
  const [selectedSupplier, setSelectedSupplier] = useState<any>('');
  const [selectedProductGroup, setSelectedProductGroup] = useState<any>('');
  const scrollPositionRef = useRef(0);
  const { id } = useParams();
  useGetSingleQuotation(id!);

  const handleAddProduct = (
    values: any,
    isValid: any,
    logyxConfigurationIntent: ILogyxConfigurationIntent | null = null
  ) => {
    if (!isValid) {
      return;
    }

    const isStockItem = selectedFilterType === FilterType.STOCK_ITEMS;
    if (isStockItem) {
      // Populate productFormDTO with sale and purchase price from stock item
      const productFormDTO: ICreateQuotationProductFormDTO = {
        uid: Math.floor(Math.random() * 10000),
        product: { name: values.product.name },
        supplier: values.product.supplier,
        quantity: QUANTITY_DEFAULT_VALUE,
        salesPrice: values.product.price,
        purchasePrice: values.product.purchase_price,
        placement: '',
        description: values.product.description,
        discount: '',
        discountType: DiscountType.PERCENTAGE,
        attributes: values.product?.attributes
          ? filterAttributesAndInjectValue(
              values.product.attributes,
              logyxConfigurationIntent
            )
          : [],
        logyxConfigurationIntent: logyxConfigurationIntent
          ? logyxConfigurationIntent
          : null,
        isStockItem: true,
        stockItemType: values.product.type.name,
        stockItemId: values.product.id,
      };
      addProductFormDto(productFormDTO);
    } else {
      const productFormDTO: ICreateQuotationProductFormDTO = {
        uid: Math.floor(Math.random() * 10000),
        product: values.product, // values.product is DefaultProduct
        supplier: values.product.supplier,
        quantity: QUANTITY_DEFAULT_VALUE,
        salesPrice: logyxConfigurationIntent
          ? extractSalesPriceFromIntent(logyxConfigurationIntent)
          : '',
        purchasePrice: logyxConfigurationIntent
          ? extractExpectedPurchasePriceFromIntent(logyxConfigurationIntent)
          : '',
        placement: '',
        description: values.product.description,
        discount: '',
        discountType: DiscountType.PERCENTAGE,
        attributes: values.product.attributes
          ? filterAttributesAndInjectValue(
              values.product.attributes,
              logyxConfigurationIntent
            )
          : [],
        logyxConfigurationIntent: logyxConfigurationIntent
          ? logyxConfigurationIntent
          : null,
        isStockItem: false,
        stockItemId: undefined,
        measurementCheck: false,
      };

      addProductFormDto(productFormDTO);
    }
  };

  const {
    data: suppliersData,
    isLoading: isLoadingSuppliers,
    hasNextPage: hasNextPageSuppliers,
    fetchNextPage: fetchNextPageSuppliers,
  } = useGetSuppliersInfinite(
    SUPPLIERS_PER_PAGE,
    debouncedSearchBySupplier,
    true
  );

  const suppliers = useMemo(() => {
    if (suppliersData?.pages?.length) {
      return suppliersData.pages.map((page) => page.suppliers).flat();
    }
    return [];
  }, [suppliersData]);

  const {
    data: productsData,
    fetchNextPage: fetchNextPageProducts,
    hasNextPage: hasNextPageProducts,
    isLoading: isLoadingProducts,
  } = useGetProductsInfinite(
    PRODUCTS_PER_PAGE,
    debouncedSearchByProductsStockItems,
    selectedSupplier?.id,
    selectedProductGroup?.id
  );
  const products = useMemo(() => {
    if (productsData?.pages?.length) {
      return productsData.pages
        .map((page) => page.products)
        .flat()
        .map((product: any) => {
          return { value: product, label: product.name, key: product.name };
        });
    }
    return [];
  }, [productsData]);

  const {
    data: stockItemsData,
    fetchNextPage: fetchNextPageStockItems,
    hasNextPage: hasNextPageStockItems,
  } = useGetStockItemsInfinite(
    STOCK_ITEMS_PER_PAGE,
    debouncedSearchByProductsStockItems,
    true, // isActive
    undefined,
    undefined,
    selectedSupplier?.id,
    undefined, // Advanced filters
    selectedProductGroup?.id
  );
  const stockItems = useMemo(() => {
    if (stockItemsData?.pages?.length) {
      return stockItemsData.pages
        .map((page) => page.stock_items)
        .flat()
        .map((stockItem: any) => {
          return {
            value: stockItem,
            label: stockItem?.name,
            key: stockItem.id,
          };
        });
    }
    return [];
  }, [stockItemsData]);

  const {
    productGroups,
    isLoadingProductGroups,
    hasNextPage: hasNextPageProductGroup,
    fetchNextPage: fetchNextPageProductGroup,
    setSearchBy: setProductGroupSearchBy,
  } = useManageProductGroupsSelect();

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          // Add empty initial values here so after calling submitForm validation errors are shown
          product: '',
          supplier: '',
          product_group: '',
        }}
        validationSchema={addProductSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          values,
          errors,
          touched,
          isValid,
          setTouched,
        }) => {
          return (
            <>
              <RowSpaceBetween>
                <AddProductLabel>
                  {t('Choose Product')} #{productsLength + 1}
                </AddProductLabel>
              </RowSpaceBetween>
              <RowMarginBottomMd>
                {canAddOnlyStockItems ? null : (
                  <FilterTypeLabel
                    data-testid="product-label"
                    isSelected={selectedFilterType === FilterType.PRODUCTS}
                    onClick={() => {
                      setSelectedFilterType(FilterType.PRODUCTS);
                      setFieldValue('product', '');
                    }}
                    marginRight={'25rem'}
                  >
                    {t('Product')}
                  </FilterTypeLabel>
                )}
                <FilterTypeLabel
                  data-testid="item-label"
                  isSelected={selectedFilterType === FilterType.STOCK_ITEMS}
                  onClick={() => {
                    setSelectedFilterType(FilterType.STOCK_ITEMS);
                    setFieldValue('product', '');
                  }}
                >
                  {t('Stock item')}
                </FilterTypeLabel>
              </RowMarginBottomMd>
              <FormikContainer>
                <Row>
                  <FieldWrapper>
                    <Field
                      pwId="select-supplier"
                      errorMessage={
                        touched['supplier'] ? errors['supplier'] : ''
                      }
                      name="supplier"
                      options={formatSupplierData(suppliers)}
                      component={StyledCustomSelect}
                      placeholder={t('Supplier')}
                      isMulti={false}
                      isSearchable={true}
                      translate={false}
                      onSelect={(
                        selectedOption: ICreateQuotationSupplierOption
                      ) => {
                        if (Number(values.supplier) !== selectedOption?.value) {
                          // If another supplier is selected reset the product input formik value, else do nothing
                          setFieldValue('product', '');
                        }
                        setSelectedSupplier(
                          selectedOption ? selectedOption.supplier : undefined
                        );
                      }}
                      onInputChange={(searchBy: string) =>
                        setSearchBySupplier(searchBy)
                      }
                      isLoading={isLoadingSuppliers}
                      onMenuScrollToBottom={() =>
                        hasNextPageSuppliers && fetchNextPageSuppliers()
                      }
                      isClearable
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Select
                      isLoading={isLoadingProductGroups}
                      name="product_group"
                      placeholder={t('Product group')}
                      isMulti={false}
                      isClearable
                      isSearchable
                      onChange={(e: Option | null) => {
                        setSelectedProductGroup(
                          e ? (e.value as IProductGroup) : (e as null)
                        );
                      }}
                      options={productGroups}
                      onInputChange={(searchBy: string) =>
                        setProductGroupSearchBy(searchBy)
                      }
                      translate={false}
                      components={{
                        MenuList: (props) => (
                          <CustomMenuList
                            {...props}
                            scrollPositionRef={scrollPositionRef}
                            label={t('Manage groups')}
                            onClick={() => {
                              navigate(RoutesConfig.ProductGroups.fullPath);
                            }}
                            onMenuScrollToBottom={() =>
                              hasNextPageProductGroup &&
                              fetchNextPageProductGroup()
                            }
                            icon={Pen}
                          />
                        ),
                      }}
                    />
                  </FieldWrapper>
                </Row>

                <Row>
                  <FieldWrapper>
                    <Field
                      pwId="select-product"
                      key={`my_unique_select_key__${selectedSupplier?.id}${selectedFilterType}`} // Force rerender react-select on supplier change to reset visible selected value
                      errorMessage={touched['product'] ? errors['product'] : ''}
                      name="product"
                      options={
                        selectedFilterType === FilterType.PRODUCTS
                          ? products
                          : stockItems
                      }
                      component={StyledCustomSelect}
                      placeholder={
                        selectedFilterType === FilterType.PRODUCTS
                          ? t('Product')
                          : t('Stock item')
                      }
                      isMulti={false}
                      isSearchable={true}
                      translate={false}
                      onSelect={(value: string) =>
                        setFieldValue('product', value)
                      }
                      onInputChange={(searchBy: string) =>
                        setSearchByProductsStockItems(searchBy)
                      }
                      onMenuScrollToBottom={() =>
                        selectedFilterType === FilterType.PRODUCTS
                          ? hasNextPageProducts && fetchNextPageProducts()
                          : hasNextPageStockItems && fetchNextPageStockItems()
                      }
                      isLoading={isLoadingProducts}
                    />
                  </FieldWrapper>
                </Row>
              </FormikContainer>
              <ButtonsWrapper>
                <Button
                  width={'200rem'}
                  onClick={() => onBack()}
                  label={t('Back')}
                  secondary
                />
                <Margin>
                  <Button
                    onClick={() => {
                      if (!values.product) {
                        const touched = {};
                        Object.keys(values).forEach((key) => {
                          touched[key] = true;
                        });
                        setTouched(touched); // Trigger validation for all fields
                      } else {
                        const valuesAsAny = values as any;
                        const isStockItem = valuesAsAny.product.product;
                        if (isStockItem) {
                          handleAddProduct(values, isValid);
                        } else {
                          if (valuesAsAny.product?.logyx_model_id) {
                            setIsConfigureThroughLogyxModalOpen(true);
                          } else {
                            handleAddProduct(values, isValid);
                          }
                        }
                      }
                    }}
                    label={t('Next')}
                    primary
                    width={'200rem'}
                  />
                </Margin>
              </ButtonsWrapper>
              <ConfigureLogyxModal
                isOpen={isConfigureThroughLogyxModalOpen}
                setIsOpen={setIsConfigureThroughLogyxModalOpen}
                selectedProduct={values.product}
                onManual={() => {
                  handleAddProduct(values, isValid);
                }}
                onLogyxConfigurationFinished={(
                  logyxConfigurationIntent: ILogyxConfigurationIntent
                ) => {
                  handleAddProduct(values, isValid, logyxConfigurationIntent);
                  toast.success(t('Added configured product to quotation'));
                }}
              />
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default AddNewProduct;
