import { Option } from 'components/Select/type';
import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Payment',
    value: 'payment',
    type: 'dropdown',
  },
  {
    label: 'Status',
    value: 'status',
    type: 'dropdown',
  },
  {
    label: 'Sub Status',
    value: 'subStatus',
    type: 'dropdown',
  },
  {
    label: 'Date',
    value: 'date',
    type: 'date',
  },
  {
    label: 'Reference',
    value: 'reference',
    type: 'string',
  },
  {
    label: 'Customer',
    value: 'customer',
    type: 'dropdown',
  },
  {
    label: 'City',
    value: 'city',
    type: 'string',
  },
  {
    label: 'Amount',
    value: 'amount',
    type: 'string',
  },
  {
    label: 'Unbilled',
    value: 'unbilled',
    type: 'string',
  },
];

export const useGetSalesOrderAdvancedFilters = (
  statusOptions: Option[],
  subStatusOptions: Option[],
  paymentStatusOptions: Option[]
) => {
  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'status') {
      return { ...filter, options: statusOptions };
    } else if (filter.value === 'subStatus') {
      return { ...filter, options: subStatusOptions };
    } else if (filter.value === 'payment') {
      return { ...filter, options: paymentStatusOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};
