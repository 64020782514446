import FwSingleAppointmentRouter from 'navigation/FwSingleAppointment/FwSingleAppointmentRouter';
import {
  Container,
  H3,
  RightSide,
  LeftSide,
  TitleContainer,
  Wrapper,
  IconsWrapper,
} from './FwSingleAppointmentPage.styled';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import { generalAppointmentSubMenuItems, subMenuItems } from './constants';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import {
  useGetSingleFwAppointment,
  useGetSingleFwAppointmentInfo,
} from './hooks';
import { getAppointmentTypeLabel } from './helpers';
import { useCurrentInterval } from './FwTimer/hooks';
import FwTimer from './FwTimer/FwTimer';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import { CaretLeft } from '@phosphor-icons/react';
import { AppointmentStatus, AppointmentType } from 'types/Appointment.types';

const FwSingleAppointmentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isReportAlreadyExistsModalOpen, setIsReportAlreadyExistsModalOpen] =
    useState<boolean>(false);
  const [
    generalAppointmentCompletedModalOpen,
    setGeneralAppointmentCompletedModalOpen,
  ] = useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const { id } = useParams();

  const { data: appointmentData, isSuccess: isSuccessGetSingleFwAppointment } =
    useGetSingleFwAppointment(id!);

  const hasReport = appointmentData?.appointment?.has_report;
  const isGeneralAppointment =
    appointmentData?.appointment?.type?.name === AppointmentType.GENERAL;
  const generalAppointmentCompleted =
    isGeneralAppointment &&
    appointmentData?.appointment?.status?.name ===
      AppointmentStatus.FIELD_WORK_FINISHED;

  useEffect(() => {
    if (isSuccessGetSingleFwAppointment) {
      hasReport && setIsReportAlreadyExistsModalOpen(true);
      generalAppointmentCompleted &&
        setGeneralAppointmentCompletedModalOpen(true);
    }
  }, [isSuccessGetSingleFwAppointment]);

  const { currentInterval } = useCurrentInterval();

  const { data: infoData } = useGetSingleFwAppointmentInfo(id!);

  return (
    <Container>
      <SubSideMenu
        subMenuItems={
          isGeneralAppointment ? generalAppointmentSubMenuItems : subMenuItems
        }
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.appointment_info)}
        isFieldWorker={true}
      />
      <Wrapper>
        <TitleContainer>
          <LeftSide>
            <Icon
              svg={CaretLeft}
              marginRight="15rem"
              color={COLORS.BLACK}
              weight="regular"
              onClick={() => navigate(RoutesConfig.FwAppointments.fullPath)}
              size={15}
            />
            <H3>
              {t(getAppointmentTypeLabel(appointmentData?.appointment))} #
              {appointmentData?.appointment?.id}
            </H3>
          </LeftSide>
          <RightSide isSpaceBetween={!!currentInterval}>
            <IconsWrapper>
              {/* <MailImg
                src={MailSent}
                alt=""
                onClick={() => setIsSendEmailModalOpen(true)}
              />
              <PhoneImg src={Phone} alt="" /> */}
            </IconsWrapper>
            <FwTimer
              appointment={appointmentData?.appointment}
              appointmentLines={appointmentData?.appointment_lines}
            />
          </RightSide>
        </TitleContainer>
        <FwSingleAppointmentRouter />
      </Wrapper>
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t(
            'Are you sure you want to send this Appointment via email to the customer'
          ) + '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={appointmentData?.appointment?.id}
        type={AddEmailNoteModalType.FW_APPOINTMENT} // TODO
      />
      <OkModal
        isOpen={isReportAlreadyExistsModalOpen}
        setIsOpen={setIsReportAlreadyExistsModalOpen}
        description={t('Report already exists') + '.'}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsReportAlreadyExistsModalOpen(false)}
      />
      <OkModal
        isOpen={generalAppointmentCompletedModalOpen}
        setIsOpen={setGeneralAppointmentCompletedModalOpen}
        description={t('General appointment already completed') + '.'}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setGeneralAppointmentCompletedModalOpen(false)}
      />
    </Container>
  );
};

export default FwSingleAppointmentPage;
