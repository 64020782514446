import { useMutation } from 'react-query';
import { getCityAndAddress } from 'services/Common/CommonService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IGetCityAndAddressDTO {
  zipCode: string;
  houseNumber: string;
  countryCode: string;
  city: string;
  street: string;
}

export const useGetCityAndAddress = () => {
  const { t } = useTranslation();

  return useMutation((data: IGetCityAndAddressDTO) => getCityAndAddress(data), {
    onError: (error: ERPError) => {
      toast.warning(t(getToastErrorMessage(error)), {
        toastId: ReactMutationKeys.GET_CITY_AND_ADDRESS,
      });
    },
    mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
  });
};
