import styled from 'styled-components';
import { COLORS, gapMd, marginMd } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';

export const Container = styled.div`
  width: 635rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10rem;
  margin-top: 60rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;

export const LeftAlign = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const DefaultFieldsDocSelectTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
`;

export const DefaultFieldsText = styled.label`
  font-size: 16rem;
  line-height: 24rem;
  font-weight: 300;
`;

export const DocSelectText = styled.label`
  width: 238rem;
  font-size: 16rem;
  line-height: 24rem;
  font-weight: 300;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.GREY_200};
  width: 100%;
`;

export const LogyxModelIdDigitalOrderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
  align-items: flex-start;
  gap: ${gapMd};
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  margin-top: ${marginMd};
`;

export const SelectWrapper = styled.div`
  margin-top: ${marginMd};
`;
