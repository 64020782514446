import { Field, Formik } from 'formik';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import {
  Container,
  ApplyWrapper,
  CloseContainer,
  TitleContainer,
  Title,
  HorizontalLine,
  FieldTitle,
  StyledCustomSelect,
  FieldWrapper,
  MultiFieldWrapper,
  HalfWrapper,
  HalfFieldWrapper,
  BlueValue,
  LabelsRow,
  LabelWrapper,
  AddLabelDropdownWrapper,
  ContentContainer,
} from './AddEditToDoModal.styled';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Close } from 'components/Close/Close';
import { PriorityOptions } from 'components/Table/Cells/SelectCell/constants';
import { useGetUsersSummaryInfinite } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useCreateToDo,
  useGetSingleToDo,
  useGetToDoTypes,
  useRelatedTypeOptions,
  useUpdateToDo,
} from '../hooks';
import { RelatedTypeOptions } from '../constants';
import { IToDoDTO, TodoRelatedTypeId } from 'types/ToDo.types';
import { Option } from 'components/Select/type';
import { addToDoSchema, editToDoSchema } from './validation';
import { USERS_PER_PAGE } from './constants';
import { ILabel, LabelEntityTypeId } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { normalizeValues } from './helpers';

interface InitialValues {
  title: string;
  description: string;
  type_id?: string | null;
  priority_id: string | null;
  assigned_to_user_id: Option | null;
  related_to_id: Option | null;
  related_type_id: string | null;
  labels: ILabel[];
}

export interface IAddEditToDoModalProps {
  isOpen: boolean;
  id?: string;
  isEditMode: boolean;
  relatedTypeId: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setForceUpdate: Dispatch<SetStateAction<number>>;
  onConfirm?: () => void;
}

const AddEditToDoModal = ({
  isOpen,
  id = '',
  isEditMode,
  relatedTypeId,
  setIsOpen,
  setForceUpdate,
}: IAddEditToDoModalProps) => {
  const { t } = useTranslation();
  const [relatedTypeSearchBy, setRelatedTypeSearchBy] = useState<string>('');
  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(userSearchBy);
  const [relatedType, setRelatedType] = useState<string>(relatedTypeId);
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const { data } = useGetSingleToDo(id, isOpen && isEditMode);

  const {
    relatedToOptions,
    fetchNextPageQuotations,
    fetchNextPageSalesOrders,
    fetchNextPageSalesInvoices,
    fetchNextPagePurchaseOrders,
    fetchNextPageCustomers,
    hasNextPageQuotations,
    hasNextPageSalesOrders,
    hasNextPageSalesInvoices,
    hasNextPagePurchaseOrders,
    hasNextPageCustomers,
    isLoadingRelatedOptions,
  } = useRelatedTypeOptions(relatedType, relatedTypeSearchBy);

  const { mutate: createToDo, isLoading: isLoadingCreateToDo } = useCreateToDo({
    onSuccess: () => {
      setForceUpdate((prev) => prev + 1);
    },
  });

  const { mutate: updateToDo, isLoading: isLoadingUpdateToDo } = useUpdateToDo({
    onSuccess: () => {
      setForceUpdate((prev) => prev + 1);
    },
  });

  const { data: toDoTypesOptions } = useGetToDoTypes();

  const isLoadingActions = isLoadingCreateToDo || isLoadingUpdateToDo;

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(USERS_PER_PAGE, debouncedSearchBy, true);

  const users = useMemo(() => {
    if (usersData?.pages?.length) {
      return usersData.pages
        .map((page) => page.users)
        .flat()
        .map((user: any) => {
          return {
            value: user?.id,
            label: user.name + ' ' + user.last_name,
          };
        });
    }
    return [];
  }, [usersData]);

  const getInitialValues = (): InitialValues => {
    const initialData = {
      title: data?.todo?.title || '',
      description: data?.todo?.description || '',
      type_id:
        toDoTypesOptions?.find(
          (op: any) => op.key === data?.todo?.todo_type?.name
        )?.value ?? null,
      priority_id:
        PriorityOptions.find((op) => op.key === data?.todo?.todo_priority?.name)
          ?.value ?? null,
      assigned_to_user_id: data?.todo?.assigned_to
        ? {
            label: `${data?.todo?.assigned_to?.name} ${data?.todo?.assigned_to?.last_name}`,
            value: data?.todo?.assigned_to?.id,
          }
        : null,
      related_to_id: data?.todo?.related_to_id
        ? {
            label: data?.todo?.related_to_number,
            value: data?.todo?.related_to_id,
          }
        : null,
      related_type_id: data?.todo?.related_type_id ?? null,
      labels: data?.todo?.labels || [],
    };

    return initialData;
  };

  const handleToDoAction = (values: any) => {
    if (data?.todo?.todo_type?.name === 'FIELD_WORKER') {
      delete values.type_id;
    }

    const finaleValues = normalizeValues(values);

    const createUpdateToDoDTO: IToDoDTO = {
      title: finaleValues.title,
      description: finaleValues.description,
      type_id: finaleValues.type_id,
      priority_id: finaleValues.priority_id,
      assigned_to_user_id: finaleValues.assigned_to_user_id || null,
      related_to_id: finaleValues.related_to_id || null,
      related_type_id: finaleValues.related_type_id || null,
      label_ids: finaleValues.labels.map((label: ILabel) => label.id),
    };

    isEditMode
      ? updateToDo({
          id,
          updateToDoData: createUpdateToDoDTO,
        })
      : createToDo(createUpdateToDoDTO);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto', padding: '20rem' }}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('To Do')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            // onSubmit
          }}
          initialValues={getInitialValues()}
          validationSchema={isEditMode ? editToDoSchema : addToDoSchema}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          validate={(values) => {
            // Custom validation for relatedTo and relatedType
            const errors: any = {};
            if (values.related_type_id && !values.related_to_id) {
              errors.related_to_id =
                'Related type must be provided together with Related to';
            }
            return errors;
          }}
        >
          {({
            errors,
            setFieldValue,
            values,
            isValid,
            handleBlur,
            touched,
          }) => {
            return (
              <Container key={data}>
                <ContentContainer>
                  <FieldTitle>{t('toDoTitle')}</FieldTitle>
                  <FormikInput
                    name="title"
                    pwId="title-field"
                    errorMessage={
                      areAllFieldsTouched || touched['title']
                        ? errors['title']
                        : ''
                    }
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('title', value);
                      handleBlur(e);
                    }}
                    height={'41rem'}
                    placeholder={t('toDoTitle')}
                  />
                  <HorizontalLine />
                  <FieldTitle>{t('Description')}</FieldTitle>
                  <FormikInput
                    pwId="description-field"
                    isTextArea
                    errorMessage={
                      areAllFieldsTouched || touched['description']
                        ? errors['description']
                        : ''
                    }
                    height={'60rem'}
                    name="description"
                    placeholder={t('Description')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('description', value);
                      handleBlur(e);
                    }}
                  />
                  <HorizontalLine />
                  <FieldTitle>{t('Type')}</FieldTitle>
                  {/* FIELD_WORKER is only set when a todo is created through an report */}
                  {data?.todo?.todo_type?.name === 'FIELD_WORKER' ? (
                    <BlueValue>{t('Field worker')}</BlueValue>
                  ) : (
                    <Field
                      pwId={'type-field'}
                      errorMessage={
                        areAllFieldsTouched || touched['type_id']
                          ? errors['type_id']
                          : ''
                      }
                      height={'41rem'}
                      width="100%"
                      name="type_id"
                      options={toDoTypesOptions}
                      selectedValue={values?.type_id}
                      component={StyledCustomSelect}
                      placeholder={t('Type')}
                      isMulti={false}
                      onSelect={(obj: any) => {
                        setFieldValue('type_id', Number(obj.value));
                      }}
                    />
                  )}
                  <HorizontalLine />
                  <FieldTitle>{t('Priority')}</FieldTitle>
                  <FieldWrapper>
                    <Field
                      pwId={'priority-field'}
                      errorMessage={
                        areAllFieldsTouched || touched['priority_id']
                          ? errors['priority_id']
                          : ''
                      }
                      height={'41rem'}
                      width="100%"
                      name="priority_id"
                      selectedValue={values?.priority_id}
                      options={PriorityOptions}
                      component={StyledCustomSelect}
                      placeholder={t('Priority')}
                      isMulti={false}
                      onSelect={(obj: any) => {
                        setFieldValue('priority_id', Number(obj.value));
                      }}
                    />
                  </FieldWrapper>
                  <HorizontalLine />
                  <FieldTitle>{t('Labels')}</FieldTitle>
                  <LabelsRow>
                    <LabelWrapper>
                      {values.labels?.map((label: ILabel) => {
                        return (
                          <EntityLabel
                            key={label.id}
                            label={label}
                            tooltipContent={
                              label.name.length > 15
                                ? label.name
                                : label.description
                            }
                            onDelete={() =>
                              setFieldValue(
                                'labels',
                                values.labels?.filter(
                                  (currentLabel: ILabel) =>
                                    currentLabel.id !== label.id
                                )
                              )
                            }
                          />
                        );
                      })}
                    </LabelWrapper>
                    <AddLabelDropdownWrapper
                      $withoutMargin={values?.labels.length === 0}
                    >
                      <AddLabelDropdown
                        inModal={true}
                        entityType={LabelEntityTypeId.TODO}
                        onClick={(newLabel: ILabel) => {
                          const labelExists = values.labels?.some(
                            (label: ILabel) => label.id === newLabel.id
                          );
                          if (!labelExists)
                            setFieldValue('labels', [
                              ...values.labels,
                              newLabel,
                            ]);
                        }}
                      />
                    </AddLabelDropdownWrapper>
                  </LabelsRow>
                  <HorizontalLine />
                  <FieldTitle>{t('Assigned to')}</FieldTitle>
                  <FieldWrapper>
                    <Field
                      pwId={'assigned_to-field'}
                      errorMessage={
                        areAllFieldsTouched || touched['assigned_to_user_id']
                          ? errors['assigned_to_user_id']
                          : ''
                      }
                      height={'41rem'}
                      width="100%"
                      name="assigned_to_user_id"
                      options={users}
                      isClearable={true}
                      defaultValue={values?.assigned_to_user_id ?? undefined}
                      component={StyledCustomSelect}
                      placeholder={t('Assigned to')}
                      menuPlacement={'top'}
                      isMulti={false}
                      translate={false}
                      onSelect={(option: Option) => {
                        setFieldValue('assigned_to_user_id', option?.value);
                      }}
                      onInputChange={(e: string) => {
                        setUserSearchBy(e);
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageUsers && fetchNextPageUsers()
                      }
                      isLoading={isLoadingUsers}
                    />
                  </FieldWrapper>
                  <HorizontalLine />
                  <MultiFieldWrapper>
                    <HalfWrapper>
                      <FieldTitle>{t('Related type')}</FieldTitle>
                      <HalfFieldWrapper>
                        <Field
                          key={relatedType}
                          pwId={'related_type_id-field'}
                          errorMessage={
                            areAllFieldsTouched || touched['related_type_id']
                              ? errors['related_type_id']
                              : ''
                          }
                          height={'41rem'}
                          width="100%"
                          name="related_type_id"
                          options={RelatedTypeOptions}
                          isClearable={true}
                          menuPlacement={'top'}
                          selectedValue={values?.related_type_id}
                          component={StyledCustomSelect}
                          placeholder={t('Related type')}
                          isMulti={false}
                          onSelect={(option: Option) => {
                            if (option === null) {
                              setFieldValue('related_type_id', null);
                              setFieldValue('related_to_id', null);
                              setRelatedType('');
                            } else {
                              setRelatedType(option?.value);
                              setFieldValue('related_type_id', option?.value);
                            }
                          }}
                        />
                      </HalfFieldWrapper>
                    </HalfWrapper>
                    <HalfWrapper>
                      <FieldTitle>{t('Related to')}</FieldTitle>
                      <HalfFieldWrapper>
                        <Field
                          key={relatedType}
                          disabled={!values?.related_type_id}
                          pwId={'related_to_id-field'}
                          errorMessage={
                            areAllFieldsTouched || touched['related_to_id']
                              ? errors['related_to_id']
                              : ''
                          }
                          height={'41rem'}
                          width="100%"
                          name="related_to_id"
                          translate={false}
                          isClearable={true}
                          menuPlacement={'top'}
                          options={relatedToOptions}
                          defaultValue={values?.related_to_id}
                          component={StyledCustomSelect}
                          placeholder={t('Related to number')}
                          isMulti={false}
                          onSelect={(option: Option) => {
                            if (option === null) {
                              setFieldValue('related_to_id', null);
                            } else {
                              setFieldValue('related_to_id', option?.value);
                            }
                          }}
                          onInputChange={(e: string) =>
                            setRelatedTypeSearchBy(e)
                          }
                          onMenuScrollToBottom={() => {
                            if (relatedType === TodoRelatedTypeId.QUOTATION) {
                              hasNextPageQuotations &&
                                fetchNextPageQuotations();
                            }
                            if (relatedType === TodoRelatedTypeId.SALES_ORDER) {
                              hasNextPageSalesOrders &&
                                fetchNextPageSalesOrders();
                            }
                            if (
                              relatedType === TodoRelatedTypeId.SALES_INVOICE
                            ) {
                              hasNextPageSalesInvoices &&
                                fetchNextPageSalesInvoices();
                            }
                            if (
                              relatedType === TodoRelatedTypeId.PURCHASE_ORDER
                            ) {
                              hasNextPagePurchaseOrders &&
                                fetchNextPagePurchaseOrders();
                            }
                            if (relatedType === TodoRelatedTypeId.CUSTOMER) {
                              hasNextPageCustomers && fetchNextPageCustomers();
                            }
                          }}
                          isLoading={isLoadingRelatedOptions}
                        />
                      </HalfFieldWrapper>
                    </HalfWrapper>
                  </MultiFieldWrapper>
                </ContentContainer>
                <ApplyWrapper>
                  <Button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={isLoadingActions}
                    onClick={() => {
                      setAreAllFieldsTouched(true);
                      if (isValid) {
                        handleToDoAction(values);
                        setIsOpen(false);
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Save')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddEditToDoModal;
