import apiClient from '../api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IAddEditProductDTO } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/hooks';

const BASE_URL = '/v1/products/';

export const getProducts = async (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: string,
  productGroupId?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    supplier_id: supplierId,
    ...(productGroupId && { product_group_id: productGroupId }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const addProduct = async (
  addProductData: IAddEditProductDTO
): Promise<any> => {
  const url = BASE_URL;
  const formData = new FormData();
  formData.append('name', addProductData.name);
  formData.append('description', addProductData.description);
  formData.append(
    'digital_order_enabled',
    (!!addProductData.digital_order_enabled).toString()
  );
  if (addProductData.product_group_id)
    formData.append(
      'product_group_id',
      addProductData.product_group_id.toString()
    );
  if (addProductData.supplier_id)
    formData.append('supplier_id', addProductData.supplier_id);
  if (addProductData.logyx_model_id)
    formData.append('logyx_model_id', addProductData.logyx_model_id);
  if (addProductData.files && addProductData.files.length) {
    addProductData.files.forEach((file: any) => {
      formData.append('files', file);
    });
  }
  if (addProductData.attributes && addProductData.attributes.length) {
    formData.append('attributes', JSON.stringify(addProductData.attributes));
  } else {
    formData.append('attributes', '[]');
  }

  const { data } = await apiClient.post<any>(url, formData);

  return data.data;
};

export const editProduct = async (
  editProductData: IAddEditProductDTO
): Promise<any> => {
  const url = BASE_URL + editProductData.product_id;

  const formData = new FormData();
  formData.append('name', editProductData.name);
  formData.append('description', editProductData.description);
  formData.append(
    'digital_order_enabled',
    (!!editProductData.digital_order_enabled).toString()
  );

  formData.append(
    'product_group_id',
    editProductData.product_group_id
      ? editProductData.product_group_id.toString()
      : ''
  );
  if (editProductData.logyx_model_id)
    formData.append('logyx_model_id', editProductData.logyx_model_id);
  if (editProductData.files && editProductData.files.length) {
    editProductData.files.forEach((file: any) => {
      formData.append('files', file);
    });
  }
  if (editProductData.attributes && editProductData.attributes.length) {
    formData.append('attributes', JSON.stringify(editProductData.attributes));
  } else {
    formData.append('attributes', '[]');
  }

  if (editProductData.deleted_attachment_ids) {
    const deletedAttachmentIds = JSON.stringify(
      editProductData.deleted_attachment_ids
    );

    formData.append('deleted_attachment_ids', deletedAttachmentIds);
  }

  const { data } = await apiClient.put<any>(url, formData);

  return data.data;
};

export const deleteProduct = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};
