import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'utils/toast';
import { markAsRead } from 'services/Notification/NotificationService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IRootReducerState } from 'store/store';
import { INotification, INotificationType } from 'types/Notification.types';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../types';

export const useGetNotificationTitleFromNotificationType = (
  notificationTypeId: number
) => {
  const { t } = useTranslation();
  const notificationTypes = useSelector(
    (state: IRootReducerState) => state.commonInfo.notificationTypes
  );
  if (!notificationTypes.length) {
    return '';
  }
  const notificationTypeName = notificationTypes.find(
    (notificationType: INotificationType) =>
      notificationType.id === notificationTypeId
  ).name;

  return notificationTypeName ? t(notificationTypeName) : '';
};

export const useMarkAsRead = () =>
  useMutation((notificationId: number) => markAsRead(notificationId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.MARK_NOTIFICATION_AS_READ,
  });

export const useGetNotificationDescriptionFromNotification = (
  notification: INotification
) => {
  const { t } = useTranslation();
  const notificationTypes = useSelector(
    (state: IRootReducerState) => state.commonInfo.notificationTypes
  );
  if (!notificationTypes.length) {
    return '';
  }
  const notificationTypeName = notificationTypes.find(
    (notificationType: INotificationType) =>
      notificationType.id === notification.notification_type_id
  )?.name;
  const notificationDescription = notificationTypeName
    ? t(`${notificationTypeName}_DESC`)
    : '';

  switch (notificationTypeName) {
    case NotificationType.QUOTATION_ACCEPTED:
    case NotificationType.QUOTATION_REJECTED:
    case NotificationType.QUOTATION_EXPIRES_SOON:
      return notification.payload?.quotation_number
        ? notificationDescription.replace(
            '{quotation_number}',
            notification.payload.quotation_number
          )
        : '';
    case NotificationType.TODO_ASSIGNED:
    case NotificationType.TODO_COMPLETED:
      return notification.payload?.todo_title
        ? notificationDescription.replace(
            '{todo_title}',
            notification.payload.todo_title
          )
        : '';
    case NotificationType.REPORT_AVAILABLE:
      return notification.payload?.customer_last_name
        ? notificationDescription.replace(
            '{customer_last_name}',
            notification.payload.customer_last_name
          )
        : '';
    case NotificationType.PURCHASE_ORDER_RECEIVED:
      return notification.payload?.purchase_order_number
        ? notificationDescription.replace(
            '{purchase_order_number}',
            notification.payload.purchase_order_number
          )
        : '';
    case NotificationType.SALES_ORDER_AVAILABLE:
      return notification.payload?.sales_order_number
        ? notificationDescription.replace(
            '{sales_order_number}',
            notification.payload.sales_order_number
          )
        : '';
    case NotificationType.APPOINTMENT_CONFIRMED:
      return notification.payload?.customer_last_name &&
        notification.payload?.appointment_type
        ? notificationDescription
            .replace(
              '{customer_last_name}',
              notification.payload.customer_last_name
            )
            .replace(
              '{appointment_type}',
              t(notification.payload.appointment_type).toLowerCase()
            )
        : '';
    case NotificationType.SALES_ORDER_COMPLETED:
      return notification.payload?.customer_first_name
        ? notificationDescription.replace(
            '{customer_first_name}',
            notification.payload.customer_first_name
          )
        : '';
    case NotificationType.DOWNPAYMENT_INVOICE_PAID:
      return notification.payload?.invoice_number
        ? notificationDescription.replace(
            '{invoice_number}',
            notification.payload.invoice_number
          )
        : '';
    default:
      return '';
  }
};
