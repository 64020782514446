import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getSingleStockItem,
  getStockItems,
} from 'services/StockItems/StockItemsService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IStockItemsPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';

export const useGetStatusOptions = () => {
  const statusOptions: Option[] = [
    { value: '', label: 'All', key: 'all' },
    { value: 'ACTIVE', label: 'Active', key: 'active' },
    { value: 'DELETED', label: 'Deleted', key: 'deleted' },
  ];
  return statusOptions;
};

export const useGetStockItems = (
  page: number,
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  supplierId?: number,
  advancedFilters?: string,
  productGroupId?: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_STOCK_ITEMS,
      page,
      perPage,
      searchBy,
      isActive,
      sortBy,
      sortDirection,
      supplierId,
      advancedFilters,
      productGroupId,
    ],
    queryFn: () => {
      return getStockItems(
        page,
        perPage,
        searchBy,
        isActive,
        sortBy,
        sortDirection,
        supplierId,
        advancedFilters,
        productGroupId
      );
    },
  });

export const useGetSingleStockItem = (stockItemId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_STOCK_ITEM, stockItemId],
    queryFn: () => {
      return getSingleStockItem(stockItemId);
    },
  });

export const useGetStockItemsInfinite = (
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  supplierId?: number,
  advancedFilters?: string,
  productGroupId?: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_STOCK_ITEMS_INFINITE,
      perPage,
      searchBy,
      isActive,
      sortBy,
      sortDirection,
      supplierId,
      productGroupId,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getStockItems(
        pageParam,
        perPage,
        searchBy,
        isActive,
        sortBy,
        sortDirection,
        supplierId,
        advancedFilters,
        productGroupId
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    enabled: !!supplierId || !!productGroupId,
  });

export const useGetStockItemsPageFilters = () => {
  const dispatch = useDispatch();
  const stockItemsPageFilters: IStockItemsPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.stockItemsPage
  );

  const statusOptions = useGetStatusOptions();

  if (!stockItemsPageFilters) {
    const initialStockItemsPageFilters: IStockItemsPageFilters = {
      page: 1,
      searchBy: '',
      selectedStatusOption:
        statusOptions.find((option) => option.key === 'active') ||
        statusOptions[0],
      sortBy: undefined,
      sortDirection: undefined,
      advancedFilters: '',
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.STOCK_ITEMS,
        data: initialStockItemsPageFilters,
      })
    );
    return {
      stockItemsPageFilters: initialStockItemsPageFilters,
      statusOptions,
    };
  }

  return {
    stockItemsPageFilters,
    statusOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IStockItemsPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOption, setSelectedStatusOption] = useState<Option>(
    initialFilters.selectedStatusOption
  );
  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );
  const [advancedFilters, setAdvancedFilters] = useState<string>(
    initialFilters.advancedFilters
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [searchBy, selectedStatusOption, sortBy, sortDirection]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IStockItemsPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      selectedStatusOption,
      sortBy,
      sortDirection,
      advancedFilters,
    };
  }, [searchBy, selectedStatusOption, sortBy, sortDirection, advancedFilters]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.STOCK_ITEMS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
  };
};
