import {
  ActionsWrapper,
  Container,
  DeleteIcon,
  DeleteLabel,
  Description,
  EditDeleteWrapperRelative,
  EditIcon,
  EditLabel,
  Name,
  Row,
  RowGap,
} from './ProductGroupRow.styled';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { IProductGroup } from '../types';

interface IProductGroupRowProps {
  productGroup: IProductGroup;
  isLast: boolean;
  onEditClick: (productGroup: IProductGroup) => void;
  onDeleteClick: (productGroupId: number) => void;
}

export const ProductGroupRow = ({
  productGroup,
  isLast,
  onEditClick,
  onDeleteClick,
}: IProductGroupRowProps) => {
  const { t } = useTranslation();

  return (
    <Container withoutLine={isLast}>
      <Row>
        <RowGap>
          <Name>{productGroup.name}</Name>
          <Description>{productGroup.description}</Description>
        </RowGap>
        <ActionsWrapper>
          <EditDeleteWrapperRelative onClick={() => onEditClick(productGroup)}>
            <EditLabel>{t('Edit')}</EditLabel>
            <EditIcon>
              <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
            </EditIcon>
          </EditDeleteWrapperRelative>
          <EditDeleteWrapperRelative
            onClick={() => onDeleteClick(productGroup.id)}
          >
            <DeleteLabel>{t('Delete')}</DeleteLabel>
            <DeleteIcon>
              <Icon
                svg={Trash}
                size={18}
                color={COLORS.RED_200}
                weight="regular"
              />
            </DeleteIcon>
          </EditDeleteWrapperRelative>
        </ActionsWrapper>
      </Row>
    </Container>
  );
};
