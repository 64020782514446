import { H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import CustomSelect from 'components/Select/FormikSelect';
import { Option } from 'components/Select/type';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICountry } from 'store/Common/types';
import { useTranslation } from 'react-i18next';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { useCreateSupplier } from './hooks';
import {
  AddSupplierWrapper,
  ButtonCreate,
  ButtonsWrapper,
  Container,
  FormikContainer,
  Row,
  StyledSelectNoMargin,
  SupplierDetailsContainer,
  SupplierDetailsLabel,
} from './NewSupplierPage.styled';
import { ICreateSupplierDTO } from './types';
import { createSupplierSchema } from './validation';
import { ICreateAddressDTO } from 'types/Address.types';
import debounce from 'lodash.debounce';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import { RoutesConfig } from 'navigation/routes';
import {
  IGetCityAndAddressDTO,
  useGetCityAndAddress,
} from 'utils/hooks/useGetCityAndAddress';
import { isValidGetCityAndAddressData } from '../NewQuotationPage/CustomerTab/helpers';

const NewSupplierPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const handleCancel = () => {
    navigate(RoutesConfig.Suppliers.fullPath);
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const countryOptions: Option[] = useGetCountryOptions();

  const { data, mutate, isSuccess } = useCreateSupplier();

  useEffect(() => {
    if (isSuccess) {
      navigate(
        `${SingleSupplierRoutes.SingleSupplierGeneral.fullPath.replace(
          ':id',
          data.supplier.id
        )}`
      );
    }
  }, [isSuccess]);

  const handleCreateSupplier = async (values: any, isValid: any) => {
    if (!isValid) {
      return;
    }

    const address: ICreateAddressDTO = {
      country: values.country,
      street: values.street,
      zip_code: values.code,
      city: values.city,
      house_number: values.house_number,
    };
    const createSupplierData: ICreateSupplierDTO = {
      contact_person: values.contact_person,
      company_name: values.company_name,
      email: values.email,
      vat_number: values.vat_number,
      phone: values.phone,
      address: address,
      coc_number: values.coc_number,
    };

    mutate(createSupplierData);
  };

  const initialCountry: ICountry = useGetInitialCountry();

  // Get city and address handling
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: isSuccessGetCityAndAddress,
    isLoading: isLoadingGetCityAndAddress,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddress &&
      cityAndAddressData &&
      !formikRef?.current.values.city
    ) {
      formikRef?.current.setFieldValue('city', cityAndAddressData.address.city);
      if (
        formikRef?.current.values.house_number &&
        formikRef?.current.values.street
      )
        getCityAndAddress({
          countryCode: formikRef?.current.values.country,
          zipCode: formikRef?.current.values.code,
          houseNumber: formikRef?.current.values.house_number,
          city: cityAndAddressData.address.city,
          street: formikRef?.current.values.street,
        });
    }
  }, [isSuccessGetCityAndAddress]);

  const debouncedGetCityAndAddress = debounce(
    (getCityAndAddressData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressData))
        getCityAndAddress({
          countryCode: getCityAndAddressData.countryCode,
          zipCode: getCityAndAddressData.zipCode,
          houseNumber: getCityAndAddressData.houseNumber,
          city: getCityAndAddressData.city,
          street: getCityAndAddressData.street,
        });
    },
    1000
  );

  return (
    <Container>
      <AddSupplierWrapper>
        <H3>{t('Create supplier')}</H3>
      </AddSupplierWrapper>
      <SupplierDetailsContainer>
        <SupplierDetailsLabel>{t('Supplier details')}</SupplierDetailsLabel>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            // Add empty initial values here so after calling submitForm validation errors are shown
            email: '',
            country: initialCountry.cca2,
            street: '',
            phone: '',
            address: '',
            code: '',
            city: '',
            house_number: '',
            contact_person: '',
            company_name: '',
            vat_number: '',
            coc_number: '',
          }}
          validationSchema={createSupplierSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleCreateSupplier}
          validateOnMount={true}
        >
          {({
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
            setTouched,
          }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    pwId={'contact-person-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['contact_person']
                        ? errors['contact_person']
                        : ''
                    }
                    height={'41rem'}
                    name="contact_person"
                    placeholder={t('Contact person')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('contact_person', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                  <Input
                    pwId={'phone-number-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['phone']
                        ? errors['phone']
                        : ''
                    }
                    height={'41rem'}
                    name="phone"
                    placeholder={t('Phone number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('phone', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    pwId={'company-name-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['company_name']
                        ? errors['company_name']
                        : ''
                    }
                    height={'41rem'}
                    name="company_name"
                    placeholder={t('Company name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('company_name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                  <Input
                    pwId={'company-vat-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['vat_number']
                        ? errors['vat_number']
                        : ''
                    }
                    height={'41rem'}
                    name="vat_number"
                    placeholder={t('Company VAT')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('vat_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    pwId={'email-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['email']
                        ? errors['email']
                        : ''
                    }
                    height={'41rem'}
                    name="email"
                    placeholder={t('E-mail')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('email', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                  <Input
                    pwId={'coc-number-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['coc_number']
                        ? errors['coc_number']
                        : ''
                    }
                    height={'41rem'}
                    name="coc_number"
                    placeholder={t('COC number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('coc_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'zip-code-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['code']
                        ? errors['code']
                        : ''
                    }
                    height={'41rem'}
                    name="code"
                    placeholder={t('Zip code')}
                    onBlur={(e: any) => {
                      const value = e.target.value.trim();
                      setFieldValue('code', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: value,
                          houseNumber: values.city ? values.house_number : '',
                          city: values.city,
                          street: values.city ? values.street : '',
                        });
                      }
                    }}
                  />
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'house-number-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['house_number']
                        ? errors['house_number']
                        : ''
                    }
                    height={'41rem'}
                    name="house_number"
                    placeholder={t('House number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('house_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.street && values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: value,
                          city: values.city,
                          street: values.street,
                        });
                      }
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'city-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['city']
                        ? errors['city']
                        : ''
                    }
                    height={'41rem'}
                    name="city"
                    placeholder={t('City')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('city', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: values.house_number,
                          city: value,
                          street: values.street,
                        });
                      }
                    }}
                  />
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'street-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['street']
                        ? errors['street']
                        : ''
                    }
                    height={'41rem'}
                    name="street"
                    placeholder={t('Street')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('street', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.city && values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: values.house_number,
                          city: values.city,
                          street: value,
                        });
                      }
                    }}
                  />
                </Row>
                <Row>
                  <StyledSelectNoMargin
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'country-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['country']
                        ? errors['country']
                        : ''
                    }
                    height={'41rem'}
                    name="country"
                    width="100%"
                    options={countryOptions}
                    component={CustomSelect}
                    isMulti={false}
                    onSelect={(value: any) => {
                      setFieldValue('country', value);
                      debouncedGetCityAndAddress({
                        countryCode: value.value,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: values.city,
                        street: values.street,
                      });
                    }}
                  />
                </Row>
                <ButtonsWrapper>
                  <Button
                    width={'200rem'}
                    onClick={() => handleCancel()}
                    label={t('Back')}
                    secondary
                  />
                  <ButtonCreate
                    data-testid="create-button"
                    width={'200rem'}
                    onClick={() => {
                      if (isValid) {
                        setIsConfirmModalOpen(true);
                      } else {
                        if (!areAllFieldsTouched) {
                          setAreAllFieldsTouched(true);
                        }
                      }
                    }}
                    label={t('Create')}
                    primary
                  />
                </ButtonsWrapper>
                <ConfirmModal
                  title={t('New Supplier')}
                  description={`${t(
                    'Are you sure you want to add a New Supplier'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => {
                    const touched = {};
                    Object.keys(values).forEach((key) => {
                      touched[key] = true;
                    });
                    setTouched(touched); // Trigger validation for all fields
                    handleCreateSupplier(values, isValid); // Handle form submission
                    setIsConfirmModalOpen(false);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
              </FormikContainer>
            );
          }}
        </Formik>
      </SupplierDetailsContainer>
    </Container>
  );
};

export default NewSupplierPage;
