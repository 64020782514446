import { Option } from 'components/Select/type';
import { SalesOrderStatusType } from 'pages/Manager/SingleSalesOrder/constants';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrderPortfolioAnalytic,
  getPurchaseOrderAnalytic,
  getQuotationsAnalytic,
  getSalesInvoicesAnalytic,
  getSalesOrdersAnalytic,
  getStockItemsAnalytic,
} from 'services/Analytic/AnalyticService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IAnalyticsPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { useGetDateIntervalOptions } from 'utils/hooks/useGetDateIntervalOptions';
import { useGetExpectedInvoiceDateOptions } from 'utils/hooks/useGetExpectedInvoiceDateIntervalOptions';
import { useGetSalesOrderStatuses } from 'utils/hooks/useGetSalesOrderStatuses';

export const useGetAnalyticsPageFilters = () => {
  const dispatch = useDispatch();
  const analyticsPageFilters: IAnalyticsPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.analyticsPage
  );
  const dateOptions: Option[] = useGetDateIntervalOptions();
  const expectedInvoiceDateOptions: Option[] =
    useGetExpectedInvoiceDateOptions();
  const subStatusOptions = useGetSalesOrderStatuses(
    SalesOrderStatusType.SUBSTATUS,
    true
  );

  if (analyticsPageFilters === null) {
    const initialAnalyticsPageFilters: IAnalyticsPageFilters = {
      selectedSalesOrders: dateOptions[0],
      selectedOrderPortfolio: '',
      selectedOrderPortfolioExpectedInvoiceDate: expectedInvoiceDateOptions[0],
      selectedInvoices: dateOptions[1],
      selectedQuotations: dateOptions[0],
    };

    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.ANALYTICS,
        data: initialAnalyticsPageFilters,
      })
    );

    return {
      analyticsPageFilters: initialAnalyticsPageFilters,
      dateOptions,
      subStatusOptions,
      expectedInvoiceDateOptions,
    };
  }
  return {
    analyticsPageFilters,
    dateOptions,
    subStatusOptions,
    expectedInvoiceDateOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IAnalyticsPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [selectedSalesOrders, setSelectedSalesOrders] = useState<Option>(
    initialFilters.selectedSalesOrders
  );
  const [selectedOrderPortfolio, setSelectedOrderPortfolio] = useState<any>(
    initialFilters.selectedOrderPortfolio
  );
  const [
    selectedOrderPortfolioExpectedInvoiceDate,
    setSelectedOrderPortfolioExpectedInvoiceDate,
  ] = useState<any>(initialFilters.selectedOrderPortfolioExpectedInvoiceDate);
  const [selectedInvoices, setSelectedInvoices] = useState<Option>(
    initialFilters.selectedInvoices
  );

  const [selectedQuotations, setSelectedQuotations] = useState<Option>(
    initialFilters.selectedQuotations
  );

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IAnalyticsPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  useEffect(() => {
    filtersRef.current = {
      selectedSalesOrders,
      selectedOrderPortfolio,
      selectedOrderPortfolioExpectedInvoiceDate,
      selectedInvoices,
      selectedQuotations,
    };
  }, [
    selectedSalesOrders,
    selectedOrderPortfolio,
    selectedOrderPortfolioExpectedInvoiceDate,
    selectedInvoices,
    selectedQuotations,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.ANALYTICS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    selectedSalesOrders,
    setSelectedSalesOrders,
    selectedOrderPortfolio,
    setSelectedOrderPortfolio,
    selectedOrderPortfolioExpectedInvoiceDate,
    setSelectedOrderPortfolioExpectedInvoiceDate,
    selectedInvoices,
    setSelectedInvoices,
    selectedQuotations,
    setSelectedQuotations,
  };
};

export const useGetSalesOrderAnalytic = (selectedSalesOrders: Option) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SALES_ORDER_ANALYTIC, selectedSalesOrders],
    queryFn: () => {
      return getSalesOrdersAnalytic(selectedSalesOrders);
    },
  });

export const useGetOrderPortfolioAnalytic = (
  selectedOrderPortfolio: any,
  selectedOrderPortfolioExpectedInvoiceDate: any
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_ORDER_PORTFOLIO_ANALYTIC,
      selectedOrderPortfolio,
      selectedOrderPortfolioExpectedInvoiceDate,
    ],
    queryFn: () => {
      return getOrderPortfolioAnalytic(
        selectedOrderPortfolio,
        selectedOrderPortfolioExpectedInvoiceDate
      );
    },
  });

export const useGetStockItemsAnalytic = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_STOCK_ITEMS_ANALYTIC,
    queryFn: () => {
      return getStockItemsAnalytic();
    },
  });

export const useGetQuotationsAnalytic = (selectedQuotations: Option) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATIONS_ANALYTIC, selectedQuotations],
    queryFn: () => {
      return getQuotationsAnalytic(selectedQuotations);
    },
  });

export const useGetSalesInvoicesAnalytic = (selectedInvoices: Option) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SALES_INVOICES_ANALYTIC, selectedInvoices],
    queryFn: () => {
      return getSalesInvoicesAnalytic(selectedInvoices);
    },
  });

export const useGetPurchaseOrderAnalytic = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_PURCHASE_ORDER_ANALYTIC,
    queryFn: () => {
      return getPurchaseOrderAnalytic();
    },
  });
