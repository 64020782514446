import { MeasurementTabOptions } from './constants';

export const validateQuotationSalesOrderAndCustomerField = (
  quotationId: string | number,
  salesOrderId: string | number,
  customerId: string | number,
  setFieldError: any,
  t: (string: string) => string,
  selectedOption: MeasurementTabOptions
) => {
  if (selectedOption === MeasurementTabOptions.QUOTATION) {
    if (!quotationId) {
      setFieldError('quotation_id', t('Quotation is required'));
      setFieldError('sales_order_id', undefined);
      setFieldError('customer_id', undefined);
      return false;
    }
  }
  if (selectedOption === MeasurementTabOptions.SALES_ORDER) {
    if (!salesOrderId) {
      setFieldError('quotation_id', undefined);
      setFieldError('sales_order_id', t('Sales order is required'));
      setFieldError('customer_id', undefined);
      return false;
    }
  }
  if (selectedOption === MeasurementTabOptions.CUSTOMER) {
    if (!customerId) {
      setFieldError('quotation_id', undefined);
      setFieldError('sales_order_id', undefined);
      setFieldError('customer_id', t('Customer is required'));
      return false;
    }
  }
  setFieldError('quotation_id', undefined);
  setFieldError('sales_order_id', undefined);
  setFieldError('customer_id', undefined);
  return true;
};
