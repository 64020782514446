import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCustomers } from 'services/Customer/CustomerService';

export const useGetCustomersInfinite = (
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  isEnabled?: boolean,
  sortBy?: string,
  sortDirection?: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_CUSTOMERS,
      perPage,
      searchBy,
      isActive,
      sortBy,
      sortDirection,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getCustomers(
        pageParam,
        perPage,
        searchBy,
        isActive,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetCustomersOptions = (
  customersData: InfiniteData<any> | undefined
) => {
  const customersOptions = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: any) => {
          return {
            value: customer?.id,
            label: `${customer?.name} ${customer?.last_name}`,
          };
        });
    }
    return [];
  }, [customersData]);
  return customersOptions;
};
