import {
  ISimpleActionsTableConfig,
  ISimpleActionsTableRowConfig,
} from 'components/SimpleActionTable/SimpleActionsTable';

const rowConfigs: ISimpleActionsTableRowConfig[] = [
  { header: 'Name', flexValue: 1 },
  { header: 'Group', flexValue: 1 },
  { header: 'Description', flexValue: 1 },
];

export const tableConfig: ISimpleActionsTableConfig = {
  rowConfigs: rowConfigs,
};
