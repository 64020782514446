import { Container, FullWidthContainer } from './AnalyticsPage.styled';
import { FilterCard } from './FilterCard/FilterCard';
import { QuotationsCard } from './QuotationsCard/QuotationsCard';
import { IncomingDeliveriesCard } from './IncomingDeliveriesCard/IncomingDeliveriesCard';
import { InvoicesCard } from './InvoicesCard/InvoicesCard';
import { Wallet, Cube, Cardholder } from '@phosphor-icons/react';
import {
  useGetAnalyticsPageFilters,
  useGetOrderPortfolioAnalytic,
  useGetSalesOrderAnalytic,
  useGetStockItemsAnalytic,
  useManageAndSaveFilters,
} from './hooks';
import { useTranslation } from 'react-i18next';

const AnalyticsPage = () => {
  const { t } = useTranslation();
  const {
    analyticsPageFilters,
    dateOptions,
    subStatusOptions,
    expectedInvoiceDateOptions,
  } = useGetAnalyticsPageFilters();

  const {
    selectedSalesOrders,
    setSelectedSalesOrders,
    selectedOrderPortfolio,
    setSelectedOrderPortfolio,
    selectedOrderPortfolioExpectedInvoiceDate,
    setSelectedOrderPortfolioExpectedInvoiceDate,
    selectedQuotations,
    setSelectedQuotations,
    selectedInvoices,
    setSelectedInvoices,
  } = useManageAndSaveFilters(analyticsPageFilters);

  const { data: stockItems } = useGetStockItemsAnalytic();
  const { data: salesOrder } = useGetSalesOrderAnalytic(selectedSalesOrders);
  const { data: orderPortfolio } = useGetOrderPortfolioAnalytic(
    selectedOrderPortfolio,
    selectedOrderPortfolioExpectedInvoiceDate
  );

  return (
    <Container>
      <FullWidthContainer>
        <QuotationsCard
          dateOptions={dateOptions}
          selectedQuotations={selectedQuotations}
          setSelectedQuotations={setSelectedQuotations}
        />
      </FullWidthContainer>

      <FullWidthContainer>
        <FilterCard
          filterOptions={dateOptions}
          title={'Sales'}
          icon={Cardholder}
          route={'/sales'}
          label={'Sales'}
          value={salesOrder?.number_of_orders}
          totalValue={salesOrder?.total_value}
          onFilterChange={(selectedValue) =>
            setSelectedSalesOrders(selectedValue)
          }
          defaultValue={selectedSalesOrders ? selectedSalesOrders : undefined}
          width="50%"
        />
        <FilterCard
          filterOptions={subStatusOptions}
          extraFilterOptions={expectedInvoiceDateOptions}
          title={'Order Portfolio'}
          icon={Wallet}
          route={'/sales'}
          label={'Sales'}
          value={orderPortfolio?.number_of_orders}
          totalValue={orderPortfolio?.total_value}
          onFilterChange={(selectedValue) =>
            setSelectedOrderPortfolio(selectedValue)
          }
          onExtraFilterChange={(selectedValue) => {
            setSelectedOrderPortfolioExpectedInvoiceDate(selectedValue);
          }}
          defaultValue={
            selectedOrderPortfolio ? selectedOrderPortfolio : undefined
          }
          extraDefaultValue={
            selectedOrderPortfolioExpectedInvoiceDate
              ? selectedOrderPortfolioExpectedInvoiceDate
              : undefined
          }
          extraFilterPlaceholder={t('Expected Invoice Date')}
          width="50%"
        />
      </FullWidthContainer>

      <FullWidthContainer>
        <IncomingDeliveriesCard />
        <FilterCard
          title={'Stock Items'}
          icon={Cube}
          route={'/stock'}
          label={'In stock'}
          value={stockItems?.total_in_stock}
          totalValue={stockItems?.total_stock_value}
        />
      </FullWidthContainer>

      <FullWidthContainer>
        <InvoicesCard
          dateOptions={dateOptions}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
        />
      </FullWidthContainer>
    </Container>
  );
};

export default AnalyticsPage;
