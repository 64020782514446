import { COLORS, marginSm } from 'assets/styled';
import { RowGap, SectionTitle } from '../ReportForm.styled';
import {
  AddMoreWrapper,
  GeneralGrid,
  GeneralLabel,
  Section,
  UploadPicturesSection,
  UploadedPicturesWrapper,
} from './GeneralPicturesSection.styled';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReportPagePictureCard from '../../ReportPagePictureCard/ReportPagePictureCard';
import { IAppointment } from 'types/Appointment.types';
import { Dispatch, SetStateAction, useState } from 'react';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { IQuotation } from 'types/Quotations.types';
import Button from 'components/Button/Button';
import { Plus } from '@phosphor-icons/react';

interface IGeneralPicturesSectionProps {
  isReadOnly: boolean;
  newQuotation: IQuotation | null;
  firstName: string;
  lastName: string;
  appointment: IAppointment;
  pictures: any[];
  setPictures: Dispatch<SetStateAction<any>>;
}

const GeneralPicturesSection = ({
  isReadOnly,
  newQuotation,
  firstName,
  lastName,
  appointment,
  pictures,
  setPictures,
}: IGeneralPicturesSectionProps) => {
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <RowGap gap={'100rem'} alignItems="flex-start">
      <Section>
        <SectionTitle marginBottom={marginSm}>{t('GENERAL')}</SectionTitle>
        {appointment ? (
          <GeneralGrid>
            <GeneralLabel>{t('User')}:</GeneralLabel>
            <GeneralLabel>{firstName + ' ' + lastName}</GeneralLabel>
            <GeneralLabel>{t('Type')}</GeneralLabel>
            <GeneralLabel color={COLORS.PRIMARY}>
              {t(`${appointment.type.name}`)}
            </GeneralLabel>
            <GeneralLabel>{t('Customer')}</GeneralLabel>
            <GeneralLabel>{`${appointment.customer.name} ${appointment.customer.last_name}`}</GeneralLabel>
            <GeneralLabel>{t('Date')}</GeneralLabel>
            <GeneralLabel color={COLORS.GREY_1000}>{`${moment(
              appointment.date_from
            ).format('DD-MM-YYYY')}`}</GeneralLabel>
            {appointment.sales_order ? (
              <>
                <GeneralLabel>{t('Sales order')}</GeneralLabel>
                <GeneralLabel>{`${appointment.sales_order.number}`}</GeneralLabel>
              </>
            ) : (
              <>
                <GeneralLabel>
                  {newQuotation ? t('Original Quotation') : t('Quotation')}
                </GeneralLabel>
                <GeneralLabel>{`${
                  appointment.quotation?.number || ''
                }`}</GeneralLabel>
              </>
            )}
            {newQuotation ? (
              <>
                <GeneralLabel>{t('New quotation')}</GeneralLabel>
                <GeneralLabel>{`${newQuotation.number}`}</GeneralLabel>
              </>
            ) : null}
          </GeneralGrid>
        ) : (
          t('Unknown appointment')
        )}
      </Section>
      <UploadPicturesSection>
        <SectionTitle marginBottom={marginSm}>
          {isReadOnly ? t('UPLOADED PICTURES') : t('UPLOAD PICTURES')}
        </SectionTitle>
        <UploadedPicturesWrapper>
          {pictures.map((picture: any, index: number) => (
            <ReportPagePictureCard
              isReadOnly={isReadOnly}
              picture={picture}
              key={index}
              onDelete={() =>
                setPictures(
                  pictures.filter(
                    (_: any, pictureIndex: number) => index !== pictureIndex
                  )
                )
              }
            />
          ))}
        </UploadedPicturesWrapper>
        {isReadOnly ? null : (
          <AddMoreWrapper>
            <Button
              label={t('Add more')}
              icon={Plus}
              sizeIcon={15}
              weightIcon="bold"
              colorIcon={COLORS.PRIMARY}
              link
              onClick={() => setIsUploadFileModalOpen(true)}
              fontSize="14rem"
            />
          </AddMoreWrapper>
        )}
      </UploadPicturesSection>
      <FileUploadModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        onSubmit={(files: any) => {
          setPictures([...pictures, ...files]);
          setIsUploadFileModalOpen(false);
        }}
      />
    </RowGap>
  );
};

export default GeneralPicturesSection;
