import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { getSingleCustomerByEmail } from 'services/Customer/CustomerService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useGetSingleCustomerAlternativeContacts } from 'pages/Manager/SingleCustomerPage/SingleCustomerAltContacts/hooks';
import { CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE } from 'pages/Manager/SingleCustomerPage/SingleCustomerAltContacts/SingleCustomerAltContacts';
import { ICustomerAlternativeContact } from 'types/Customer.types';
import { useMemo } from 'react';
import { Option } from 'components/Select/type';
import { generateAltContactOptionFromAltContact } from './helpers';

export const useGetCustomerByEmail = () =>
  useMutation((email: string) => getSingleCustomerByEmail(email), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_CUSTOMER_BY_EMAIL,
      });
    },
    mutationKey: ReactMutationKeys.GET_CUSTOMER_BY_EMAIL,
  });
export const useGetCustomerAlternativeContactOptions = (
  customerId: number | undefined,
  searchBy: string
) => {
  const {
    data: customerAlternativeContactsData,
    isLoading: isLoadingAltContacts,
    hasNextPage: hasNextPageAltContacts,
    fetchNextPage: fetchNextPageAltContacts,
  } = useGetSingleCustomerAlternativeContacts(
    Number(customerId),
    CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE,
    searchBy
  );

  const customerAlternativeContactOptions: Option[] = useMemo(() => {
    if (customerAlternativeContactsData?.pages?.length) {
      return customerAlternativeContactsData.pages
        .map((page) => page.alternative_contacts)
        .flat()
        .map((contact: ICustomerAlternativeContact) => {
          return generateAltContactOptionFromAltContact(contact);
        });
    }
    return [];
  }, [customerAlternativeContactsData]);

  return {
    customerAlternativeContactOptions,
    isLoadingAltContacts,
    hasNextPageAltContacts,
    fetchNextPageAltContacts,
  };
};
