import IAddress from './Address.types';
import { ICustomer, ICustomerSnapshot } from './Customer.types';
import { IDiscount } from './Discount.types';
import { ILabel } from './EntityLabel.types';
import { IProduct } from './Product.types';
import { IStockItem } from './StockItem.types';
import { ISupplier } from './Supplier.types';

export interface IQuotation {
  id: string;
  number: string;
  status: IQuotationStatus;
  created_at: string;
  payment_condition: string; // In case payment condition entity is deleted from db
  downpayment_condition: string; // In case payment condition entity is deleted from db
  payment_condition_id: number;
  reference: string;
  customer: ICustomer;
  customer_snapshot: ICustomerSnapshot;
  total_amount: string;
  sent_to_customer: boolean;
  name: string;
  valid_to: string;
  description: string;
  quotation_lines: IProduct[];
  different_shipping_address: boolean;
  shipping_data: IShippingData;
  parent_quotation: IQuotation;
  prices_definitive: boolean;
  measuring_date: string;
  type_id: QuotationTypeIdsEnum;
  type: IQuotationType;
  labels: ILabel[];
}

export interface IQuotationStatus {
  id: string;
  name: string;
}

export interface IQuotationTableDTO {
  navigationID: string;
  customerID: number;
  number: string;
  status: string;
  date: string;
  reference: string;
  customer?: string;
  city?: string;
  isDraft: string;
  amount: string;
  measuringDate: string;
  labels: ILabel[];
}

export interface IQuotationLine {
  id: string;
  placement: string;
  quantity: string;
  product: IProduct;
  working_hours_line?: boolean;
  working_hours_rate?: number;
  stock_item?: IStockItem;
  stock_item_line?: boolean;
  total_amount?: number;
  status?: IQuotationLineStatus;
  product_purchase_price: number;
  product_sales_price: number;
  discount: IDiscount;
  discount_amount: number;
  supplier: ISupplier;
  description: string;
  index: number;
  measurement_check?: boolean;
  measured?: boolean;
}
export interface ICreateQuotationData {
  payload: ICreateQuotationPayload;
  queryParams: ICreateQuotationQueryParamsForCustomer;
}

export interface ICreateQuotationShippingData {
  first_name: string;
  last_name: string;
  phone: string;
  company_name: string;
  street: string;
  city: string;
  zip_code: string;
  house_number: string;
  country: string;
  email: string;
  title: string;
}

export interface IShippingData {
  company_name: string;
  first_name: string;
  email?: string;
  phone: string;
  id: string;
  last_name: string;
  vat_number: string;
  address: IAddress;
  title: string;
}

export interface ICreateQuotationAddressData {
  country: string;
  street: string;
  zip_code: string;
  city: string;
  house_number: string;
}

export interface ICreateQuotationCustomerData {
  name: string;
  last_name: string;
  phone: string;
  email: string;
  address: ICreateQuotationAddressData;
  gender: string;
  title: string;
  company_name: string;
  vat_number: string;
  alternative_contact_id?: number;
  alternative_invoice_email?: string;
}

export interface ICreateQuotationPayload {
  type_id: number;
  valid_to: string;
  description: string;
  payment_condition?: string;
  payment_condition_id?: number;
  downpayment_condition?: string;
  reference: string;
  quotation_lines: ICreateQuotationLineDTO[];
  discount: IDiscount;
  customer_id?: string | null;
  customer: ICreateQuotationCustomerData;
  parent_quotation_id?: number;
  downpayment?: number;
  shipping_data?: ICreateQuotationShippingData;
  label_ids?: number[];
  recurring_period_id?: number;
  recurring_type_id?: number;
}

export interface ICreateQuotationQueryParamsForCustomer {
  force_create_customer: boolean;
}

export interface ICreateQuotationLineDTO {
  supplier_id?: number;
  product_id?: string;
  stock_item_id?: number;
  quantity: number;
  discount: IDiscount;
  product_attributes?: object;
  logyx_configuration_intent_uuid?: string;
  placement?: string;
  working_hours_line?: boolean;
  working_hours_rate?: string;
  description?: string;
  product_purchase_price?: number;
  product_sales_price?: number;
  is_stock_item?: boolean;
}

export interface ICreateQuotationActionData {
  type_id: number;
  name?: string;
}

export interface IQuotationLineStatus {
  id: number;
  name: string;
}

export interface IEditQuotationLineData {
  quantity: number;
  discount?: IDiscount | undefined;
  description?: string;
  logyx_configuration_intent_uuid?: string | undefined;
  placement?: string | undefined;
  product_purchase_price?: number | undefined;
  product_sales_price: number;
  product_attributes: any;
  measured?: boolean;
}

export interface IQuotationAction {
  id: string;
  name: string;
}

export enum QuotationTypeIdsEnum {
  DEFINITIVE = 1,
  DRAFT = 2,
  RECURRING = 3,
}

export interface IQuotationType {
  id: number;
  name: string;
}
