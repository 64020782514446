import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  CustomTable,
  TD,
  PaginationTR,
  ThLabel,
  Th,
  ExtraActionsHeader,
  ExtraActionsTableData,
  TdLabel,
  ExtraActionsHeaderInvisibleContentWrapper,
  TableResponsive,
  ThWrapper,
  ThIcon,
  IconWrapper,
  LabelWrapper,
  TDLabels,
  TableRow,
} from './Table.styled';
import Button from 'components/Button/Button';
import Pagination from './Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CellType, SortDirection, excludedTableDataKeys } from './constants';
import { getSortIcon, sortableFieldsMapper } from './helpers';
import { AccountingCell } from './Cells/AccountingCell/AccountingCell';
import { ICustomerTableAccountingDTO } from 'types/Customer.types';
import TableSkeleton from 'components/TableSkeleton/TableSkeleton';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import PermissionsCell from './Cells/PermissionsCell/PermissionsCell';
import Tooltip from 'components/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import {
  BookOpenText,
  HandSwipeRight,
  PencilSimple,
  PencilSimpleLine,
  Trash,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import { ILabel } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import CheckboxCell from './Cells/CheckboxCell/CheckboxCell';
import SelectCell from './Cells/SelectCell/SelectCell';
import { Option } from 'components/Select/type';
import { RoutesConfig } from 'navigation/routes';
import { TodoRelatedTypeId } from 'types/ToDo.types';
import { useHandleTodoNavigateSalesInvoice } from 'pages/Manager/ToDoPage/ViewToDoModal/hooks';

interface ISortTableParams {
  sortBy?: string;
  setSortBy?: (sortBy: string) => void;
  sortDirection?: SortDirection;
  setSortDirection?: Dispatch<SetStateAction<SortDirection | undefined>>;
}

export interface ITableConfig {
  rowConfigs: ITableRowConfig[];
  extraActionsFlexValue: number;
}

export interface ITableRowConfig {
  header: string;
  flexValue: number;
  isBlue: boolean;
  isSortable?: boolean;
  alternativeSortField?: string; // If provided, it will be used as sorting field instead of header value
  cellType?: CellType;
  icon?: boolean;
  bigCell?: boolean;
}

interface ITableProps {
  page: number;
  perPage: number;
  total: number;
  tableData: any[];
  isLoading?: boolean;
  tableConfig: ITableConfig;
  labels?: ILabel[];
  hasExtraAction: boolean;
  onDelete?: (item: any) => void;
  onEdit?: (item: any) => void;
  onCheckboxChange?: (row: any) => void;
  onSelectChange?: (e: Option, rowData: any, rowDataKey: string) => void;
  handlePermissionChange?: any;
  withoutPagination?: boolean;
  withoutMarginTop?: boolean;
  loadPage: (page: number) => void;
  onViewButtonClick?: (params: any) => void;
  sortParams?: ISortTableParams;
  resetPerms?: boolean;
  setResetPerms?: React.Dispatch<React.SetStateAction<boolean>>;
  editableIcon?: boolean;
  labelsRow?: boolean;
}

export const Table = ({
  page,
  perPage,
  total,
  tableData,
  tableConfig,
  labels,
  hasExtraAction,
  isLoading,
  onDelete,
  loadPage,
  onViewButtonClick,
  onEdit,
  handlePermissionChange,
  withoutPagination = false,
  withoutMarginTop = false,
  sortParams,
  resetPerms,
  setResetPerms,
  editableIcon = true,
  onCheckboxChange,
  onSelectChange,
  labelsRow = false,
}: ITableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translatableColumnIndexes: number[] = [];
  const [hoveredGroup, setHoveredGroup] = useState<number | null>(null);
  let customerColumnIndex: number;
  let supplierColumnIndex: number;
  let toDoRelatedColumnIndex: number;
  const { isMidTablet } = useBreakpointFlag();

  const { id: loggedInUserId } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  let sortBy: any, setSortBy: any, sortDirection: any, setSortDirection: any;
  if (sortParams) {
    sortBy = sortParams.sortBy;
    setSortBy = sortParams.setSortBy;
    sortDirection = sortParams.sortDirection;
    setSortDirection = sortParams.setSortDirection;
  }

  const handleSortClick = (rowConfig: ITableRowConfig) => {
    switch (sortDirection) {
      case undefined:
      case SortDirection.NO_SORT: {
        setSortBy?.(sortableFieldsMapper(rowConfig));
        setSortDirection?.(SortDirection.ASC);
        break;
      }
      case SortDirection.ASC: {
        if (sortBy !== sortableFieldsMapper(rowConfig)) {
          setSortDirection?.(SortDirection.ASC);
        } else {
          setSortDirection?.(SortDirection.DESC);
        }
        setSortBy?.(sortableFieldsMapper(rowConfig));

        break;
      }
      case SortDirection.DESC: {
        if (sortBy !== sortableFieldsMapper(rowConfig)) {
          setSortDirection?.(SortDirection.ASC);
        } else {
          setSortDirection?.(SortDirection.NO_SORT);
        }
        setSortBy?.(sortableFieldsMapper(rowConfig));
        break;
      }
      default:
        break;
    }
  };

  const { handleTodoNavigateSalesInvoice } =
    useHandleTodoNavigateSalesInvoice(navigate);

  const renderTableHeaderData = () => {
    return (
      <tr
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          width: '100%',
        }}
      >
        <th></th>
        {tableConfig?.rowConfigs.map((rowConfig: ITableRowConfig, index) => {
          if (rowConfig.header === 'Customer') customerColumnIndex = index;
          if (rowConfig.header === 'Related') toDoRelatedColumnIndex = index;
          if (rowConfig.header === 'Supplier' || rowConfig.header === 'Company')
            supplierColumnIndex = index;
          if (
            rowConfig.header === 'Payment' ||
            rowConfig.header === 'Status' ||
            rowConfig.header === 'Sub Status' ||
            rowConfig.header === 'Type' ||
            rowConfig.header === 'License'
          ) {
            translatableColumnIndexes.push(index);
          }
          const isSortable =
            setSortBy && setSortDirection && rowConfig.isSortable !== false;
          return (
            <Th
              key={index}
              style={{ position: 'relative' }}
              onClick={() => isSortable && handleSortClick(rowConfig)}
              flexValue={rowConfig.flexValue}
              $bigCell={rowConfig?.bigCell}
            >
              <ThWrapper>
                <ThLabel>{t(rowConfig.header)}</ThLabel>
                {isSortable && (
                  <Icon
                    svg={getSortIcon(sortDirection, sortBy, rowConfig)}
                    marginLeft="5rem"
                    color={COLORS.WHITE}
                    size={17}
                  />
                )}
              </ThWrapper>
              {rowConfig?.cellType === CellType.PERMISSIONS && rowConfig.icon && (
                <ThIcon>
                  <Tooltip content={t('Read permissions')}>
                    <Icon svg={BookOpenText} size={20} color={COLORS.WHITE} />
                  </Tooltip>
                  <Tooltip content={t('Write permissions')}>
                    <Icon
                      svg={PencilSimpleLine}
                      size={20}
                      color={COLORS.WHITE}
                    />
                  </Tooltip>
                </ThIcon>
              )}
            </Th>
          );
        })}
        <ExtraActionsHeader
          extraActionsFlexValue={tableConfig.extraActionsFlexValue}
        >
          <ExtraActionsHeaderInvisibleContentWrapper>
            {hasExtraAction && <Button table label={t('View')} />}
            {onDelete && (
              <Icon
                svg={Trash}
                color={COLORS.PRIMARY}
                size={20}
                weight="regular"
              />
            )}
            {onEdit && (
              <Icon
                svg={PencilSimple}
                color={COLORS.PRIMARY}
                size={20}
                weight="regular"
              />
            )}
          </ExtraActionsHeaderInvisibleContentWrapper>
        </ExtraActionsHeader>
        {tableConfig.extraActionsFlexValue > 0 && <th></th>}
      </tr>
    );
  };

  const getTdLabelValue = (tdIndex: number, rowData: any, rowDataKey: any) => {
    const tdLabelValue = translatableColumnIndexes.includes(tdIndex)
      ? t(rowData[rowDataKey])
      : rowData[rowDataKey];
    return (rowDataKey === 'externalId' ||
      rowDataKey === 'reference' ||
      rowDataKey === 'city') &&
      tdLabelValue?.length > 10
      ? `${tdLabelValue?.substring(0, 10)}...`
      : tdLabelValue;
  };

  const renderTableBodyData = () => {
    return [
      tableData.map((rowData: React.ReactNode[], rowIndex: number) => {
        const isFieldLicense = rowData['license'] === 'FIELD_WORKER';
        const isAdminChecked =
          rowData['adminPermission']?.read || rowData['adminPermission']?.edit;

        const handleMouseEnter = (index: number) => {
          setHoveredGroup(index);
        };

        const handleMouseLeave = () => {
          setHoveredGroup(null);
        };

        return (
          <React.Fragment key={rowIndex}>
            <TableRow
              data-testid={`row-${rowIndex}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                width: '100%',
              }}
              isHovered={hoveredGroup === rowIndex}
              onMouseEnter={() => handleMouseEnter(rowIndex)}
              onMouseLeave={handleMouseLeave}
            >
              <td></td>
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((rowDataKey: any, tdIndex: number) => {
                  const rowConfig: ITableRowConfig =
                    tableConfig.rowConfigs?.[tdIndex];

                  return (
                    <TD
                      labelsRow={labelsRow && !!rowData['labels']?.length}
                      flexValue={rowConfig && rowConfig.flexValue}
                      key={`${rowIndex}-${tdIndex}`}
                      onClick={() => {
                        if (
                          rowData['customerID'] &&
                          customerColumnIndex === tdIndex
                        )
                          navigate(
                            `${SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                              ':id',
                              rowData['customerID']
                            )}`
                          );
                        else if (
                          rowData['supplierID'] &&
                          supplierColumnIndex === tdIndex
                        )
                          navigate(
                            `${SingleSupplierRoutes.SingleSupplierGeneral.fullPath.replace(
                              ':id',
                              rowData['supplierID']
                            )}`
                          );
                        else if (
                          rowData['relatedTo'] &&
                          toDoRelatedColumnIndex === tdIndex
                        ) {
                          let route: string;
                          switch (rowData['relatedTypeId']) {
                            case TodoRelatedTypeId.QUOTATION:
                              route = RoutesConfig.SingleQuotation.fullPath;
                              break;
                            case TodoRelatedTypeId.SALES_ORDER:
                              route = RoutesConfig.SingleSalesOrder.fullPath;
                              break;
                            case TodoRelatedTypeId.SALES_INVOICE:
                              handleTodoNavigateSalesInvoice(
                                rowData['relatedToId']
                              );
                              return;
                            case TodoRelatedTypeId.PURCHASE_ORDER:
                              route = RoutesConfig.SinglePurchaseOrder.fullPath;
                              break;
                            case TodoRelatedTypeId.CUSTOMER:
                              route = RoutesConfig.SingleCustomer.fullPath;
                              break;
                            default:
                              return false;
                          }
                          navigate(
                            `${route.replace(':id/*', rowData['relatedToId'])}`
                          );
                        }
                      }}
                    >
                      {rowConfig?.cellType === CellType.ACCOUNTING ? (
                        <AccountingCell
                          accountingData={
                            rowData[rowDataKey] as ICustomerTableAccountingDTO[]
                          }
                        />
                      ) : rowConfig?.cellType === CellType.PERMISSIONS ? (
                        <PermissionsCell
                          rowDataKey={rowDataKey}
                          user_id={rowData['userId']}
                          permissionDetails={rowData[rowDataKey]}
                          handlePermissionChange={handlePermissionChange}
                          page={page}
                          resetPerms={resetPerms}
                          setResetPerms={setResetPerms}
                          isAdminChecked={isAdminChecked}
                          isFieldLicense={isFieldLicense}
                        />
                      ) : rowConfig?.cellType === CellType.CHECKBOX ? (
                        <CheckboxCell
                          key={`${rowData[rowDataKey]}`}
                          value={rowData[rowDataKey] as boolean}
                          onChange={
                            onCheckboxChange
                              ? () => onCheckboxChange(rowData)
                              : () => null
                          }
                        />
                      ) : rowConfig?.cellType === CellType.SELECT ? (
                        <SelectCell
                          key={`${rowData[rowDataKey]}`}
                          field={rowDataKey}
                          defaultValue={rowData[rowDataKey]}
                          onChange={
                            onSelectChange
                              ? (e: any) =>
                                  onSelectChange(e, rowData, rowDataKey)
                              : () => null
                          }
                        />
                      ) : (
                        <TdLabel isBlueColor={rowConfig && rowConfig.isBlue}>
                          {getTdLabelValue(tdIndex, rowData, rowDataKey)}
                        </TdLabel>
                      )}
                    </TD>
                  );
                })}
              <ExtraActionsTableData
                labelsRow={labelsRow && !!rowData['labels']?.length}
                extraActionsFlexValue={tableConfig.extraActionsFlexValue}
              >
                {hasExtraAction && (
                  <Button
                    table
                    label={t('View')}
                    onClick={() =>
                      onViewButtonClick && onViewButtonClick(rowData)
                    }
                  />
                )}
                {onDelete && (
                  <Icon
                    data-testid={`delete-icon-${rowIndex}`}
                    onClick={() => {
                      onDelete(rowData);
                    }}
                    svg={Trash}
                    weight="regular"
                    color={COLORS.PRIMARY}
                    size={20}
                    disabled={
                      !(editableIcon || rowData['userId'] === loggedInUserId)
                    }
                  />
                )}
                {onEdit && (
                  <Icon
                    data-testid={`edit-icon-${rowIndex}`}
                    onClick={() => {
                      onEdit(rowData);
                    }}
                    svg={PencilSimple}
                    color={COLORS.PRIMARY}
                    size={20}
                    weight="regular"
                    disabled={
                      !(editableIcon || rowData['userId'] === loggedInUserId)
                    }
                  />
                )}
              </ExtraActionsTableData>
              {tableConfig.extraActionsFlexValue > 0 && <td></td>}
            </TableRow>
            {labelsRow && !!rowData['labels']?.length && (
              <TableRow
                isHovered={hoveredGroup === rowIndex}
                onMouseEnter={() => handleMouseEnter(rowIndex)}
                onMouseLeave={handleMouseLeave}
              >
                <TDLabels colSpan={tableConfig.rowConfigs.length}>
                  {rowData['labels'].map((label: ILabel) => {
                    return (
                      <EntityLabel
                        key={label.id}
                        label={label}
                        tooltipContent={
                          label.name.length > 15
                            ? label.name
                            : label.description
                        }
                      />
                    );
                  })}
                </TDLabels>
              </TableRow>
            )}
          </React.Fragment>
        );
      }),
      renderTablePaginationFooter(),
    ];
  };

  const renderTablePaginationFooter = () => {
    if (!tableData.length) return;
    const isPaginationPresent = total > perPage;
    return (
      <PaginationTR key="0" isPaginationPresent={isPaginationPresent}>
        <td
          colSpan={
            Object.keys(tableData[0]).filter(
              (key) => !excludedTableDataKeys.includes(key)
            ).length + 2
          }
        >
          {isPaginationPresent && !withoutPagination && (
            <Pagination
              page={page}
              perPage={perPage}
              total={total}
              loadPage={loadPage}
            />
          )}
        </td>
        {onDelete && <td></td>}
      </PaginationTR>
    );
  };

  return (
    <TableResponsive className="table-responsive">
      <CustomTable
        $labels={labels}
        $withoutPagination={withoutPagination}
        $withoutMarginTop={withoutMarginTop}
      >
        <thead>{renderTableHeaderData()}</thead>
        {isLoading && <TableSkeleton numRows={perPage} numColumns={1} />}
        <tbody>{renderTableBodyData()}</tbody>
      </CustomTable>
      {isMidTablet &&
        tableData.length > 0 &&
        tableConfig.rowConfigs.length > 7 &&
        !isLoading && (
          <IconWrapper>
            <Icon
              svg={HandSwipeRight}
              size={30}
              color={COLORS.PRIMARY}
              pointer={false}
            />
          </IconWrapper>
        )}
    </TableResponsive>
  );
};

export default Table;
