import { ICustomer, ICustomerAlternativeContact } from 'types/Customer.types';
import { ICreateQuotationCustomerDetails } from './constants';
import IAddress from 'types/Address.types';
import { ICreateQuotationShippingData } from 'types/Quotations.types';
import { IGetCityAndAddressDTO } from 'utils/hooks/useGetCityAndAddress';

export const generateCustomerOptionFromCustomer = (customer: ICustomer) => {
  return {
    value: customer,
    label: `${customer?.name} ${customer?.last_name} - ${customer.number} - ${customer.address.zip_code} ${customer.address.house_number}`,
    key: customer.id.toString(),
  };
};

export const generateAltContactOptionFromAltContact = (
  contact: ICustomerAlternativeContact
) => {
  return {
    value: contact,
    label: `${contact?.first_name} ${contact?.last_name} - ${contact.address.zip_code} ${contact.address.house_number}`,
    key: `alt-contact-${contact.id}`,
  };
};

const _addressFields = [
  'country',
  'street',
  'city',
  'zip_code',
  'house_number',
];
const _getShippingDataValue = (
  shouldUpdateShippingDataToMatchCustomer: boolean,
  customer: ICustomer,
  shouldUpdateShippingDataToMatchAltContact: boolean,
  contact: ICustomerAlternativeContact | null,
  shipping_data: ICreateQuotationShippingData | undefined,
  fieldName: string
) => {
  const isAddressField = _addressFields.includes(fieldName);
  if (shouldUpdateShippingDataToMatchCustomer) {
    if (fieldName === 'first_name') {
      fieldName = 'name';
    }
    if (fieldName === 'country') {
      return customer?.address?.country?.cca2 || '';
    }
    return isAddressField
      ? customer?.address?.[fieldName] || ''
      : customer?.[fieldName] || '';
  }
  if (shouldUpdateShippingDataToMatchAltContact) {
    if (fieldName === 'country') {
      return contact?.address?.country?.cca2 || '';
    }
    return isAddressField
      ? contact?.address?.[fieldName] || ''
      : contact?.[fieldName] || '';
  }
  return shipping_data?.[fieldName] || '';
};

export const generateExistingCustomerDetails = (
  customer: ICustomer,
  contact: ICustomerAlternativeContact | null,
  existingCustomerDetails: ICreateQuotationCustomerDetails,
  formikValues: any,
  shouldUpdateShippingDataToMatchCustomer: boolean,
  shouldUpdateShippingDataToMatchAltContact: boolean
) => {
  const address: IAddress = contact ? contact.address : customer.address;
  const customerDetails: ICreateQuotationCustomerDetails = {
    existingCustomer: customer,
    name: contact ? contact.first_name : customer.name,
    last_name: contact ? contact.last_name : customer.last_name,
    gender: customer.gender!,
    email: contact ? contact.email : customer.email,
    country: address.country.cca2,
    phone: contact ? contact.phone : customer.phone,
    street: address.street,
    code: address.zip_code,
    city: address.city!,
    house_number: address.house_number!,
    company_name: customer.company_name!,
    vat_number: customer.vat_number!,
    title: contact ? contact.title : customer.title!,
    shipping_data: {
      // Calls _getShippingDataValue() for each of the shipping data fields
      ...(
        [
          'first_name',
          'last_name',
          'phone',
          'country',
          'street',
          'city',
          'zip_code',
          'house_number',
          'company_name',
          'email',
          'title',
        ] as const
      ).reduce(
        (acc, field) => ({
          ...acc,
          [field]: _getShippingDataValue(
            shouldUpdateShippingDataToMatchCustomer,
            customer,
            shouldUpdateShippingDataToMatchAltContact,
            contact,
            formikValues?.shipping_data || {},
            field
          ),
        }),
        {
          first_name: '',
          last_name: '',
          phone: '',
          country: '',
          street: '',
          city: '',
          zip_code: '',
          house_number: '',
          company_name: '',
          email: '',
          title: '',
        } as ICreateQuotationShippingData
      ),
    },
    isShippingDataFormShown: existingCustomerDetails.isShippingDataFormShown,
    hasAlternativeInvoiceEmail: contact
      ? !!contact?.alternative_invoice_email
      : !!customer?.alternative_invoice_email,
    alternative_invoice_email: contact
      ? contact?.alternative_invoice_email || ''
      : customer?.alternative_invoice_email || '',
    alternativeContact: contact || undefined,
  };

  return customerDetails;
};

export const handleUpdateFormikValuesOnAlternativeContactSelect = (
  setFieldValue: (fieldName: string, value: any) => void,
  contact: ICustomerAlternativeContact | null
) => {
  if (!setFieldValue) {
    return;
  }
  setFieldValue('alternativeContact', contact);
  setFieldValue(
    'alternative_invoice_email',
    contact?.alternative_invoice_email || ''
  );
  setFieldValue(
    'hasAlternativeInvoiceEmail',
    !!contact?.alternative_invoice_email || ''
  );
  setFieldValue('name', contact?.first_name || '');
  setFieldValue('last_name', contact?.last_name || '');
  setFieldValue('email', contact?.email || '');
  setFieldValue('phone', contact?.phone || '');
  setFieldValue('title', contact?.title || '');
  setFieldValue('country', contact?.address.country || '');
  setFieldValue('street', contact?.address.street || '');
  setFieldValue('code', contact?.address.zip_code || '');
  setFieldValue('city', contact?.address.city || '');
};

export const isValidGetCityAndAddressData = (
  data: IGetCityAndAddressDTO
): boolean => {
  return (
    (!!data.countryCode &&
      !!data.zipCode &&
      !!data.city &&
      !!data.street &&
      !!data.houseNumber) ||
    (!!data.zipCode &&
      !!data.countryCode &&
      !data.city &&
      !data.street &&
      !data.houseNumber)
  );
};
