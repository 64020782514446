import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { queryClient } from 'index';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { addProduct, editProduct } from 'services/Product/ProductService';
import { useLocation } from 'react-router-dom';
import { IProductAttribute } from 'types/Product.types';
import { useMemo, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetProductGroupsInfinite } from 'pages/Manager/ProductGroupsPage/hooks';
import { PRODUCT_GROUPS_PER_PAGE } from 'pages/Manager/ProductGroupsPage/constants';
import { IProductGroup } from 'pages/Manager/ProductGroupsPage/types';

export interface IAddEditProductDTO {
  name: string;
  description: string;
  logyx_model_id: string;
  supplier_id?: string;
  product_id?: string;
  files?: any;
  attributes: IProductAttribute[];
  deleted_attachment_ids?: any;
  digital_order_enabled: boolean;
  product_group_id?: string;
}

export const useAddProduct = () =>
  useMutation(
    (editProductData: IAddEditProductDTO) => {
      return addProduct(editProductData);
    },
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_PRODUCT,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS_INFINITE);
      },
      mutationKey: ReactMutationKeys.ADD_PRODUCT,
    }
  );

export const useEditProduct = () =>
  useMutation(
    (editProductData: IAddEditProductDTO) => {
      return editProduct(editProductData);
    },
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PRODUCT,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS_INFINITE);
      },
      mutationKey: ReactMutationKeys.EDIT_PRODUCT,
    }
  );

export const useGetSupplierId = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return pathParts[3];
};

export const useIsLogyxModelIdFieldVisible = () => {
  // const companyName = useSelector(
  //   (state: IRootReducerState) => state.companyInfo.name
  // );
  return true;
};

export const useManageProductGroupsSelect = () => {
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const {
    data: prouductGroupsData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingProductGroups,
  } = useGetProductGroupsInfinite(PRODUCT_GROUPS_PER_PAGE, debouncedSearchBy);

  const productGroups = useMemo(() => {
    if (prouductGroupsData?.pages?.length) {
      return prouductGroupsData.pages
        .map((page) => page.product_group_schema)
        .flat()
        .map((group: IProductGroup) => {
          return {
            value: group,
            label: group?.name,
            key: group?.id,
          };
        });
    }
    return [];
  }, [prouductGroupsData]);

  return {
    productGroups,
    isLoadingProductGroups,
    setSearchBy,
    hasNextPage,
    fetchNextPage,
  };
};
