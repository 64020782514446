import { paddingLg, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 1000rem;
  padding: ${paddingLg};
  gap: 20rem;

  ${respondTo.bigTablet`
    padding: ${paddingMd};
    width: 100%;
  `}
`;

export const FullWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1200rem;
  gap: 20rem;

  ${respondTo.bigTablet`
    margin-bottom: 0;
  `}

  ${respondTo.midTablet`
    min-width: auto;
    width: 100%;
    max-width: 100%;
  `}
`;
