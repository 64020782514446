import {
  Container,
  Row,
  SubmitRow,
  Margin,
  Line,
  RightAlign,
  ProductsLabel,
  SingleProductLabel,
  SingleProductWrapper,
  ProductTitleWrapper,
  InputWrapper,
  AddedFieldsFlexWrap,
  DeleteProductFieldIcon,
  TrashCopyWrapper,
  AbsoluteDiscountOptionsWrapper,
  DiscountOptionLabel,
  RelativeDiscountFieldAndDiscountOptionsWrapper,
  FakeLogyxInputWrapper,
  SeparatorMd,
  SelectWrapper,
  EditWithLogyxContainer,
  LogyxLogoImage,
  EditLabel,
  GreenLine,
  EyeInputWrapper,
  EyeWrapper,
  Wrapper,
  StyledCustomSelect,
  SwitchWrapper,
  SwitchLabel,
} from './ProductList.styled';
import Button from 'components/Button/Button';
import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  getIn,
} from 'formik';
import { Input } from 'components/Input/InputFormik';
import { ICreateQuotationProductFormDTO } from '../constants';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import {
  validateField,
  validateNumericFieldWithZero,
  validateOptionalNumericFieldWithZero,
} from 'utils/validation';
import { useTranslation } from 'react-i18next';
import {
  checkAreDiscountsValid,
  getDiscountErrorMessage,
  getFormikErrorMesssage,
  validatePlacementField,
} from './validation';
import { AddProductFormDTOAttributeModal } from './AddProductFormDTOAttributeModal/AddProductFormDTOAttributeModal';
import { marginMd, marginSm } from 'assets/styled';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import FakeInput from 'components/FakeInput/FakeInput';
import {
  calculateTotalPurchasePriceForSingleProductFormDTO,
  calculateTotalSalesPriceForSingleProductFormDTO,
  getDropdownDefaultValue,
  togglePurchasePriceHidden,
  updateHiddenPurchasePriceProductFormDTOIndexes,
} from './helpers';
import { DiscountType } from 'types/Discount.types';
import FakeLogyxInput from 'components/FakeLogyxInput/FakeLogyxInput';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
  ProductAttributeType,
} from 'types/Product.types';
import LogyxLogo from 'assets/icons/logyxLogoSmall.svg';
import { EditWithLogyxModal } from './EditWithLogyxModal/EditWithLogyxModal';
import { ILogyxAttribute, ILogyxConfigurationIntent } from 'store/Logyx/types';
import { toast } from 'utils/toast';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { formatNumber } from 'utils/numberFormatter';
import {
  extractExpectedPurchasePriceFromIntent,
  extractSalesPriceFromIntent,
} from '../AddNewProduct/helpers';
import { useHandleScrollingEffects } from './hooks';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import {
  Copy,
  Eye,
  EyeSlash,
  Percent,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import { useGetQuickFieldOptions } from '../AddNewProduct/ConfigureLogyxModal/hooks';
import useHasRole from 'utils/hooks/useHasRole';
import { Roles } from 'types/Permissions.types';
import Tooltip from 'components/Tooltip/Tooltip';
import { Switch } from '@mui/material';
interface IProductListProps {
  onForward: (productFormDtosData: ICreateQuotationProductFormDTO[]) => void; // productFormDtosData is for order tab initial downpayment calculation
  setProductFormDtos: Dispatch<
    SetStateAction<ICreateQuotationProductFormDTO[]>
  >;
  onBack: () => void;
  productFormDtos: ICreateQuotationProductFormDTO[];
  addNewProduct: () => void;
  canAddAnotherProduct: boolean;
  withoutPrices: boolean;
  shouldScrollToLastProduct: boolean;
  setShouldScrollToLastProduct: Dispatch<SetStateAction<boolean>>;
  hideMeasurementsToggle?: boolean;
}

const ProductList = ({
  onForward,
  onBack,
  productFormDtos,
  addNewProduct,
  setProductFormDtos,
  canAddAnotherProduct,
  withoutPrices = false,
  shouldScrollToLastProduct,
  setShouldScrollToLastProduct,
  hideMeasurementsToggle = false,
}: IProductListProps) => {
  const [
    productFormDTOIndexForLogyxEditing,
    setProductFormDTOIndexForLogyxEditing,
  ] = useState<number | null>(null);
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const [isAddProductFieldModalOpen, setIsAddProductFieldModalOpen] =
    useState<boolean>(false);
  const [isEditWithLogyxModalOpen, setIsEditWithLogyxModalOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteFunction, setDeleteFunction] = useState<any>(false);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const selectedproductFormDTOIndexRef = useRef<number>();
  const [
    hiddenPurchasePriceProductFormDTOIndexes,
    setHiddenPurchasePriceProductFormDTOIndexes,
  ] = useState<number[]>(productFormDtos.map((_, index) => index));

  const quickFieldOptions = useGetQuickFieldOptions();

  const { productFormDtoRefs, handleScrollToLastProduct } =
    useHandleScrollingEffects(
      shouldScrollToLastProduct,
      setShouldScrollToLastProduct
    );

  const isQuickField = (value: string) => {
    return quickFieldOptions.some((option) => option.label === value);
  };

  const canViewPurchasePrice =
    useHasRole(Roles.READ_COMPANY_ADMIN) || useHasRole(Roles.READ_PURCHASE);

  const handleAddAttributes = (
    newAttributeNames: string[],
    productFormDTOIndex: number
  ) => {
    const productFormDtosCopy = [...productFormDtos];
    const newAttributes = [
      ...productFormDtosCopy[productFormDTOIndex].attributes,
    ];
    for (const newAttributeName of newAttributeNames) {
      newAttributes.push({
        type: ProductAttributeType.FIELD,
        fieldName: newAttributeName,
        hasDefaultValue: false,
        defaultOptionIndex: null,
        defaultValue: '',
        options: [],
        value: '',
        docVisibilityOptions: Object.values(IDocVisibilityOptionEnum),
        shouldDisplayAfterLogyxConfiguration: false,
      });
    }
    productFormDtosCopy[productFormDTOIndex].attributes = newAttributes;
    setProductFormDtos(productFormDtosCopy);
  };

  const handleRemoveAttribute = (
    values: { productFormDtos: ICreateQuotationProductFormDTO[] },
    setValues: any,
    productFormDTOIndex: number,
    attributeIndex: number,
    validateForm: any
  ) => {
    const productFormDtosCopy = [...values.productFormDtos];
    const newAttributes = [
      ...productFormDtosCopy[productFormDTOIndex].attributes,
    ];
    newAttributes.splice(attributeIndex, 1);
    productFormDtosCopy[productFormDTOIndex].attributes = newAttributes;

    setValues({ productFormDtos: productFormDtosCopy });
    setProductFormDtos(productFormDtosCopy);

    // Introduce a slight delay before validation to allow the attributes to rerender
    setTimeout(() => {
      validateForm();
    }, 0); // 0ms timeout pushes the validation to the end of the event queue
  };

  const renderAttributes = (
    productFormDTO: ICreateQuotationProductFormDTO,
    productFormDTOIndex: number,
    values: { productFormDtos: ICreateQuotationProductFormDTO[] },
    touched: FormikTouched<{
      productFormDtos: ICreateQuotationProductFormDTO[];
    }>,
    errors: FormikErrors<{ productFormDtos: ICreateQuotationProductFormDTO[] }>,
    handleBlur: any,
    setFieldValue: any,
    setValues: any,
    validateForm: any
  ) => {
    if (!productFormDTO?.attributes.length) {
      return;
    }
    return productFormDTO?.attributes.map(
      (attribute: IProductAttribute, attributeIndex: number) => {
        switch (attribute.type) {
          case ProductAttributeType.FIELD:
            return (
              <InputWrapper
                key={attributeIndex}
                paddingTop={[0, 1].includes(attributeIndex) ? marginSm : ''}
              >
                <Input
                  pwId={`form-${productFormDTOIndex}-attribute-${attributeIndex}`}
                  validate={(value: string) =>
                    validateField(value, attribute.fieldName)
                  }
                  errorMessage={getFormikErrorMesssage(
                    productFormDTOIndex,
                    touched,
                    errors,
                    areAllFieldsTouched,
                    `attributes.${attributeIndex}.value`
                  )}
                  height={'41rem'}
                  name={`productFormDtos.${productFormDTOIndex}.attributes.${attributeIndex}.value`}
                  placeholder={
                    isQuickField(attribute.fieldName)
                      ? t(attribute.fieldName)
                      : attribute.fieldName
                  }
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue(e.target.name, value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                    marginBottom: '14rem', //30(default)-16(formik error height)
                  }}
                />
                <DeleteProductFieldIcon
                  data-testid={`form-${productFormDTOIndex}-remove-attribute-${attributeIndex}`}
                >
                  <Icon
                    svg={Trash}
                    onClick={() =>
                      handleRemoveAttribute(
                        values,
                        setValues,
                        productFormDTOIndex,
                        attributeIndex,
                        validateForm
                      )
                    }
                    size={20}
                    weight="regular"
                    color={COLORS.PRIMARY}
                  />
                </DeleteProductFieldIcon>
              </InputWrapper>
            );
          case ProductAttributeType.DROPDOWN: {
            const defaultValue = getDropdownDefaultValue(attribute);
            return (
              <SelectWrapper
                pwId={`form-${productFormDTOIndex}-attribute-${attributeIndex}`}
                key={attributeIndex}
                paddingTop={[0, 1].includes(attributeIndex) ? marginSm : ''}
              >
                <Field
                  pwId={`form-${productFormDTOIndex}-attribute-${attributeIndex}`}
                  defaultValue={defaultValue}
                  component={StyledCustomSelect}
                  validate={(value: string) =>
                    validateField(value, attribute.fieldName)
                  }
                  name={`productFormDtos.${productFormDTOIndex}.attributes.${attributeIndex}.value`}
                  errorMessage={getFormikErrorMesssage(
                    productFormDTOIndex,
                    touched,
                    errors,
                    areAllFieldsTouched,
                    `attributes.${attributeIndex}.value`
                  )}
                  placeholder={attribute.fieldName}
                  isMulti={false}
                  isDisabled={false}
                  isSearchable={true}
                  height={'41rem'}
                  options={
                    attribute.options
                      ? attribute.options.map((option: string) => {
                          return { value: option, label: option, key: option };
                        })
                      : []
                  }
                />

                <DeleteProductFieldIcon
                  data-testid={`form-${productFormDTOIndex}-remove-attribute-${attributeIndex}`}
                >
                  <Icon
                    svg={Trash}
                    onClick={() =>
                      handleRemoveAttribute(
                        values,
                        setValues,
                        productFormDTOIndex,
                        attributeIndex,
                        validateForm
                      )
                    }
                    size={20}
                    weight="regular"
                    color={COLORS.PRIMARY}
                  />
                </DeleteProductFieldIcon>
              </SelectWrapper>
            );
          }
        }
      }
    );
  };

  const renderLogyxAttributes = (
    productFormDTO: ICreateQuotationProductFormDTO
  ) => {
    if (productFormDTO.logyxConfigurationIntent?.data) {
      const logyxAttributes = formatLogyxAttributesObjectIntoArray(
        productFormDTO.logyxConfigurationIntent.data
      );

      return (
        <>
          <GreenLine />
          {logyxAttributes?.map(
            (logyxAttribute: ILogyxAttribute, index: number) => {
              const logyxAttributeValue = Array.isArray(logyxAttribute.value)
                ? JSON.stringify(logyxAttribute.value)
                : logyxAttribute.value;
              return (
                <FakeLogyxInputWrapper key={index}>
                  <FakeLogyxInput
                    width="100%"
                    placeholder={logyxAttribute.fieldName}
                    label={logyxAttributeValue}
                    questionType={logyxAttribute.type}
                    marginBottom={marginMd}
                  />
                </FakeLogyxInputWrapper>
              );
            }
          )}
          <SeparatorMd height="40rem" />
          <GreenLine />
        </>
      );
    }
  };

  const handleRemoveProductFormDTO = (
    productFormDTOIndexForRemoval: number,
    values: ICreateQuotationProductFormDTO[],
    uid?: any
  ) => {
    const newProductFormDTOs: ICreateQuotationProductFormDTO[] = values.filter(
      (_, index) => index !== productFormDTOIndexForRemoval
    );
    const removedProduct = document.getElementById(uid);

    if (removedProduct) {
      removedProduct.classList.add('exit-row');
      setTimeout(() => {
        setProductFormDtos(newProductFormDTOs);
        updateHiddenPurchasePriceProductFormDTOIndexes(
          productFormDTOIndexForRemoval,
          hiddenPurchasePriceProductFormDTOIndexes,
          setHiddenPurchasePriceProductFormDTOIndexes
        );
      }, 400);
    } else {
      setProductFormDtos(newProductFormDTOs);
      updateHiddenPurchasePriceProductFormDTOIndexes(
        productFormDTOIndexForRemoval,
        hiddenPurchasePriceProductFormDTOIndexes,
        setHiddenPurchasePriceProductFormDTOIndexes
      );
    }
  };

  return (
    <Container canAddAnotherProduct={canAddAnotherProduct}>
      {canAddAnotherProduct && (
        <>
          <ProductsLabel>{t('Products')}</ProductsLabel>
          <Line />
        </>
      )}
      <Formik
        enableReinitialize
        initialValues={{ productFormDtos: productFormDtos }}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(): any => null}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          setValues,
          isValid,
          values,
          errors,
          touched,
          validateForm,
        }) => {
          const discountsValid = checkAreDiscountsValid(values.productFormDtos);
          return (
            <Form>
              <Wrapper>
                <FieldArray
                  name="productFormDtos"
                  render={(arrayHelpers) => (
                    <div>
                      {values.productFormDtos.map(
                        (
                          productFormDTO: ICreateQuotationProductFormDTO,
                          index
                        ) => {
                          const discountErrorMsg = getDiscountErrorMessage(
                            getIn(values.productFormDtos[index], 'discount'),
                            'discount',
                            (
                              Number(
                                getIn(
                                  values.productFormDtos[index],
                                  'salesPrice'
                                )
                              ) *
                              Number(
                                getIn(values.productFormDtos[index], 'quantity')
                              )
                            ).toString(),
                            getIn(
                              values.productFormDtos[index],
                              'discountType'
                            ),
                            'total sales price'
                          );
                          const isPurchasePriceHidden =
                            hiddenPurchasePriceProductFormDTOIndexes.includes(
                              index
                            );
                          return (
                            <SingleProductWrapper
                              id={productFormDTO.uid}
                              key={
                                productFormDTO.uid
                                  ? productFormDTO.uid + index
                                  : index
                              }
                              ref={(el: HTMLDivElement) =>
                                (productFormDtoRefs.current[index] = el)
                              } // For scrollTo
                            >
                              <ProductTitleWrapper>
                                <SingleProductLabel
                                  canAddAnotherProduct={canAddAnotherProduct}
                                >
                                  {productFormDTO?.product?.name}
                                </SingleProductLabel>
                                {canAddAnotherProduct && (
                                  <TrashCopyWrapper>
                                    <Button
                                      label={t('Copy Product')}
                                      icon={Copy}
                                      sizeIcon={20}
                                      colorIcon={COLORS.PRIMARY}
                                      link
                                      underline
                                      onClick={() => {
                                        arrayHelpers.push(productFormDTO);
                                        setProductFormDtos([
                                          ...values.productFormDtos,
                                          {
                                            ...productFormDTO,
                                            uid: Math.floor(
                                              Math.random() * 1000
                                            ),
                                          },
                                        ]);
                                        handleScrollToLastProduct(150);
                                        setHiddenPurchasePriceProductFormDTOIndexes(
                                          [
                                            ...hiddenPurchasePriceProductFormDTOIndexes,
                                            productFormDtos.length,
                                          ]
                                        );
                                      }}
                                      fontSize="18rem"
                                    />
                                    <Button
                                      label={t('Remove')}
                                      icon={Trash}
                                      sizeIcon={20}
                                      colorIcon={COLORS.RED_200}
                                      weightIcon="regular"
                                      redLink
                                      underline
                                      onClick={() => {
                                        setDeleteFunction(() => () => {
                                          handleRemoveProductFormDTO(
                                            index,
                                            values.productFormDtos,
                                            productFormDTO?.uid
                                          );
                                          setTimeout(
                                            // After removing a product, we cant proceed to next step because there is an error present
                                            // We can either trigger validation manually by interacting with the form, or here with validateForm()
                                            () => validateForm(),
                                            1000
                                          );
                                        });
                                        setIsDeleteModalOpen(true);
                                      }}
                                      fontSize="18rem"
                                    />
                                  </TrashCopyWrapper>
                                )}
                              </ProductTitleWrapper>
                              <Row>
                                <Input
                                  pwId={`quantity-field-${index}`}
                                  type="number"
                                  validate={(value: string) =>
                                    validateNumericFieldWithZero(
                                      value,
                                      'quantity'
                                    )
                                  }
                                  errorMessage={getFormikErrorMesssage(
                                    index,
                                    touched,
                                    errors,
                                    areAllFieldsTouched,
                                    'quantity'
                                  )}
                                  height={'41rem'}
                                  name={`productFormDtos.${index}.quantity`}
                                  placeholder={t('Quantity')}
                                  onBlur={(e) => {
                                    const value = e.target.value.trim();
                                    setFieldValue(e.target.name, value);
                                    handleBlur(e);
                                  }}
                                  wrapperStyles={{
                                    width: '50%',
                                    paddingRight: '15rem',
                                  }}
                                />
                              </Row>
                              <AddedFieldsFlexWrap>
                                {renderLogyxAttributes(
                                  values.productFormDtos[index]
                                )}
                                {productFormDTO.logyxConfigurationIntent
                                  ?.data ? (
                                  <EditWithLogyxContainer
                                    onClick={() => {
                                      setIsEditWithLogyxModalOpen(true);
                                      setProductFormDTOIndexForLogyxEditing(
                                        index
                                      );
                                    }}
                                  >
                                    <LogyxLogoImage
                                      src={LogyxLogo}
                                      alt="Logyx"
                                    />
                                    <EditLabel>{t('Edit')}</EditLabel>
                                  </EditWithLogyxContainer>
                                ) : null}
                              </AddedFieldsFlexWrap>
                              <AddedFieldsFlexWrap>
                                {renderAttributes(
                                  values.productFormDtos[index],
                                  index,
                                  values,
                                  touched,
                                  errors,
                                  handleBlur,
                                  setFieldValue,
                                  setValues,
                                  validateForm
                                )}
                              </AddedFieldsFlexWrap>
                              {withoutPrices ? (
                                <Row marginTop={'10rem'} />
                              ) : (
                                <>
                                  <Row marginTop={'40rem'}>
                                    <Input
                                      pwId={`sales-price-field-${index}`}
                                      type="number"
                                      validate={(value: string) =>
                                        validateNumericFieldWithZero(
                                          value,
                                          'salesPrice'
                                        )
                                      }
                                      symbol={currencySymbol}
                                      errorMessage={getFormikErrorMesssage(
                                        index,
                                        touched,
                                        errors,
                                        areAllFieldsTouched,
                                        'salesPrice'
                                      )}
                                      height={'41rem'}
                                      name={`productFormDtos.${index}.salesPrice`}
                                      placeholder={t('Sales price')}
                                      onBlur={(e) => {
                                        const value = e.target.value.trim();
                                        setFieldValue(e.target.name, value);
                                        handleBlur(e);
                                      }}
                                      wrapperStyles={{
                                        width: '50%',
                                      }}
                                    />
                                    <RelativeDiscountFieldAndDiscountOptionsWrapper>
                                      <AbsoluteDiscountOptionsWrapper>
                                        <DiscountOptionLabel
                                          isSelected={
                                            getIn(
                                              values.productFormDtos[index],
                                              'discountType'
                                            ) === DiscountType.PERCENTAGE
                                          }
                                          onClick={() =>
                                            setFieldValue(
                                              `productFormDtos.${index}.discountType`,
                                              DiscountType.PERCENTAGE
                                            )
                                          }
                                        >
                                          {t('Percentage')}
                                        </DiscountOptionLabel>
                                        <DiscountOptionLabel
                                          data-testid={`amount-button-${index}`}
                                          isSelected={
                                            getIn(
                                              values.productFormDtos[index],
                                              'discountType'
                                            ) === DiscountType.AMOUNT
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `productFormDtos.${index}.discountType`,
                                              DiscountType.AMOUNT
                                            );
                                          }}
                                        >
                                          {t('Amount')}
                                        </DiscountOptionLabel>
                                      </AbsoluteDiscountOptionsWrapper>
                                      <Input
                                        pwId={`discount-field-${index}`}
                                        type="number"
                                        errorMessage={discountErrorMsg}
                                        height={'41rem'}
                                        icon={
                                          getIn(
                                            values.productFormDtos[index],
                                            'discountType'
                                          ) === DiscountType.PERCENTAGE
                                            ? Percent
                                            : undefined
                                        }
                                        symbol={
                                          getIn(
                                            values.productFormDtos[index],
                                            'discountType'
                                          ) === DiscountType.AMOUNT
                                            ? currencySymbol
                                            : undefined
                                        }
                                        name={`productFormDtos.${index}.discount`}
                                        placeholder={t('Discount')}
                                        onBlur={(e) => {
                                          const value = e.target.value.trim();
                                          setFieldValue(e.target.name, value);
                                          handleBlur(e);
                                        }}
                                        wrapperStyles={{
                                          width: '100%',
                                        }}
                                      />
                                    </RelativeDiscountFieldAndDiscountOptionsWrapper>
                                  </Row>
                                  <Row>
                                    <EyeInputWrapper>
                                      <Input
                                        pwId={`purchase-price-field-${index}`}
                                        type={'number'}
                                        secure={isPurchasePriceHidden}
                                        symbol={currencySymbol}
                                        validate={(value: string) =>
                                          validateOptionalNumericFieldWithZero(
                                            value,
                                            'purchasePrice'
                                          )
                                        }
                                        errorMessage={getFormikErrorMesssage(
                                          index,
                                          touched,
                                          errors,
                                          areAllFieldsTouched,
                                          'purchasePrice'
                                        )}
                                        height={'41rem'}
                                        name={`productFormDtos.${index}.purchasePrice`}
                                        placeholder={t(
                                          'Expected purchase price exc. VAT'
                                        )}
                                        onBlur={(e) => {
                                          const value = e.target.value.trim();
                                          setFieldValue(e.target.name, value);
                                          handleBlur(e);
                                        }}
                                        wrapperStyles={{
                                          width: '100%',
                                        }}
                                      />
                                      <EyeWrapper
                                        data-testid={`eye-icon-${index}`}
                                        onClick={() => {
                                          if (!canViewPurchasePrice) return;
                                          togglePurchasePriceHidden(
                                            index,
                                            hiddenPurchasePriceProductFormDTOIndexes,
                                            setHiddenPurchasePriceProductFormDTOIndexes
                                          );
                                        }}
                                      >
                                        <Tooltip
                                          content={
                                            canViewPurchasePrice
                                              ? undefined
                                              : t(
                                                  "You don't have enough permission to see this"
                                                )
                                          }
                                        >
                                          <Icon
                                            svg={
                                              isPurchasePriceHidden
                                                ? Eye
                                                : EyeSlash
                                            }
                                            color={COLORS.BLACK}
                                            size={20}
                                          />
                                        </Tooltip>
                                      </EyeWrapper>
                                    </EyeInputWrapper>
                                  </Row>
                                  <Row>
                                    <FakeInput
                                      data-testid={`total-sales-price-field-${index}`}
                                      label={formatNumber(
                                        calculateTotalSalesPriceForSingleProductFormDTO(
                                          values.productFormDtos[index]
                                        )
                                      )}
                                      placeholder={t('Total sales price')}
                                      isDropdown={false}
                                      width="50%"
                                      marginBottom="24rem"
                                    />
                                    <FakeInput
                                      data-testid="total-purchase-price"
                                      secure={isPurchasePriceHidden}
                                      label={formatNumber(
                                        calculateTotalPurchasePriceForSingleProductFormDTO(
                                          values.productFormDtos[index]
                                        )
                                      )}
                                      placeholder={t(
                                        'Expected total purchase price exc. VAT'
                                      )}
                                      isDropdown={false}
                                      width="50%"
                                      marginBottom="24rem"
                                    />
                                  </Row>
                                </>
                              )}
                              {!productFormDTO.stockItemId &&
                                !hideMeasurementsToggle && (
                                  <SwitchWrapper>
                                    <Switch
                                      size={'small'}
                                      checked={productFormDTO.measurementCheck}
                                      onChange={() =>
                                        setFieldValue(
                                          `productFormDtos.${index}.measurementCheck`,
                                          !productFormDTO.measurementCheck
                                        )
                                      }
                                    />
                                    <SwitchLabel>
                                      {productFormDTO.measurementCheck
                                        ? t('Check all fields')
                                        : t('Check measurements')}
                                    </SwitchLabel>
                                  </SwitchWrapper>
                                )}

                              <Input // Optional field
                                pwId={`placement-field-${index}`}
                                isTextArea
                                validate={validatePlacementField}
                                errorMessage={getFormikErrorMesssage(
                                  index,
                                  touched,
                                  errors,
                                  areAllFieldsTouched,
                                  'placement'
                                )}
                                height={'80rem'}
                                name={`productFormDtos.${index}.placement`}
                                placeholder={t('Internal note')}
                                onBlur={(e) => {
                                  const value = e.target.value.trim();
                                  setFieldValue(e.target.name, value);
                                  handleBlur(e);
                                }}
                                wrapperStyles={{
                                  width: '100%',
                                }}
                              />

                              <RightAlign>
                                <Button
                                  label={t('Add another field')}
                                  icon={Plus}
                                  sizeIcon={15}
                                  weightIcon="regular"
                                  colorIcon={COLORS.PRIMARY}
                                  link
                                  underline
                                  onClick={() => {
                                    // Adding will cause a rerender, so here we set the product form dtos so we dont lose any configuration
                                    setProductFormDtos(values.productFormDtos);
                                    selectedproductFormDTOIndexRef.current =
                                      index;
                                    setIsAddProductFieldModalOpen(true);
                                  }}
                                  fontSize="16rem"
                                />
                              </RightAlign>
                              <Line />
                            </SingleProductWrapper>
                          );
                        }
                      )}
                      {canAddAnotherProduct && (
                        <RightAlign>
                          <Button
                            label={t('Add another product')}
                            icon={Plus}
                            sizeIcon={15}
                            weightIcon="regular"
                            colorIcon={COLORS.PRIMARY}
                            link
                            underline
                            onClick={() => {
                              setProductFormDtos(values.productFormDtos);
                              addNewProduct();
                            }}
                            fontSize="18rem"
                          />
                        </RightAlign>
                      )}
                      <SubmitRow>
                        <Button
                          width={'200rem'}
                          onClick={() => {
                            setProductFormDtos(values.productFormDtos);
                            onBack();
                          }}
                          label={t('Back')}
                          secondary
                        />
                        <Margin>
                          <Button
                            onClick={() => {
                              if (isValid && discountsValid) {
                                setProductFormDtos(values.productFormDtos);
                                onForward(values.productFormDtos); // Calculation of initial downpayment occurs before proceeding to OrderTab
                              } else {
                                setAreAllFieldsTouched(true);
                              }
                            }}
                            label={t('Next')}
                            primary
                            disabled={!values.productFormDtos.length}
                            width={'200rem'}
                          />
                        </Margin>
                      </SubmitRow>
                    </div>
                  )}
                />
              </Wrapper>
              <AddProductFormDTOAttributeModal
                isOpen={isAddProductFieldModalOpen}
                setIsOpen={setIsAddProductFieldModalOpen}
                onSubmit={(fieldNamesArray: string[]) =>
                  handleAddAttributes(
                    fieldNamesArray,
                    selectedproductFormDTOIndexRef.current!
                  )
                }
              />
              <EditWithLogyxModal
                isOpen={isEditWithLogyxModalOpen}
                setIsOpen={setIsEditWithLogyxModalOpen}
                productFormDTOForLogyxEditing={
                  productFormDTOIndexForLogyxEditing !== null
                    ? productFormDtos[productFormDTOIndexForLogyxEditing]
                    : null
                }
                onLogyxEditConfigurationFinished={(
                  editIntent: ILogyxConfigurationIntent,
                  shouldUpdateSalesPrice: boolean
                ) => {
                  if (productFormDTOIndexForLogyxEditing !== null) {
                    const newProductFormDtos = [...values.productFormDtos];
                    newProductFormDtos[
                      productFormDTOIndexForLogyxEditing
                    ].logyxConfigurationIntent = editIntent;
                    if (shouldUpdateSalesPrice) {
                      newProductFormDtos[
                        productFormDTOIndexForLogyxEditing
                      ].salesPrice = extractSalesPriceFromIntent(editIntent);
                    }
                    // Always update the purchase price
                    newProductFormDtos[
                      productFormDTOIndexForLogyxEditing
                    ].purchasePrice =
                      extractExpectedPurchasePriceFromIntent(editIntent);

                    setProductFormDtos(newProductFormDtos);
                    setProductFormDTOIndexForLogyxEditing(null);
                    toast.success(
                      t('Successfully edited product configuration')
                    );
                  } else {
                    toast.error(
                      t('Error occured when updating new configuration data')
                    );
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{
          position: 'fixed',
          marginLeft: '43vw',
          marginTop: '40vh',
        }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteFunction();
              setDeleteFunction(() => null);
              setIsDeleteModalOpen(false);
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Remove product')}
          description={t(`Are you sure you want to remove this Product`) + '?'}
        />
      </Modal>
    </Container>
  );
};

export default ProductList;
