import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/Input/InputFormik';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './AddEditProductGroupModal.styled';
import { addNewProductGroupValidationSchema } from './validation';
import Button from 'components/Button/Button';
import { useAddProductGroup, useEditProductGroup } from './hooks';
import { IAddEditProductGroupData } from './types';
import { IProductGroup } from '../types';

export interface IAddEditProductGroupModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  productGroupForEditing?: IProductGroup | null;
}

export const AddEditProductGroupModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  productGroupForEditing,
}: IAddEditProductGroupModalProps) => {
  const { t } = useTranslation();
  const { mutate: addProductGroup, isLoading: isLoadingAddProductGroup } =
    useAddProductGroup();
  const { mutate: editProductGroup, isLoading: isLoadingEditProductGroup } =
    useEditProductGroup();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={onCancel}
      level="FIRST"
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        padding: '20rem',
        width: '480rem',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>
          {productGroupForEditing
            ? t('Edit product group')
            : t('Create product group')}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            name: productGroupForEditing?.name || '',
            description: productGroupForEditing?.description || '',
          }}
          validationSchema={addNewProductGroupValidationSchema}
          validateOnMount={true}
          validateOnChange={true}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            handleBlur,
            isValid,
          }) => {
            return (
              <Container>
                <Input
                  pwId="name-field"
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'40rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                  }}
                />

                <Input
                  pwId="description-field"
                  isTextArea
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  height={'150rem'}
                  name="description"
                  placeholder={t('Description')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                    marginTop: '10rem',
                  }}
                />
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={
                      !isValid ||
                      isLoadingAddProductGroup ||
                      isLoadingEditProductGroup
                    }
                    onClick={() => {
                      if (productGroupForEditing) {
                        const dto: IAddEditProductGroupData = {
                          id: productGroupForEditing.id,
                          name: values.name,
                          description: values.description
                            ? values.description
                            : null,
                        };
                        editProductGroup(dto, {
                          onSuccess: () => {
                            onCancel(); // Also sets produc group for editing to null
                          },
                        });
                      } else {
                        const dto: IAddEditProductGroupData = {
                          name: values.name,
                          description: values.description,
                        };
                        addProductGroup(dto, {
                          onSuccess: () => {
                            setIsOpen(false);
                          },
                        });
                      }
                    }}
                    primary
                    width="200rem"
                    label={productGroupForEditing ? t('Edit') : t('Create')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
