import { Moment } from 'moment';
import i18n from 'providers/i18n/i18n';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import apiClient from 'services/api/apiService';
import openApiClient from 'services/api/openApiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { AppointmentEmailType } from 'types/Appointment.types';
import { IConfirmAppointmentDTO } from 'pages/Manager/PlanningPage/ViewAppointmentModal/hooks';
import {
  ICreateAppointmentIntervalDTO,
  IStopAppointmentIntervalDTO,
} from 'pages/Manager/PlanningPage/ViewAppointmentModal/Timer/hooks';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { jsonToFormData } from 'utils/jsonToFormData';
import {
  IEditAppointmentDTO,
  IEditPastAppointmentDTO,
} from 'pages/Manager/PlanningPage/EditAppointmentModal/hooks';
import { Option } from 'components/Select/type';

const BASE_URL = '/v1/appointments/';

export const getAppointments = async (
  dateFrom: Moment,
  dateTo: Moment,
  users?: string[],
  workers?: string[],
  resources?: string[],
  selectedTypes?: Option[]
): Promise<any> => {
  const queryParamsObj = {
    date_from: `${dateFrom?.format('YYYY-MM-DD')} 00:00:00`,
    date_to: `${dateTo?.format('YYYY-MM-DD')} 00:00:00`,
    filter_users: users,
    filter_workers: workers,
    filter_resources: resources,
    filter_types: selectedTypes?.map((type: Option) => type.value),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const createAppointment = async (
  createAppointment: ICreateAppointmentDTO,
  shouldSendEmail: boolean,
  note?: string,
  files?: any
): Promise<any> => {
  let url = `${BASE_URL}`;
  const formData = jsonToFormData(createAppointment);
  const defaultAttachmentIds: string[] = [];

  if (shouldSendEmail) {
    url = getUrlWithQueryParams(url, {
      email_customer: true,
      lang: i18n.resolvedLanguage,
    });

    if (note) formData.append('note', note);

    if (files && files.length) {
      files.forEach((file: any) => {
        if (file.id) {
          defaultAttachmentIds.push(file.id);
        } else {
          formData.append('files', file);
        }
      });
    }

    if (defaultAttachmentIds.length > 0) {
      formData.append(
        'default_attachment_ids',
        JSON.stringify(defaultAttachmentIds)
      );
    }
  } else {
    url = getUrlWithQueryParams(url, {
      lang: i18n.resolvedLanguage,
    });
  }
  const { data } = await apiClient.post<any>(url, formData);

  return data.data;
};

export const getSingleAppointment = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const deleteAppointment = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const exportAppointment = async (
  appointmentId: string
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${appointmentId}/export`,
    queryParamsObj
  );

  const { data } = await apiClient.post<any>(fullUrl, appointmentId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const sendAppointmentEmail = async (
  appointmentId: string,
  appointmentEmailType: AppointmentEmailType,
  note: string,
  files: any
) => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${appointmentId}/email`,
    queryParamsObj
  );
  const formData = new FormData();
  const defaultAttachmentIds: string[] = [];

  if (note) formData.append('note', note);

  if (files && files.length) {
    files.forEach((file: any) => {
      if (file.id) {
        defaultAttachmentIds.push(file.id);
      } else {
        formData.append('files', file);
      }
    });
  }

  if (defaultAttachmentIds.length > 0) {
    formData.append(
      'default_attachment_ids',
      JSON.stringify(defaultAttachmentIds)
    );
  }

  formData.append('email_type', appointmentEmailType);
  const { data } = await apiClient.post<any>(fullUrl, formData);
  return data.data;
};

export const confirmAppointment = async (
  dto: IConfirmAppointmentDTO
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${dto.appointmentId}`,
    queryParamsObj
  );

  const formData = new FormData();
  const defaultAttachmentIds: string[] = [];

  formData.append('confirmed', 'true');

  if (dto.send_confirm_email)
    formData.append('send_confirm_email', String(dto.send_confirm_email));
  if (dto.note) formData.append('note', dto.note);

  if (dto.files && dto.files.length) {
    dto.files.forEach((file: any) => {
      if (file.id) {
        defaultAttachmentIds.push(file.id);
      } else {
        formData.append('files', file);
      }
    });
  }

  if (defaultAttachmentIds.length > 0) {
    formData.append(
      'default_attachment_ids',
      JSON.stringify(defaultAttachmentIds)
    );
  }

  const { data } = await apiClient.patch<any>(fullUrl, formData);

  return data.data;
};

export const editAppointment = async (
  id: string,
  editedAppointment: IEditAppointmentDTO | IEditPastAppointmentDTO
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(BASE_URL + `${id}`, queryParamsObj);
  const formData = jsonToFormData(editedAppointment);

  const { data } = await apiClient.patch<any>(fullUrl, formData);

  return data.data;
};

export const markAsFailedAppointment = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}/failed`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const markAsSuccessfulAppointment = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}/successful`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const getCommentsForAppointment = async (
  id: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

interface IAppointmentComment {
  headline: string;
  description: string;
  is_internal: boolean;
}

export const addCommentForAppointment = async (
  id: string,
  commentData: IAppointmentComment
): Promise<any> => {
  const url = `${BASE_URL}${id}/comments`;
  const { data } = await apiClient.post<any>(url, commentData);

  return data.data;
};

export const createAppointmentInterval = async (
  appointmentId: string,
  createAppointmentIntervalDTO: ICreateAppointmentIntervalDTO
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/intervals`;
  const { data } = await apiClient.post<any>(url, createAppointmentIntervalDTO);

  return data.data;
};

export const stopAppointmentInterval = async (
  appointmentId: string,
  stopAppointmentIntervalDTO: IStopAppointmentIntervalDTO
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/intervals/stop`;
  const { data } = await apiClient.post<any>(url, stopAppointmentIntervalDTO);

  return data.data;
};

export const getSingleAppointmentIntervals = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/intervals`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getAppointmentLatestEmailStatus = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/emails/latest-status`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const updateIsAppointmentProcessedInOffice = async (
  appointmentId: string,
  isProcessedInOffice: boolean
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/is-processed`;
  const payload = { is_processed: isProcessedInOffice };
  const { data } = await apiClient.patch<any>(url, payload);

  return data.data;
};

export const getAppointmentAttachmentsPreview = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/attachments-preview`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getCustomerConfirmation = async (
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}customer-appointment-details`,
    queryParamsObj
  );
  const { data } = await openApiClient.get<any>(fullUrl);
  return data.data;
};

export const customerConfirmation = async (
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}customer-confirmation`,
    queryParamsObj
  );
  const { data } = await openApiClient.patch<any>(fullUrl);
  return data.data;
};

export const getRunningFwAppointments = async (): Promise<any> => {
  const url = `${BASE_URL}running`;

  const { data } = await apiClient.get<any>(url);

  return data.data;
};
