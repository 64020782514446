import moment from 'moment';
import {
  ICustomer,
  ICustomerTableAccountingDTO,
  ICustomerTableDTO,
} from 'types/Customer.types';
import { IFileResponseDTO, IFileTableDTO } from 'types/File.types';
import {
  IInvoiceResponseDTO,
  IPurchaseInvoiceLine,
  IPurchaseInvoiceLineTableDTO,
  ISalesInvoiceLine,
  ISalesInvoiceLineTableDTO,
  ISalesInvoice,
  ISalesInvoiceTableDTO,
  IPurchaseInvoice,
  IPurchaseInvoiceTableDTO,
  ISalesCreditInvoiceLineTableDTO,
  ISecondaryPurchaseInvoiceTableDTO,
  ISecondarySalesInvoiceTableDTO,
  ISalesInvoiceTableAccountingDTO,
  IFwSalesInvoiceLineTableDTO,
} from 'types/Invoice.types';
import {
  IQuotation,
  IQuotationTableDTO,
  QuotationTypeIdsEnum,
} from 'types/Quotations.types';
import {
  ISalesOrder,
  ISalesOrdersResponseDTO,
  ISalesOrderTableDTO,
} from 'types/SalesOrders.types';
import {
  IPurchaseOrder,
  IPurchaseOrderTableDTO,
} from 'types/PurchaseOrders.types';
import { ISupplier, ISupplierTableDTO } from 'types/Supplier.types';
import { IStockItem, IStockItemTableDTO } from 'types/StockItem.types';
import { formatNumber, formatNumberCompact } from 'utils/numberFormatter';
import { AccountingType } from 'types/Accounting.types';
import { IAppointment, IFwAppointmentTableDTO } from 'types/Appointment.types';
import { cutLongWords } from 'utils/stringUtils';
import { mapFileExtension } from 'components/Cards/FileCard/helpers';
import { IFullUser, IUserRolesTableDTO } from 'types/User.types';
import { AccountingCellType } from './Cells/AccountingCell/AccountingCell';
import { useTranslation } from 'react-i18next';
import { IToDo, IToDoTableDTO } from 'types/ToDo.types';

const MIN_PRICE_TO_FORMAT = 99999;

const getQuotationTypeString = (typeId: QuotationTypeIdsEnum) => {
  switch (typeId) {
    case QuotationTypeIdsEnum.DEFINITIVE:
      return 'Definitive';
    case QuotationTypeIdsEnum.DRAFT:
      return 'Draft';
    case QuotationTypeIdsEnum.RECURRING:
      return 'Recurring';
  }
};
export const formatQuotationsTableData = (
  quotationsData: IQuotation[],
  currencySymbol: string
) => {
  return quotationsData?.map((quotation: IQuotation) => {
    const quotationTableDTO: IQuotationTableDTO = {
      navigationID: quotation.id,
      number: quotation.number,
      status: quotation.status?.name,
      date: moment(quotation.created_at).format('DD-MM-YYYY'),
      reference: quotation.reference,
      customer: `${quotation.customer.name} ${quotation.customer.last_name}`,
      city: quotation.customer_snapshot?.address?.city,
      measuringDate: quotation.measuring_date
        ? moment(quotation.measuring_date).format('DD-MM-YYYY')
        : '',
      isDraft:
        quotation.type_id === null
          ? '/'
          : getQuotationTypeString(quotation.type_id),
      amount: `${currencySymbol}${
        Number(quotation.total_amount) > MIN_PRICE_TO_FORMAT
          ? formatNumberCompact(Number(quotation.total_amount))
          : formatNumber(quotation.total_amount)
      }`,
      customerID: quotation.customer.id!,
      labels: quotation.labels,
    };
    return quotationTableDTO;
  });
};

export const formatSalesOrdersTableData = (
  salesOrdersData: ISalesOrdersResponseDTO[],
  currencySymbol: string
) => {
  return salesOrdersData?.map((salesOrder: ISalesOrdersResponseDTO) => {
    const salesOrderTableDTO: ISalesOrderTableDTO = {
      navigationID: salesOrder.id,
      number: salesOrder.number,
      payment: salesOrder.payment_status?.name,
      status: salesOrder.status?.name,
      sub_status: salesOrder.sub_status?.name,
      date: moment(salesOrder.date).format('DD-MM-YYYY'),
      reference: salesOrder.reference,
      customer: `${salesOrder.customer.name} ${salesOrder.customer.last_name}`,
      city: salesOrder?.customer_snapshot?.address?.city,
      amount: `${currencySymbol}${
        Number(salesOrder.total_amount) > MIN_PRICE_TO_FORMAT
          ? formatNumberCompact(Number(salesOrder.total_amount))
          : formatNumber(salesOrder.total_amount)
      }`,
      customerID: salesOrder.customer.id!,
      unbilledAmount: `${currencySymbol}${
        Number(salesOrder.unbilled_amount) > MIN_PRICE_TO_FORMAT
          ? formatNumberCompact(Number(salesOrder.unbilled_amount))
          : formatNumber(salesOrder.unbilled_amount)
      }`,
      labels: salesOrder.labels,
    };
    return salesOrderTableDTO;
  });
};

export const formatPurchaseOrdersTableData = (
  purchaseOrdersData: IPurchaseOrder[],
  currencySymbol: string
) => {
  const formatDeliveryDate = (date: string | undefined): string => {
    return date ? moment(date).format('DD-MM-YYYY') : '';
  };

  return purchaseOrdersData?.map((purchaseOrder: IPurchaseOrder) => {
    const purchaseOrderTableDTO: IPurchaseOrderTableDTO = {
      navigationID: purchaseOrder.id,
      number: purchaseOrder.number,
      payment: purchaseOrder.payment_status?.name,
      status: purchaseOrder.status?.name,
      date: moment(purchaseOrder.created_at).format('DD-MM-YYYY'),
      reference: purchaseOrder.reference,
      sales_order_number: purchaseOrder?.sales_order_number,
      company_name: purchaseOrder?.supplier?.company_name,
      amount: `${currencySymbol}${formatNumber(purchaseOrder.total_amount)}`,
      supplierID: purchaseOrder.supplier.id!,
      promised_delivery_date: formatDeliveryDate(
        purchaseOrder.delivery_date
          ? purchaseOrder.delivery_date
          : purchaseOrder.promised_delivery_date
          ? purchaseOrder.promised_delivery_date
          : purchaseOrder.expected_delivery_date
      ),
      labels: purchaseOrder.labels,
    };
    return purchaseOrderTableDTO;
  });
};

export const formatSalesInvoicesTableData = (
  invoicesData: IInvoiceResponseDTO[],
  currencySymbol: string
) => {
  const { t } = useTranslation();
  return invoicesData?.map((invoice: IInvoiceResponseDTO) => {
    const accountingData: ISalesInvoiceTableAccountingDTO[] = [];
    const accountingItem: ISalesInvoiceTableAccountingDTO = {
      accountingCellType: AccountingCellType.SALES_INVOICE,
      accountingType: AccountingType.TWINFIELD,
      id: invoice.twinfield_sales_transaction?.transaction_no,
      accountingIntegrated: invoice.accounting_connected,
    };
    accountingData.push(accountingItem);
    const invoiceTableDTO: ISalesInvoiceTableDTO = {
      navigationID: invoice.id,
      number: invoice.invoice_number,
      status: invoice.payment_status.name,
      externalId: invoice.external_id,
      totalPrice: `${currencySymbol}${formatNumber(invoice.total_amount)}`,
      validTo: moment(invoice.valid_to).format('DD-MM-YYYY'),
      createdAt: moment(invoice.created_at).format('DD-MM-YYYY'),
      isCreditInvoice: invoice.is_credit ? t('Yes') : t('No'),
      accountingData,
      labels: invoice.labels,
    };

    return invoiceTableDTO;
  });
};

export const formatPurchaseInvoicesTableData = (
  invoicesData: IInvoiceResponseDTO[],
  currencySymbol: string
) => {
  const { t } = useTranslation();
  return invoicesData?.map((invoice: IInvoiceResponseDTO) => {
    const invoiceTableDTO: IPurchaseInvoiceTableDTO = {
      navigationID: invoice.id,
      number: invoice.invoice_number,
      status: invoice.payment_status.name,
      externalId: invoice.external_id,
      totalPrice: `${currencySymbol}${formatNumber(invoice.total_amount)}`,
      validTo: moment(invoice.valid_to).format('DD-MM-YYYY'),
      createdAt: moment(invoice.created_at).format('DD-MM-YYYY'),
      isCreditInvoice: invoice.is_credit ? t('Yes') : t('No'),
      labels: invoice.labels,
    };
    return invoiceTableDTO;
  });
};

export const formatCustomersTableData = (
  customersData: ICustomer[],
  currencySymbol: string
) => {
  return customersData?.map((customer: ICustomer) => {
    const accountingData: ICustomerTableAccountingDTO[] = [];
    if (customer.twinfield_customer) {
      const accountingItem: ICustomerTableAccountingDTO = {
        accountingCellType: AccountingCellType.CUSTOMER,
        accountingType: AccountingType.TWINFIELD,
        code: customer.twinfield_customer.code,
      };
      accountingData.push(accountingItem);
    }
    const customerTableDTO: ICustomerTableDTO = {
      navigationID: customer.id,
      number: customer.number,
      name: `${customer?.name} ${customer?.last_name}`,
      address: customer.address.full_address,
      zipCode: customer.address.zip_code,
      companyName: customer.company_name,
      totalSpent: `${currencySymbol}${formatNumber(customer.amount_sold)}`,
      totalOrders: customer.quotes_count,
      accountingData,
    };
    return customerTableDTO;
  });
};

export const formatSuppliersTableData = (
  suppliersData: ISupplier[],
  currencySymbol: string
) => {
  return suppliersData?.map((supplier: ISupplier) => {
    const supplierTableDTO: ISupplierTableDTO = {
      navigationID: supplier.id,
      number: supplier.number,
      companyName: supplier.company_name,
      supplierID: supplier.id,
      address: supplier.address.full_address,
      zipCode: supplier.address.zip_code,
      phoneNumber: supplier.phone,
      totalSpent: `${currencySymbol}${formatNumber(supplier.amount_sold)}`,
      totalOrders: supplier.orders_count,
    };
    return supplierTableDTO;
  });
};

export const formatQuotationFilesTableData = (
  quotationFilesData: IFileResponseDTO[]
) => {
  return quotationFilesData?.map((quotationFile: IFileResponseDTO) => {
    const quotationFileTableDTO: IFileTableDTO = {
      navigationID: quotationFile.id,
      previewUrl: quotationFile.preview_url,
      name: cutLongWords(quotationFile.name, 20),
      fileFullName: quotationFile.name,
      type: mapFileExtension(quotationFile.type),
      date: moment
        .utc(quotationFile.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY HH:mm:ss'),
      addedBy: `${quotationFile.uploaded_by.name} ${quotationFile.uploaded_by.last_name}`,
      entityType: quotationFile.file_type,
      entityId: quotationFile.entity_id,
      userId: quotationFile.uploaded_by.id,
    };
    return quotationFileTableDTO;
  });
};

export const formatSalesOrderFilesTableData = (
  salesOrderFilesData: IFileResponseDTO[]
) => {
  return salesOrderFilesData?.map((salesOrderFile: IFileResponseDTO) => {
    const salesOrderFileTableDTO: IFileTableDTO = {
      navigationID: salesOrderFile.id,
      previewUrl: salesOrderFile.preview_url,
      name: cutLongWords(salesOrderFile.name, 20),
      fileFullName: salesOrderFile.name,
      type: mapFileExtension(salesOrderFile.type),
      date: moment
        .utc(salesOrderFile.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY HH:mm:ss'),
      addedBy: `${salesOrderFile.uploaded_by.name} ${salesOrderFile.uploaded_by.last_name}`,
      entityType: salesOrderFile.file_type,
      entityId: salesOrderFile.entity_id,
      userId: salesOrderFile.uploaded_by.id,
    };
    return salesOrderFileTableDTO;
  });
};

export const formatStockItemsTableData = (stockItemsData: IStockItem[]) => {
  return stockItemsData?.map((stockItem: IStockItem) => {
    const stockItemTableDTO: IStockItemTableDTO = {
      navigationID: stockItem.id,
      number: stockItem.number,
      type: stockItem?.type?.name
        ? stockItem?.type?.name.charAt(0) +
          stockItem?.type?.name.slice(1).toLowerCase()
        : '',
      name: stockItem.name,
      group: stockItem.product_group?.name,
      quantityBought: formatNumber(stockItem.bought_amount),
      status: stockItem.is_active ? 'ACTIVE' : 'DELETED',
      supplierID: stockItem.supplier.id,
      supplier: stockItem?.supplier?.company_name
        ? stockItem.supplier.company_name
        : '',
      inStock: formatNumber(stockItem.in_stock),
      unitsSold: formatNumber(stockItem.sold_amount),
    };
    return stockItemTableDTO;
  });
};

export const formatSalesInvoiceLineTableData = (
  salesInvoiceLineData: ISalesInvoiceLine[],
  tax_rate: string,
  currencySymbol: string
) => {
  const getUnitName = (salesInvoiceLine: ISalesInvoiceLine) => {
    let unitName;
    if (salesInvoiceLine.working_hours_line) {
      unitName = 'Working hours';
    } else if (salesInvoiceLine.is_downpayment_line) {
      unitName = 'Downpayment';
    } else if (salesInvoiceLine.service) {
      unitName = salesInvoiceLine.service.purpose;
    } else if (salesInvoiceLine.stock_item) {
      unitName = salesInvoiceLine.stock_item.name;
    } else {
      unitName = salesInvoiceLine.product ? salesInvoiceLine.product.name : '';
    }
    return unitName;
  };
  const getUnitPrice = (salesInvoiceLine: ISalesInvoiceLine) => {
    let unitPrice;
    if (salesInvoiceLine.working_hours_line) {
      unitPrice = `${currencySymbol}${formatNumber(
        salesInvoiceLine.working_hours_rate
      )}`;
    } else if (salesInvoiceLine.unit_price) {
      unitPrice = `${currencySymbol}${formatNumber(
        salesInvoiceLine.unit_price
      )}`;
    } else {
      unitPrice = '';
    }
    return unitPrice;
  };
  return salesInvoiceLineData?.map((salesInvoiceLine: ISalesInvoiceLine) => {
    const salesInvoiceLineTableDTO: ISalesInvoiceLineTableDTO = {
      unitName: getUnitName(salesInvoiceLine),
      supplierCompanyName: salesInvoiceLine.supplier?.company_name,
      quantity: salesInvoiceLine.quantity,
      vat: tax_rate + '%',
      unitPrice: getUnitPrice(salesInvoiceLine),
      discount: `${currencySymbol}${formatNumber(
        salesInvoiceLine.discount_amount
      )}`,
      totalInclVat: `${currencySymbol}${formatNumber(
        salesInvoiceLine.total_amount
      )}`,
      index: salesInvoiceLine.index,
      dndId: Number(salesInvoiceLine.id),
    };
    return salesInvoiceLineTableDTO;
  });
};

export const formatSalesCreditInvoiceLineTableData = (
  salesInvoiceLineData: ISalesInvoiceLine[],
  taxRate: string,
  currencySymbol: string
) => {
  return salesInvoiceLineData?.map((salesInvoiceLine: ISalesInvoiceLine) => {
    const salesCreditInvoiceLineTableDTO: ISalesCreditInvoiceLineTableDTO = {
      reason: salesInvoiceLine.credit_line_description
        ? salesInvoiceLine.credit_line_description
        : '',
      quantity: formatNumber(salesInvoiceLine.quantity),
      vat: taxRate + '%',
      unitPrice: salesInvoiceLine.credit_unit_price
        ? `${currencySymbol}${formatNumber(salesInvoiceLine.credit_unit_price)}`
        : '',
      totalInclVat: `${currencySymbol}${formatNumber(
        salesInvoiceLine.total_amount
      )}`,
    };
    return salesCreditInvoiceLineTableDTO;
  });
};

export const formatSalesInvoiceFilesTableData = (
  salesInvoiceFilesData: IFileResponseDTO[]
) => {
  return salesInvoiceFilesData?.map((salesInvoiceFile: IFileResponseDTO) => {
    const salesInvoiceFileTableDTO: IFileTableDTO = {
      navigationID: salesInvoiceFile.id,
      previewUrl: salesInvoiceFile.preview_url,
      name: cutLongWords(salesInvoiceFile.name, 23),
      fileFullName: salesInvoiceFile.name,
      type: mapFileExtension(salesInvoiceFile.type),
      date: moment
        .utc(salesInvoiceFile.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY HH:mm:ss'),
      addedBy: `${salesInvoiceFile.uploaded_by.name} ${salesInvoiceFile.uploaded_by.last_name}`,
      entityType: salesInvoiceFile.file_type,
      entityId: salesInvoiceFile.entity_id,
      userId: salesInvoiceFile.uploaded_by.id,
    };
    return salesInvoiceFileTableDTO;
  });
};

export const formatPurchaseInvoiceLineTableData = (
  purchaseInvoiceLineData: IPurchaseInvoiceLine[],
  currencySymbol: string
) => {
  return purchaseInvoiceLineData?.map(
    (purchaseInvoiceLine: IPurchaseInvoiceLine) => {
      const stockItemTableDTO: IPurchaseInvoiceLineTableDTO = {
        quantity: formatNumber(purchaseInvoiceLine.quantity),
        unitPrice: purchaseInvoiceLine.working_hours_line
          ? `${currencySymbol}${formatNumber(
              Number(purchaseInvoiceLine.total_amount) /
                Number(purchaseInvoiceLine.quantity)
            )}`
          : `${currencySymbol}${formatNumber(
              purchaseInvoiceLine.product?.purchase_price
            )}`,
        unitName: purchaseInvoiceLine.product?.name
          ? purchaseInvoiceLine.product?.name
          : 'Working Hours',
        supplierCompanyName: purchaseInvoiceLine.supplier?.company_name,
        billedAmount: `${currencySymbol}${formatNumber(
          purchaseInvoiceLine.billed_amount
        )}`,
        discountedAmount: `${currencySymbol}${formatNumber(
          purchaseInvoiceLine.discount_amount
        )}`,
        totalPrice: `${currencySymbol}${formatNumber(
          purchaseInvoiceLine.total_amount
        )}`,
      };
      return stockItemTableDTO;
    }
  );
};

export const formatPurchaseInvoiceFilesTableData = (
  purchaseInvoiceFilesData: IFileResponseDTO[]
) => {
  return purchaseInvoiceFilesData?.map(
    (purchaseInvoiceFile: IFileResponseDTO) => {
      const purchaseInvoiceFileTableDTO: IFileTableDTO = {
        navigationID: purchaseInvoiceFile.id,
        previewUrl: purchaseInvoiceFile.preview_url,
        name: cutLongWords(purchaseInvoiceFile.name, 20),
        fileFullName: purchaseInvoiceFile.name,
        type: mapFileExtension(purchaseInvoiceFile.type),
        date: moment
          .utc(purchaseInvoiceFile.created_at)
          .utcOffset(moment().utcOffset())
          .format('DD-MM-YYYY HH:mm:ss'),
        addedBy: `${purchaseInvoiceFile.uploaded_by.name} ${purchaseInvoiceFile.uploaded_by.last_name}`,
        entityType: purchaseInvoiceFile.file_type,
        entityId: purchaseInvoiceFile.entity_id,
        userId: purchaseInvoiceFile.uploaded_by.id,
      };
      return purchaseInvoiceFileTableDTO;
    }
  );
};

export const formatSalesInvoiceTableData = (
  salesInvoiceData: ISalesInvoice[],
  currencySymbol: string
) => {
  return salesInvoiceData?.map((salesInvoice: ISalesInvoice) => {
    const salesInvoiceTableDTO: ISecondarySalesInvoiceTableDTO = {
      navigationID: salesInvoice.id.toString(),
      number: salesInvoice.invoice_number,
      status: salesInvoice.payment_status.name,
      externalId: salesInvoice.external_id,
      totalPrice: `${currencySymbol}${formatNumber(salesInvoice.total_amount)}`,
      isCreditInvoice: salesInvoice.is_credit ? 'True' : 'False',
    };
    return salesInvoiceTableDTO;
  });
};

export const formatPurchaseInvoiceTableData = (
  purchaseInvoiceData: IPurchaseInvoice[],
  currencySymbol: string
) => {
  return purchaseInvoiceData?.map((purchaseInvoice: IPurchaseInvoice) => {
    const purchaseInvoiceTableDTO: ISecondaryPurchaseInvoiceTableDTO = {
      navigationID: purchaseInvoice.id.toString(),
      number: purchaseInvoice.invoice_number,
      status: purchaseInvoice.payment_status.name,
      description: purchaseInvoice.description,
      totalPrice: `${currencySymbol}${formatNumber(
        purchaseInvoice.total_amount
      )}`,
      isCreditInvoice: purchaseInvoice.is_credit ? 'True' : 'False',
    };
    return purchaseInvoiceTableDTO;
  });
};

export const formatFwAppointmentsTableData = (appointments: IAppointment[]) => {
  return appointments.map((appointment: IAppointment) => {
    const fwAppointmentTableDTO: IFwAppointmentTableDTO = {
      customer: appointment?.customer
        ? appointment.customer.name + ' ' + appointment?.customer.last_name
        : '',
      address: appointment?.address?.full_address || '',
      phoneNumber: appointment?.customer ? appointment.customer.phone : '',
      status: appointment.status.name,
      type: appointment.type.name,
      date: moment(appointment.date_from).format('DD/MM/YYYY'),
      timeSlot:
        moment(appointment.date_from).format('HH:mm') +
        ' - ' +
        moment(appointment.date_to).format('HH:mm'),
      navigationID: appointment.id,
    };
    return fwAppointmentTableDTO;
  });
};

export const formatFwAppointmentsFilesTableData = (
  fwAppointmentsFilesData: IFileResponseDTO[]
) => {
  return fwAppointmentsFilesData?.map((appointmentFile: IFileResponseDTO) => {
    const appointmentFileTableDTO: IFileTableDTO = {
      navigationID: appointmentFile.id,
      previewUrl: appointmentFile.preview_url,
      name: cutLongWords(appointmentFile.name, 20),
      fileFullName: appointmentFile.name,
      type: mapFileExtension(appointmentFile.type),
      date: moment
        .utc(appointmentFile.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY HH:mm:ss'),
      addedBy: `${appointmentFile.uploaded_by.name} ${appointmentFile.uploaded_by.last_name}`,
      entityType: appointmentFile.file_type,
      entityId: appointmentFile.entity_id,
      userId: appointmentFile.uploaded_by.id,
    };
    return appointmentFileTableDTO;
  });
};

export const formatFwSalesInvoiceLineTableData = (
  salesInvoiceLineData: ISalesInvoiceLine[],
  currencySymbol: string
) => {
  const getUnitName = (salesInvoiceLine: ISalesInvoiceLine) => {
    let unitName;
    if (salesInvoiceLine.working_hours_line) {
      unitName = 'Working hours';
    } else if (salesInvoiceLine.is_downpayment_line) {
      unitName = 'Downpayment';
    } else if (salesInvoiceLine.service) {
      unitName = salesInvoiceLine.service.purpose;
    } else if (salesInvoiceLine.stock_item) {
      unitName = salesInvoiceLine.stock_item.name;
    } else {
      unitName = salesInvoiceLine.product ? salesInvoiceLine.product.name : '';
    }
    return unitName;
  };
  const getUnitPrice = (salesInvoiceLine: ISalesInvoiceLine) => {
    let unitPrice;
    if (salesInvoiceLine.working_hours_line) {
      unitPrice = `${currencySymbol}${formatNumber(
        salesInvoiceLine.working_hours_rate
      )}`;
    } else if (salesInvoiceLine.unit_price) {
      unitPrice = `${currencySymbol}${formatNumber(
        salesInvoiceLine.unit_price
      )}`;
    } else {
      unitPrice = '';
    }
    return unitPrice;
  };
  return salesInvoiceLineData?.map((salesInvoiceLine: ISalesInvoiceLine) => {
    const fwSalesInvoiceLineTableDTO: IFwSalesInvoiceLineTableDTO = {
      name: getUnitName(salesInvoiceLine),
      quantity: salesInvoiceLine.quantity,
      unitPrice: getUnitPrice(salesInvoiceLine),
      attributes: getUnitPrice(salesInvoiceLine),
      totalPrice: `${currencySymbol}${formatNumber(
        salesInvoiceLine.discount_amount
      )}`,
    };
    return fwSalesInvoiceLineTableDTO;
  });
};

export const formatCustomerQuotationsTableData = (
  data: IQuotation[],
  currencySymbol: string
) => {
  return data?.map((row: IQuotation) => {
    const tableDTO: any = {
      navigationID: row.id,
      number: row.number,
      status: row.status?.name,
      date: moment
        .utc(row.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY'),
      reference: row.reference,
      customer: `${row.customer.name} ${row.customer.last_name}`,
      amount: `${currencySymbol}${formatNumber(row.total_amount)}`,
      customerID: row.customer.id,
    };
    return tableDTO;
  });
};

export const formatCustomerSalesOrdersTableData = (
  data: ISalesOrder[],
  currencySymbol: string
) => {
  return data?.map((row: ISalesOrder) => {
    const tableDTO: any = {
      navigationID: row.id,
      number: row.number,
      payment: row.payment_status?.name,
      status: row.status?.name,
      date: moment
        .utc(row.date)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY'),
      reference: row.reference,
      customer: `${row.customer_snapshot?.first_name} ${row.customer_snapshot?.last_name}`,
      amount: `${currencySymbol}${formatNumber(row.total_amount)}`,
      customerID: row.customer_id,
    };
    return tableDTO;
  });
};

export const formatSupplierPurchaseOrdersTableData = (
  data: IPurchaseOrder[],
  currencySymbol: string
) => {
  return data?.map((row: IPurchaseOrder) => {
    const tableDTO: any = {
      navigationID: row.id,
      number: row.number,
      status: row.status?.name,
      payment: row.payment_status?.name,
      date: moment
        .utc(row.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY'),
      company: row.supplier.company_name,
      amount: `${currencySymbol}${formatNumber(row.total_amount)}`,
    };
    return tableDTO;
  });
};

export const formatUserRolesTableData = (userRolesData: IFullUser[]) => {
  return userRolesData?.map((userRole: IFullUser) => {
    const categories = {
      company_admin: { read: false, edit: false, read_id: 13, edit_id: 14 },
      sales: { read: false, edit: false, read_id: 1, edit_id: 2 },
      invoice: { read: false, edit: false, read_id: 3, edit_id: 4 },
      purchase: { read: false, edit: false, read_id: 5, edit_id: 6 },
      stock: { read: false, edit: false, read_id: 7, edit_id: 8 },
      planning: { read: false, edit: false, read_id: 9, edit_id: 10 },
      field_worker: { read: false, edit: false, read_id: 11, edit_id: 12 },
    };

    userRole.permission_groups.forEach((permission) => {
      const parts = permission.name.split('_');
      if (parts.length > 1) {
        const category = parts.slice(1).join('_').toLowerCase();
        const permissionType = permission.name.split('_')[0].toLowerCase();

        if (categories[category]) {
          // Set permission true if exist in user permissions list
          categories[category][`${permissionType}`] = true;
        }
      }
    });

    const userRolesTableDTO: IUserRolesTableDTO = {
      userId: userRole.id,
      name: `${userRole.name} ${userRole.last_name}`,
      license: userRole.license?.name || '',
      adminPermission: categories.company_admin,
      salesPermission: categories.sales,
      invoicePermission: categories.invoice,
      purchasePermission: categories.purchase,
      stockPermission: categories.stock,
      planningPermission: categories.planning,
      fieldWorkerPermission: categories.field_worker,
    };

    return userRolesTableDTO;
  });
};

export const formatToDosTableData = (toDosData: IToDo[]) => {
  return toDosData?.map((todo: IToDo) => {
    const toDoTableDTO: IToDoTableDTO = {
      todoId: todo.id,
      title: todo.title,
      createdBy: `${todo.created_by.name} ${todo.created_by.last_name}`,
      type: todo.todo_type.name
        .toLowerCase()
        .split('_')
        .map((w, i) => (i === 0 ? w.charAt(0).toUpperCase() + w.slice(1) : w))
        .join(' '),
      createdAt: moment
        .utc(todo.created_at)
        .utcOffset(moment().utcOffset())
        .format('DD-MM-YYYY h:mm'),
      priority: todo.todo_priority.name,
      assignedTo: todo.assigned_to
        ? {
            label: `${todo.assigned_to.name} ${todo.assigned_to.last_name}`,
            value: todo.assigned_to.id.toString(),
          }
        : '',
      relatedTo:
        todo.related_type_id && todo.customer
          ? `${todo.customer.name} ${todo.customer.last_name}`
          : todo.related_to_number,
      finished: todo.finished,
      relatedTypeId: todo.related_type_id?.toString(),
      relatedToId: todo.related_to_id?.toString(),
      labels: todo.labels,
    };
    return toDoTableDTO;
  });
};
