import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  FilterTypeLabel,
  FilterTypesWrapper,
  Title,
  TitleContainer,
} from './EditCustomerDetailsModal.styled';
import {
  EditCustomerDetailsTab,
  EditCustomerDetailsType,
  IEditCustomerContactDetails,
  IEditCustomerDetails,
  IEditCustomerShipToDetails,
} from './constants';
import { GeneralTab } from './GeneralTab/GeneralTab';
import { ContactTab } from './ContactTab/ContactTab';
import { ShipToTab } from './ShipToTab/ShipToTab';
import Button from 'components/Button/Button';
import { useParams } from 'react-router-dom';
import {
  getInitialCustomerContactDetailsData,
  getInitialCustomerDetailsData,
  getInitialCustomerShipToDetailsData,
} from './helpers';
import {
  useEditCustomerDetailsOnQuotation,
  useEditCustomerDetailsOnSalesOrder,
} from './hooks';
import { ISalesOrder } from 'types/SalesOrders.types';
import { IQuotation } from 'types/Quotations.types';

interface IEditCustomerDetailsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: ISalesOrder | IQuotation;
  type: EditCustomerDetailsType;
}

export const EditCustomerDetailsModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  data,
  type,
}: IEditCustomerDetailsModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { mutate: editCustomerDetailsOnSalesOrder } =
    useEditCustomerDetailsOnSalesOrder(id!);
  const { mutate: editCustomerDetailsOnQuotation } =
    useEditCustomerDetailsOnQuotation(id!);

  const [selectedFilterType, setSelectedFilterType] =
    useState<EditCustomerDetailsTab>(EditCustomerDetailsTab.GENERAL);

  const [customerDetails, setCustomerDetails] = useState<IEditCustomerDetails>(
    getInitialCustomerDetailsData(data)
  );
  const [contactDetails, setContactDetails] =
    useState<IEditCustomerContactDetails>(
      getInitialCustomerContactDetailsData(data)
    );
  const [shipToDetails, setShipToDetails] =
    useState<IEditCustomerShipToDetails>(
      getInitialCustomerShipToDetailsData(data)
    );

  const [isValidGeneralTab, setIsValidGeneralTab] = useState<boolean>(true);
  const [isValidContactTab, setIsValidContactTab] = useState<boolean>(true);
  const [isValidShipToTab, setIsValidShipToTab] = useState<boolean>(true);

  const handleOnClick = () => {
    const editCustomerDetailsData = {
      email: contactDetails.email!,
      phone_number: contactDetails.phone!,
      first_name: customerDetails.name!,
      last_name: customerDetails.last_name!,
      title: customerDetails.title!,
      address: {
        country: customerDetails.country!,
        street: customerDetails.street!,
        zip_code: customerDetails.zip_code!,
        city: customerDetails.city!,
        house_number: customerDetails.house_number!,
      },
      shipping_data: {
        company_name: shipToDetails.company_name!,
        first_name: shipToDetails.name!,
        last_name: shipToDetails.last_name!,
        address: {
          country: shipToDetails.country!,
          street: shipToDetails.street!,
          zip_code: shipToDetails.zip_code!,
          city: shipToDetails.city!,
          house_number: shipToDetails.house_number!,
        },
        email: shipToDetails.email!,
        title: shipToDetails.title,
        phone_number: shipToDetails.phone,
      },
      company_name: customerDetails.company_name!,
      company_vat: customerDetails.vat_number!,
    };

    switch (type) {
      case EditCustomerDetailsType.QUOTATION:
        editCustomerDetailsOnQuotation(editCustomerDetailsData);
        break;
      case EditCustomerDetailsType.SALES_ORDER:
        editCustomerDetailsOnSalesOrder(editCustomerDetailsData);
        break;
      default:
        return;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit customer details')}</Title>
      </TitleContainer>
      <FilterTypesWrapper>
        <FilterTypeLabel
          data-testid="general-label"
          onClick={() => setSelectedFilterType(EditCustomerDetailsTab.GENERAL)}
          isSelected={selectedFilterType === EditCustomerDetailsTab.GENERAL}
        >
          {t(EditCustomerDetailsTab.GENERAL)}
        </FilterTypeLabel>
        <FilterTypeLabel
          data-testid="contact-label"
          onClick={() => setSelectedFilterType(EditCustomerDetailsTab.CONTACT)}
          isSelected={selectedFilterType === EditCustomerDetailsTab.CONTACT}
        >
          {t(EditCustomerDetailsTab.CONTACT)}
        </FilterTypeLabel>
        <FilterTypeLabel
          data-testid="ship-to-label"
          onClick={() => setSelectedFilterType(EditCustomerDetailsTab.SHIP_TO)}
          isSelected={selectedFilterType === EditCustomerDetailsTab.SHIP_TO}
        >
          {t(EditCustomerDetailsTab.SHIP_TO)}
        </FilterTypeLabel>
      </FilterTypesWrapper>
      {selectedFilterType === EditCustomerDetailsTab.GENERAL && (
        <GeneralTab
          customerDetails={customerDetails}
          setCustomerDetails={setCustomerDetails}
          setIsValid={setIsValidGeneralTab}
        />
      )}
      {selectedFilterType === EditCustomerDetailsTab.CONTACT && (
        <ContactTab
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
          setIsValid={setIsValidContactTab}
        />
      )}
      {selectedFilterType === EditCustomerDetailsTab.SHIP_TO && (
        <ShipToTab
          shipToDetails={shipToDetails}
          setShipToDetails={setShipToDetails}
          setIsValid={setIsValidShipToTab}
        />
      )}
      <ApplyWrapper>
        <Button
          onClick={onCancel}
          secondary
          width="200rem"
          label={t('Cancel')}
        />
        <Button
          onClick={() => {
            if (isValidGeneralTab && isValidContactTab && isValidShipToTab) {
              handleOnClick();
              onCancel();
            }
          }}
          primary
          width="200rem"
          label={t('Apply')}
        />
      </ApplyWrapper>
    </Modal>
  );
};
