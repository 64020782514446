export const INITIALIZE_PAGE_FILTERS = 'INITIALIZE_PAGE_FILTERS';
export const SAVE_PAGE_FILTERS_ON_DISMOUNT = 'SAVE_PAGE_FILTERS_ON_DISMOUNT';
export const UPDATE_PLANNING_TAB = 'UPDATE_PLANNING_TAB';
export const REMOVE_PLANNING_TAB = 'REMOVE_PLANNING_TAB';
export const ADD_PLANNING_TAB = 'ADD_PLANNING_TAB';
export const SELECT_PLANNING_TAB = 'SELECT_PLANNING_TAB';
export const UPDATE_TAB_NAME = 'UPDATE_TAB_NAME';
export const WAS_PLANNING_PAGE_JUST_UPDATED = 'WAS_PLANNING_PAGE_JUST_UPDATED';

export enum FiltersPageEnum {
  QUOTATIONS = 'quotationsPage',
  SALES_ORDERS = 'salesOrdersPage',
  PURCHASE_ORDERS = 'purchaseOrdersPage',
  INVOICES = 'invoicesPage',
  PLANNING = 'planningPage',
  STOCK_ITEMS = 'stockItemsPage',
  CUSTOMERS = 'customersPage',
  SUPPLIERS = 'suppliersPage',
  FW_APPOINTMENTS = 'fwAppointmentsPage',
  ANALYTICS = 'analyticsPage',
  USER_ROLES = 'userRoles',
  TODO = 'todoPage',
}
