import Button from 'components/Button/Button';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteProductGroup, useGetProductGroupsInfinite } from './hooks';
import {
  Container,
  ContentContainer,
  FirstRow,
  Title,
} from './ProductGroupsPage.styled';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { AddEditProductGroupModal } from './AddEditProductGroupModal/AddEditProductGroupModal';
import { ProductGroupRow } from './ProductGroupRow/ProductGroupRow';
import { IProductGroup } from './types';
import { PRODUCT_GROUPS_PER_PAGE } from './constants';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';

export const ProductGroupsPage = () => {
  const { data, hasNextPage, fetchNextPage, isLoading } =
    useGetProductGroupsInfinite(PRODUCT_GROUPS_PER_PAGE, '');
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [productGroupIdForDeletion, setProductGroupIdForDeletion] = useState<
    number | null
  >(null);
  const [productGroupForEditing, setProductGroupForEditing] =
    useState<IProductGroup | null>(null);

  const { mutate: deleteProductGroup, isLoading: isLoadingDeleteProductGroup } =
    useDeleteProductGroup();

  const loadMoreRef = useRef<HTMLDivElement>(null);
  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoading);

  const productGroups: IProductGroup[] = useMemo(() => {
    return (data?.pages || []).map((page) => page.product_group_schema).flat();
  }, [data]);

  const [isAddEditProductGroupModalOpen, setIsAddEditProductGroupModalOpen] =
    useState<boolean>(false);

  return (
    <Container>
      <FirstRow>
        <Title>{t('Product groups')}</Title>
        <Button
          icon={Plus}
          label={t('Create new product group')}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          link
          onClick={() => setIsAddEditProductGroupModalOpen(true)}
          fontSize="18rem"
        />
      </FirstRow>
      <ContentContainer>
        {productGroups?.map((group: IProductGroup, index: number) => {
          return (
            <ProductGroupRow
              key={group.id}
              productGroup={group}
              isLast={productGroups?.length - 1 === index}
              onEditClick={(productGroup: IProductGroup) => {
                setProductGroupForEditing(productGroup);
                setIsAddEditProductGroupModalOpen(true);
              }}
              onDeleteClick={(productGroupId: number) => {
                setProductGroupIdForDeletion(productGroupId);
                setIsDeleteModalOpen(true);
              }}
            />
          );
        })}
      </ContentContainer>
      <div
        ref={loadMoreRef}
        style={{
          height: '1px',
        }}
      />
      <AddEditProductGroupModal
        isOpen={isAddEditProductGroupModalOpen}
        setIsOpen={setIsAddEditProductGroupModalOpen}
        productGroupForEditing={productGroupForEditing}
        onCancel={() => {
          setIsAddEditProductGroupModalOpen(false);
          productGroupForEditing && setProductGroupForEditing(null);
        }}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (productGroupIdForDeletion) {
                deleteProductGroup(productGroupIdForDeletion);
                setProductGroupIdForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: isLoadingDeleteProductGroup,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete product group')}
          description={
            t('Are you sure you want to delete this product group') + '?'
          }
        />
      </Modal>
    </Container>
  );
};
