import {
  Container,
  AddNewProductLabel,
  LoaderWrapper,
} from './NewSalesOrderLinePage.styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductTab from 'pages/Manager/NewQuotationPage/ProductTab/ProductTab';
import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { useEffect, useState } from 'react';
import {
  EMPTY_PURCHASE_PRICE,
  SingleSalesOrderNewProductTab,
} from './constants';
import OverviewTab from 'pages/Manager/NewQuotationPage/OverviewTab/OverviewTab';
import {
  useAddSalesOrderLine,
  useEditSalesOrderLine,
  useGetPendingAppointments,
  useGetPrepopulateSalesOrderLineData,
} from './hooks';
import {
  IAddSalesOrderLineData,
  IEditSalesOrderLine,
} from 'types/SalesOrders.types';
import { RoutesConfig } from 'navigation/routes';
import { isNumeric } from 'utils/validation';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { getInitialProductsData } from './helpers';
import PendingAppointmentsModal from './PendingAppointmentsModal/PendingAppointmentsModal';
import Loader from 'components/Loader/Loader';
import { useGetSingleSalesOrder } from '../hooks';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';

const NewSalesOrderLinePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [includeInAppointmentId, setIncludeInAppointmentId] = useState<
    number | null
  >(null);
  const [isPendingAppointmentsModalOpen, setIsPendingAppointmentsModalOpen] =
    useState<boolean>(false);

  const prepopulateSalesOrderLineData = useGetPrepopulateSalesOrderLineData();

  const [activeTab, setActiveTab] = useState<string>(
    SingleSalesOrderNewProductTab.PRODUCTS
  );
  const [productFormDtos, setProductFormDtos] = useState<
    ICreateQuotationProductFormDTO[]
  >(getInitialProductsData(prepopulateSalesOrderLineData));

  const [isAddStockItemModalOpen, setIsAddStockItemModalOpen] =
    useState<boolean>(false);

  useSocketConnection();

  const { mutate: addSalesOrderLine, status: addStatus } = useAddSalesOrderLine(
    id!
  );
  const { mutate: editSalesOrderLine, status: editStatus } =
    useEditSalesOrderLine(id!, prepopulateSalesOrderLineData?.id);

  useGetSingleSalesOrder(id!);

  useEffect(() => {
    if (addStatus === 'success' || editStatus === 'success') {
      navigate(
        RoutesConfig.SingleSalesOrder.fullPath
          .replace(':id', id! + '/products')
          .replace('/*', '')
      );
    }
  }, [addStatus, editStatus]);

  const handleSubmit = (deductStock?: boolean) => {
    if (prepopulateSalesOrderLineData) {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }
      const editSalesOrderLineData: IEditSalesOrderLine = {
        ...(!productFormDto.isStockItem &&
          !prepopulateSalesOrderLineData && {
            product_id: productFormDto.product.id,
          }),
        quantity: productFormDto.quantity,
        product_attributes: productAttributes,
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? productFormDto.purchasePrice
          : EMPTY_PURCHASE_PRICE,
        product_sales_price: productFormDto.salesPrice,
      };
      editSalesOrderLine(editSalesOrderLineData);
    } else {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }
      const createSalesOrderLineData: IAddSalesOrderLineData = {
        include_in_appointment_id: includeInAppointmentId,
        ...(!productFormDto.isStockItem &&
          !prepopulateSalesOrderLineData && {
            product_id: productFormDto.product.id,
          }),
        quantity: productFormDto.quantity,
        product_attributes: productAttributes,
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
        supplier_id: Number(productFormDto.supplier.id),
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? productFormDto.purchasePrice
          : EMPTY_PURCHASE_PRICE,
        product_sales_price: productFormDto.salesPrice,
        stock_item_id: productFormDto.stockItemId,
        ...(deductStock && { deduct_stock: deductStock }),
      };

      addSalesOrderLine(createSalesOrderLineData);
    }
  };

  // When creating new sales order line, we need to check for existence of future appointments
  const {
    mutate: getPendingAppointments,
    data: pendingAppointmentsData,
    isLoading: isLoadingPendingAppointments,
    isSuccess: isSuccessPendingAppointments,
    reset: resetGetPendingAppointments,
  } = useGetPendingAppointments();

  useEffect(() => {
    if (isSuccessPendingAppointments) {
      if (!pendingAppointmentsData?.pending_appointments?.length) {
        setActiveTab(SingleSalesOrderNewProductTab.OVERVIEW);
        resetGetPendingAppointments();
      } else {
        setIsPendingAppointmentsModalOpen(true);
      }
    }
  }, [isSuccessPendingAppointments]);

  return (
    <Container>
      {isLoadingPendingAppointments && (
        <LoaderWrapper>
          <Loader positionType="relative" />
        </LoaderWrapper>
      )}
      <AddNewProductLabel
        data-testid={
          prepopulateSalesOrderLineData
            ? t('edit-product')
            : t('add-new-product')
        }
      >
        {prepopulateSalesOrderLineData
          ? t('Edit product')
          : t('Add new product')}
      </AddNewProductLabel>
      {activeTab === SingleSalesOrderNewProductTab.PRODUCTS && (
        <ProductTab
          onForward={() => {
            if (!prepopulateSalesOrderLineData) {
              getPendingAppointments(Number(id));
            } else {
              setActiveTab(SingleSalesOrderNewProductTab.OVERVIEW);
            }
          }}
          onBack={() => {
            if (prepopulateSalesOrderLineData) {
              navigate(location.pathname.replace('/add-product', '/products'));
            } else {
              navigate(-1);
            }
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
          canAddAnotherProduct={false}
          hideMeasurementsToggle
        />
      )}
      {activeTab === SingleSalesOrderNewProductTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(SingleSalesOrderNewProductTab.PRODUCTS)}
          onForward={() => {
            productFormDtos[0].isStockItem &&
            productFormDtos[0].stockItemType === 'NORMAL' &&
            !prepopulateSalesOrderLineData &&
            !location.pathname.includes('quotations')
              ? setIsAddStockItemModalOpen(true)
              : handleSubmit();
          }}
          customerDetails={null}
          productFormDtos={productFormDtos}
          orderDetails={null}
          status={addStatus || editStatus}
          newlyCreatedQuotationId={''}
        />
      )}
      <YesOrNoModal
        pwId="yes-or-no-installation-modal"
        isOpen={isAddStockItemModalOpen}
        setIsOpen={setIsAddStockItemModalOpen}
        onNo={() => {
          handleSubmit();
          setIsAddStockItemModalOpen(false);
        }}
        onYes={() => {
          handleSubmit(true);
          setIsAddStockItemModalOpen(false);
        }}
        title=""
        description={`${t(
          'Is the stock item already used for the installation'
        )}?`}
      />
      <PendingAppointmentsModal
        isOpen={isPendingAppointmentsModalOpen}
        setIsOpen={setIsPendingAppointmentsModalOpen}
        appointments={pendingAppointmentsData?.pending_appointments || []}
        onCancel={() => {
          setIsPendingAppointmentsModalOpen(false);
          setActiveTab(SingleSalesOrderNewProductTab.OVERVIEW);
          setIncludeInAppointmentId(null);
          resetGetPendingAppointments();
        }}
        onSelect={(appointmentId: number) => {
          setIsPendingAppointmentsModalOpen(false);
          setIncludeInAppointmentId(appointmentId);
          setActiveTab(SingleSalesOrderNewProductTab.OVERVIEW);
          resetGetPendingAppointments();
        }}
      />
    </Container>
  );
};

export default NewSalesOrderLinePage;
