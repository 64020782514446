import { COLORS, H3, H4 } from 'assets/styled';
import {
  ButtonContainer,
  Container,
  Count,
  Number,
  NumberContainer,
  TitleContainer,
  TitleWrapper,
} from './IncomingDeliveriesCard.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetPurchaseOrderAnalytic } from '../hooks';
import { formatNumberCompact } from 'utils/numberFormatter';
import { RoutesConfig } from 'navigation/routes';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { Truck } from '@phosphor-icons/react';
import MoreButton from 'components/MoreButton/MoreButton';
export const IncomingDeliveriesCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: purchaseOrder } = useGetPurchaseOrderAnalytic();

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={Truck} size={30} color={COLORS.PRIMARY} />
          <H3>{t('Incoming Deliveries')}</H3>
        </TitleWrapper>
      </TitleContainer>
      <NumberContainer>
        <Number>
          <H4>{t('THIS WEEK')}</H4>
          <Count>
            {purchaseOrder?.this_week_count === undefined ? (
              <Spinner />
            ) : (
              formatNumberCompact(purchaseOrder?.this_week_count)
            )}
          </Count>
        </Number>
        <Number>
          <H4>{t('NEXT WEEK')}</H4>
          <Count>
            {purchaseOrder?.next_week_count === undefined ? (
              <Spinner />
            ) : (
              formatNumberCompact(purchaseOrder?.next_week_count)
            )}
          </Count>
        </Number>
        <Number>
          <H4>{t('LAGGED')}</H4>

          <Count>
            {purchaseOrder?.lagged_count === undefined ? (
              <Spinner />
            ) : (
              formatNumberCompact(purchaseOrder?.lagged_count)
            )}
          </Count>
        </Number>
      </NumberContainer>
      <ButtonContainer
        onClick={() => navigate(RoutesConfig.PurchaseOrders.fullPath)}
      >
        <MoreButton />
      </ButtonContainer>
    </Container>
  );
};
