import { SortDirection } from 'components/Table/constants';
import { IEditStockAmountDTO } from 'pages/Manager/SingleStockItemPage/EditStockAmountModal/hooks';
import { IEditStockDTO } from 'pages/Manager/SingleStockItemPage/EditStockItemDetailsModal/hooks';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { ICreateStockItemData } from 'types/StockItem.types';

const BASE_URL = '/v1/stock-items/';

export const createStockItem = async (
  createStockItemData: ICreateStockItemData
): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, createStockItemData);

  return data.data;
};

export const getStockInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getStockItems = async (
  page: number,
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  supplierId?: number,
  advancedFilters?: string,
  productGroupId?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    is_active: isActive?.toString(),
    ...(supplierId && { supplier_id: supplierId }),
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
    ...(productGroupId && { product_group_id: productGroupId }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSingleStockItem = async (stockItemId: string): Promise<any> => {
  const url = `${BASE_URL}${stockItemId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const deleteStockitem = async (stockItemId: string): Promise<any> => {
  const url = `${BASE_URL}${stockItemId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const activateStockItem = async (stockItemId: string): Promise<any> => {
  const url = `${BASE_URL}${stockItemId}`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const editStockItemAmount = async (
  editStockAmountData: IEditStockAmountDTO
): Promise<any> => {
  const url = `${BASE_URL}${editStockAmountData.stockItemId}`;
  const payload = { in_stock: editStockAmountData.amount };
  const { data } = await apiClient.patch<any>(url, payload);

  return data.data;
};

export const editStockItemDetails = async (
  editStockData: IEditStockDTO,
  stockItemId: string
): Promise<any> => {
  const url = `${BASE_URL}${stockItemId}`;
  const { data } = await apiClient.put<any>(url, editStockData);

  return data.data;
};

export const editStockItemAttributes = async (
  editStockItemAttributesData: any
): Promise<any> => {
  const url = `${BASE_URL}${editStockItemAttributesData.stockItemId}/attributes`;
  const { data } = await apiClient.put<any>(url, {
    attributes: editStockItemAttributesData.attributes,
  });

  return data.data;
};
