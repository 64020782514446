import Draggable from 'components/DragAndDrop/Draggable/Draggable';
import { QuotationProductCard } from 'pages/Manager/SingleQuotation/SingleQuotationProductsPage/QuotationProductCard/QuotationProductCard';
import { SalesOrderProductCard } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderProductsPage/SalesOrderProductCard/SalesOrderProductCard';
import { useCallback, useEffect, useState } from 'react';
import { IPurchaseOrder, IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { IQuotationLine } from 'types/Quotations.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import {
  isOrderDifferentFromInitial,
  sortLinesByIndex,
  swapDragAndHoverLineIndexes,
} from './helpers';
import { PurchaseOrderProductCard } from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderProductsPage/PurchaseOrderProductCard/PurchaseOrderProductCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  IReorderLineDTO,
  IReorderLinesRequestDTO,
  useReorderLines,
} from './hooks';
import { useParams } from 'react-router-dom';
import DndProvider from 'components/DragAndDrop/DndProvider/DndProvider';
import { FwAppointmentProductCard } from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentProductsPage/FwAppointmentProductCard/FwAppointmentProductCard';
import { IAppointment, IFwAppointmentLine } from 'types/Appointment.types';

export type IDraggableLine =
  | IQuotationLine
  | ISalesOrderLine
  | IPurchaseOrderLine
  | IFwAppointmentLine;

export enum DraggableLineType {
  QUOTATION = 'QUOTATION',
  SALES_ORDER = 'SALES_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  APPOINTMENT = 'APPOINTMENT',
}

interface IDraggableLinesProps {
  lines: undefined | IDraggableLine[];
  lineType: DraggableLineType;
  onEditClick?: (line: IDraggableLine) => void;
  onDeleteClick?: (line: IDraggableLine) => void;
  purchaseOrder?: IPurchaseOrder; // Used only for PurchaseOrderProductCard
  appointment?: IAppointment; // Used only for FwAppointmentProductCard
  isDragDisabled?: boolean;
  withCssTransition?: boolean;
  showMeasurementsToggle?: boolean;
}

const DraggableLines = ({
  lines,
  lineType,
  onEditClick,
  onDeleteClick,
  purchaseOrder,
  appointment,
  isDragDisabled = false,
  withCssTransition = true,
  showMeasurementsToggle = false,
}: IDraggableLinesProps) => {
  const { id } = useParams();
  const [localLines, setLocalLines] = useState<IDraggableLine[]>(lines || []);
  const [draggedId, setDraggedId] = useState<number | null>(null);
  useEffect(() => {
    // Execute if lines are fetched and local lines havent been set
    if (
      lines &&
      (!localLines.length ||
        localLines.length !== lines?.length ||
        JSON.stringify(lines) !== JSON.stringify(localLines))
    ) {
      setLocalLines(lines);
    }
  }, [lines, isDragDisabled]);

  const reorderLinesMutation = useReorderLines(Number(id), lineType);

  const onDragHover = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (localLines && dragIndex !== hoverIndex) {
        const newLocalLines = swapDragAndHoverLineIndexes(
          localLines,
          dragIndex,
          hoverIndex
        );
        setLocalLines(newLocalLines);
      }
    },
    [localLines]
  );

  useEffect(() => {
    if (!draggedId) {
      const reorderLinesDTOs: IReorderLineDTO[] = localLines.map(
        (line: IDraggableLine) => {
          return { lineId: Number(line.id), lineIndex: Number(line.index) };
        }
      );
      if (lines && isOrderDifferentFromInitial(lines, reorderLinesDTOs)) {
        const dto: IReorderLinesRequestDTO = { lines: reorderLinesDTOs };
        reorderLinesMutation(dto);
      }
    }
  }, [localLines, draggedId]);

  if (!lines) {
    return <></>;
  }

  const renderLineCard = (line: IDraggableLine) => {
    switch (lineType) {
      case DraggableLineType.QUOTATION:
        return (
          <QuotationProductCard
            quotationLine={line as IQuotationLine}
            onEditClick={onEditClick ? () => onEditClick(line) : undefined}
            onDeleteClick={
              onDeleteClick ? () => onDeleteClick(line) : undefined
            }
            showMeasurementsToggle={showMeasurementsToggle}
          />
        );
      case DraggableLineType.SALES_ORDER:
        return (
          <SalesOrderProductCard
            salesOrderLine={line as ISalesOrderLine}
            onEditClick={onEditClick ? () => onEditClick(line) : undefined}
            onDeleteClick={
              onDeleteClick ? () => onDeleteClick(line) : undefined
            }
          />
        );
      case DraggableLineType.PURCHASE_ORDER:
        return (
          <PurchaseOrderProductCard
            purchaseOrder={purchaseOrder!}
            purchaseOrderLine={line as IPurchaseOrderLine}
            onEditClick={onEditClick ? () => onEditClick(line) : undefined}
          />
        );
      case DraggableLineType.APPOINTMENT:
        return (
          <FwAppointmentProductCard
            appointment={appointment!}
            appointmentLine={line as IFwAppointmentLine}
            onEditClick={onEditClick ? () => onEditClick(line) : undefined}
            onDeleteClick={
              onDeleteClick ? () => onDeleteClick(line) : undefined
            }
          />
        );

      default:
        return <div>Unknown Line Type</div>;
    }
  };

  if (withCssTransition) {
    return (
      <DndProvider>
        <TransitionGroup component={null}>
          {sortLinesByIndex(localLines).map((line: IDraggableLine) => {
            return (
              <CSSTransition
                key={`css-transition-${line.id}`}
                timeout={500}
                classNames={'slide'}
              >
                <Draggable
                  key={`draggable-line-${line.id}-${line.index}`}
                  id={Number(line.id)}
                  index={line.index}
                  onDragHover={onDragHover}
                  style={{
                    marginBottom: '30rem',
                  }}
                  draggedId={draggedId}
                  setDraggedId={setDraggedId}
                  isDragDisabled={isDragDisabled}
                >
                  {renderLineCard(line)}
                </Draggable>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </DndProvider>
    );
  } else {
    return (
      <DndProvider>
        {sortLinesByIndex(localLines).map((line: IDraggableLine) => {
          return (
            <Draggable
              key={`draggable-line-${line.id}-${line.index}`}
              id={Number(line.id)}
              index={line.index}
              onDragHover={onDragHover}
              style={{
                marginBottom: '30rem',
              }}
              draggedId={draggedId}
              setDraggedId={setDraggedId}
              isDragDisabled={isDragDisabled}
            >
              {renderLineCard(line)}
            </Draggable>
          );
        })}
      </DndProvider>
    );
  }
};

export default DraggableLines;
