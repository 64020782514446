import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editAppointment } from 'services/Appointment/AppointmentService';
import { IWorkerAndResource } from '../WorkersModal/type';
import { Option } from 'components/Select/type';
import { IUser } from 'types/User.types';

export interface IEditAppointmentDTO {
  date_from: string;
  date_to: string;
  users_ids: number[];
  workers_ids: number[];
  resources_ids: number[];
  sales_order_lines_ids?: number[];
  description?: string;
  purpose?: string;
  arrival_from?: string;
  arrival_to?: string;
}

export interface IEditPastAppointmentDTO {
  users_ids: number[];
}

export const useEditAppointment = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editAppointmentDTO: IEditAppointmentDTO | IEditPastAppointmentDTO) =>
      editAppointment(appointmentId, editAppointmentDTO),
    {
      onSuccess: () => {
        toast.success(t('Appointment edited'), {
          className: ReactMutationKeys.EDIT_APPOINTMENT,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_APPOINTMENT]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SALES_ORDER_LINES_SUMMARY,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_APPOINTMENT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_APPOINTMENT,
    }
  );
};

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetUserOptions = (initialData: any, usersData: any) => {
  // Prepare initial user options from initial data
  const initialUserOptions = useMemo(() => {
    return (
      initialData?.appointment?.users?.map((user: IUser) => ({
        value: user.id,
        label: user.name + ' ' + user.last_name,
      })) || []
    );
  }, [initialData]);

  // Combine initial and fetched worker options
  const users = useMemo(() => {
    const fetchedUsers = usersData?.pages?.length
      ? usersData.pages
          .map((page: any) => page.users)
          .flat()
          .map((user: IUser) => ({
            value: user.id,
            label: user.name + ' ' + user.last_name,
          }))
      : [];

    // Filter out duplicates
    const uniqueFetchedUserOptions = fetchedUsers.filter(
      (fetchedUserOption: Option) =>
        !initialUserOptions.some(
          (initialUserOption: Option) =>
            initialUserOption.value === fetchedUserOption.value
        )
    );

    return [...initialUserOptions, ...uniqueFetchedUserOptions];
  }, [usersData, initialUserOptions]);

  return users;
};

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetWorkerOptions = (initialData: any, workersData: any) => {
  // Prepare initial worker options from initial data
  const initialWorkerOptions = useMemo(() => {
    return (
      initialData?.appointment?.workers?.map((worker: IWorkerAndResource) => ({
        value: worker.id,
        label: worker.name,
      })) || []
    );
  }, [initialData]);

  // Combine initial and fetched worker options
  const workers = useMemo(() => {
    const fetchedWorkers = workersData?.pages?.length
      ? workersData.pages
          .map((page: any) => page.workers)
          .flat()
          .map((worker: IWorkerAndResource) => ({
            value: worker?.id,
            label: worker?.name,
          }))
      : [];

    // Filter out duplicates
    const uniqueFetchedWorkerOptions = fetchedWorkers.filter(
      (fetchedWorkerOption: Option) =>
        !initialWorkerOptions.some(
          (initialWorkerOption: Option) =>
            initialWorkerOption.value === fetchedWorkerOption.value
        )
    );

    return [...initialWorkerOptions, ...uniqueFetchedWorkerOptions];
  }, [workersData, initialWorkerOptions]);

  return workers;
};

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetResourceOptions = (initialData: any, resourcesData: any) => {
  // Prepare initial resource options from initial data
  const initialResourceOptions = useMemo(() => {
    return (
      initialData?.appointment?.resources?.map(
        (resource: IWorkerAndResource) => ({
          value: resource.id,
          label: resource.name,
        })
      ) || []
    );
  }, [initialData]);

  // Combine initial and fetched resource options
  const resources = useMemo(() => {
    const fetchedResources = resourcesData?.pages?.length
      ? resourcesData.pages
          .map((page: any) => page.resources)
          .flat()
          .map((resource: IWorkerAndResource) => ({
            value: resource?.id,
            label: resource?.name,
          }))
      : [];

    // Filter out duplicates
    const uniqueFetchedResourceOptions = fetchedResources.filter(
      (fetchedResourceOption: Option) =>
        !initialResourceOptions.some(
          (initialResourceOption: Option) =>
            initialResourceOption.value === fetchedResourceOption.value
        )
    );

    return [...initialResourceOptions, ...uniqueFetchedResourceOptions];
  }, [resourcesData, initialResourceOptions]);

  return resources;
};
