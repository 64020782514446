import styled from 'styled-components';
import { COLORS, borderRadiusSm, marginMd } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import { Field } from 'formik';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  margin-top: 14rem; //30-16
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-left: 30rem;
  margin-right: 10rem;
  width: 203rem;
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;

export const StyledSelect = styled(Field)`
  width: 50%;
`;
export const StyledCustomSelectProductGroup = styled(CustomSelect)`
  width: 100%;
`;
