import { H3, marginMd } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  StyledCustomSelect,
  ExistingOptionLabel,
  SecondRow,
  SelectWrapper,
  FieldWrapper,
} from './SupplierTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { createStockItemSupplierTabSchema } from './validation';
import { ICreateStockItemSupplierDetails } from './constants';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { ISupplier } from 'types/Supplier.types';
import { Select } from 'components/Select/Select';
import debounce from 'lodash.debounce';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetSuppliersInfinite } from 'pages/Manager/NewQuotationPage/ProductTab/AddNewProduct/hooks';
import { SUPPLIERS_PER_PAGE } from 'pages/Manager/SuppliersPage/constants';
import {
  IGetCityAndAddressDTO,
  useGetCityAndAddress,
} from 'utils/hooks/useGetCityAndAddress';
import { isValidGetCityAndAddressData } from 'pages/Manager/NewQuotationPage/CustomerTab/helpers';

interface ISupplierProps {
  onForward: () => void;
  supplierDetails: ICreateStockItemSupplierDetails;
  setSupplierDetails: Dispatch<SetStateAction<ICreateStockItemSupplierDetails>>;
  resetSupplierTabForm: () => void;
  handlePopulateNewStockItemSupplierTab: (supplier: ISupplier) => void;
  isUsingExistingSupplier: boolean;
  setIsUsingExistingSupplier: Dispatch<SetStateAction<boolean>>;
}

const SupplierTab = ({
  onForward,
  supplierDetails,
  setSupplierDetails,
  resetSupplierTabForm,
  handlePopulateNewStockItemSupplierTab,
  isUsingExistingSupplier,
  setIsUsingExistingSupplier,
}: ISupplierProps) => {
  const countryOptions = useGetCountryOptions();
  const { t } = useTranslation();
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier | null>(
    null
  );
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const {
    data: suppliersData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBy, true);

  const supplierOptions = useMemo(() => {
    if (suppliersData?.pages?.length) {
      return suppliersData.pages
        .map((page) => page.suppliers)
        .flat()
        .map((supplier: any) => {
          return {
            value: supplier,
            label: supplier?.company_name,
            key: supplier?.id,
          };
        });
    }
    return [];
  }, [suppliersData]);

  useEffect(() => {
    if (selectedSupplier) {
      handlePopulateNewStockItemSupplierTab(selectedSupplier);
    }
  }, [selectedSupplier]);

  // Get city and address handling
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: isSuccessGetCityAndAddress,
    isLoading: isLoadingGetCityAndAddress,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddress &&
      cityAndAddressData &&
      !formikRef?.current.values.city
    ) {
      formikRef?.current.setFieldValue('city', cityAndAddressData.address.city);
      if (
        formikRef?.current.values.house_number &&
        formikRef?.current.values.street
      )
        getCityAndAddress({
          countryCode: formikRef?.current.values.country,
          zipCode: formikRef?.current.values.code,
          houseNumber: formikRef?.current.values.house_number,
          city: cityAndAddressData.address.city,
          street: formikRef?.current.values.street,
        });
    }
  }, [isSuccessGetCityAndAddress]);

  const debouncedGetCityAndAddress = debounce(
    (getCityAndAddressData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressData))
        getCityAndAddress({
          countryCode: getCityAndAddressData.countryCode,
          zipCode: getCityAndAddressData.zipCode,
          houseNumber: getCityAndAddressData.houseNumber,
          city: getCityAndAddressData.city,
          street: getCityAndAddressData.street,
        });
    },
    1000
  );

  return (
    <Container>
      <FirstRow>
        <H3>{t('Supplier details')}</H3>
        <SelectWrapper>
          {isUsingExistingSupplier && (
            <Select
              pwId="existing-supplier"
              name="existingSupplier"
              placeholder={t('Select Supplier')}
              isMulti={false}
              isDisabled={false}
              isSearchable={true}
              onChange={(e: any) => setSelectedSupplier(e.value)}
              options={supplierOptions}
              onInputChange={(value: string) => setSearchBy(value ? value : '')}
              isLoading={isLoading}
              translate={false}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          )}
        </SelectWrapper>
      </FirstRow>
      <SecondRow>
        <ExistingOptionLabel
          data-testid="existing-supplier-label"
          isSelected={isUsingExistingSupplier}
          onClick={() => {
            if (!isUsingExistingSupplier) {
              setIsUsingExistingSupplier(true);
              resetSupplierTabForm();
              setSelectedSupplier(null);
            }
          }}
          marginRight={marginMd}
        >
          {t('Existing Supplier')}
        </ExistingOptionLabel>
        <ExistingOptionLabel
          isSelected={!isUsingExistingSupplier}
          onClick={() => {
            if (isUsingExistingSupplier) {
              setIsUsingExistingSupplier(false);
              resetSupplierTabForm();
            }
          }}
        >
          {t('New Supplier')}
        </ExistingOptionLabel>
      </SecondRow>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={supplierDetails}
        validationSchema={createStockItemSupplierTabSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onForward}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId="contact-person-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={
                    touched['contact_person'] ? errors['contact_person'] : ''
                  }
                  height={'41rem'}
                  name="contact_person"
                  placeholder={t('Contact person')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('contact_person', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="phone-number-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={touched['phone'] ? errors['phone'] : ''}
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="company-name-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={
                    touched['company_name'] ? errors['company_name'] : ''
                  }
                  height={'41rem'}
                  name="company_name"
                  placeholder={t('Company Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('company_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="vat-number-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={
                    touched['vat_number'] ? errors['vat_number'] : ''
                  }
                  height={'41rem'}
                  name="vat_number"
                  placeholder={t('Company VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('vat_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="email-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={touched['email'] ? errors['email'] : ''}
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="coc-number-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={
                    touched['coc_number'] ? errors['coc_number'] : ''
                  }
                  height={'41rem'}
                  name="coc_number"
                  placeholder={t('COC number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('coc_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId="zip-code-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={touched['code'] ? errors['code'] : ''}
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: value,
                        houseNumber: values.city ? values.house_number : '',
                        city: values.city,
                        street: values.city ? values.street : '',
                      });
                    }
                  }}
                />
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId="house-number-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={
                    touched['house_number'] ? errors['house_number'] : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.street && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: value,
                        city: values.city,
                        street: values.street,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId="city-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={touched['city'] ? errors['city'] : ''}
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: value,
                        street: values.street,
                      });
                    }
                  }}
                />
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId="street-field"
                  disabled={isUsingExistingSupplier}
                  errorMessage={touched['street'] ? errors['street'] : ''}
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.city && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: values.city,
                        street: value,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <FieldWrapper>
                  <Field
                    isLoading={isLoadingGetCityAndAddress}
                    pwId="country-field"
                    disabled={isUsingExistingSupplier}
                    errorMessage={touched['country'] ? errors['country'] : ''}
                    height={'41rem'}
                    name="country"
                    width="50%"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Country Code')}
                    isMulti={false}
                    onSelect={(value: any) => {
                      setFieldValue('country', value);

                      debouncedGetCityAndAddress({
                        countryCode: value.value,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: values.city,
                        street: values.street,
                      });
                    }}
                    translate={false}
                  />
                </FieldWrapper>
              </Row>
              <AlignRow>
                <Button
                  width={'200rem'}
                  onClick={() => {
                    submitForm();
                    if (isValid) {
                      setSupplierDetails(values);
                      onForward();
                    }
                  }}
                  label={t('Next')}
                  primary
                />
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SupplierTab;
