import { Option } from 'components/Select/type';

export enum DateInterval {
  ALL = 'all',
  CURRENT_MONTH = 'current_month',
  NEXT_MONTH = 'next_month',
  UNKNOWN = 'unknown',
}

export const useGetExpectedInvoiceDateOptions = () => {
  const dateIntervalOptions: Option[] = [
    { value: DateInterval.ALL, label: 'All', key: 'all' },
    {
      value: DateInterval.CURRENT_MONTH,
      label: 'Current month',
      key: 'current_month',
    },
    { value: DateInterval.NEXT_MONTH, label: 'Next Month', key: 'next_month' },
    { value: DateInterval.UNKNOWN, label: 'Unknown', key: 'unknown' },
  ];
  return dateIntervalOptions;
};
