import { Option } from 'components/Select/type';
import { IProductGroup } from 'pages/Manager/ProductGroupsPage/types';
import { StockItemType } from 'pages/Manager/SingleStockItemPage/constants';

export interface ICreateStockItemDetails {
  min_order_amount: string;
  in_stock: string;
  name: string;
  description: string;
  unit_of_measure: string;
  sku: string;
  price: string;
  sales_price: string;
  purchase_price: string;
  reserved_amount: string;
  type_id: string;
  type: StockItemType;
  number_supplier?: string;
  product_group?: IProductGroup;
}

export const newEmptyItem: ICreateStockItemDetails = {
  min_order_amount: '',
  in_stock: '',
  purchase_price: '',
  reserved_amount: '',
  name: '',
  description: '',
  unit_of_measure: '1',
  price: '',
  sales_price: '',
  sku: '',
  type_id: '1',
  type: StockItemType.NORMAL,
  number_supplier: '',
};

export const STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER_PER_PAGE = 20;

export const useGetUnitOfMeasureOptions = () => {
  const unitOfMeasureOptions: Option[] = [
    { value: '1', label: 'Piece', key: 'piece' },
    { value: '2', label: 'Kilogram', key: 'kilogram' },
    { value: '3', label: 'Liter', key: 'liter' },
    { value: '4', label: 'Meter', key: 'meter' },
  ];
  return unitOfMeasureOptions;
};

export const typeOptions: Option[] = [
  { value: '1', label: 'Normal', key: 'normal' },
  { value: '2', label: 'Service', key: 'service' },
];
