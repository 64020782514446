import { COLORS, H4 } from 'assets/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductAttribute } from 'types/Product.types';
import { formatAttributeObjectIntoArray } from 'utils/productAttributeFormatting/formatAttributeObjectIntoArray';
import {
  AttributesWrapper,
  Card,
  ColumnContainer,
  DeleteIcon,
  DeleteLabel,
  DescriptionContainer,
  EditIcon,
  EditLabel,
  GreenLine,
  Label,
  LabelTitle,
  LogyxAttributesLabel,
  MainContainer,
  TextColumnContainer,
  TextContainer,
  TitleContainer,
  TitleWrapper,
  TypeLabel,
  EditDeleteWrapperRelative,
  WrapperRelative,
  ActionsRow,
  TitleLabel,
  SwitchContainer,
  SwitchWrapper,
  SwitchLabel,
} from './FwAppointmentProductCard.styled';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute, QuestionType } from 'store/Logyx/types';
import i18n from 'providers/i18n/i18n';
import {
  canDeleteAppointmentLine,
  canEditAppointmentLine,
  getProductCardTitle,
  getProductCardType,
} from './helpers';
import {
  AppointmentStatus,
  AppointmentType,
  IAppointment,
  IEditFwAppointmentLineDTO,
  IFwAppointmentLine,
} from 'types/Appointment.types';
import Icon from 'components/Icon/Icon';
import { PencilSimple } from '@phosphor-icons/react/dist/ssr';
import { CaretDown, CaretUp, Trash } from '@phosphor-icons/react';
import { getBooleanAttributeLabel } from 'components/DragAndDrop/DraggableLines/helpers';
import { QuotationTypeIdsEnum } from 'types/Quotations.types';
import Spinner from 'components/Spinner/Spinner';
import { Switch } from '@mui/material';
import { useEditFwAppointmentLine } from '../../FwNewAppointmentLinePage/hooks';
import { useParams } from 'react-router-dom';

interface IFwAppointmentProductCardProps {
  appointment: IAppointment;
  appointmentLine: IFwAppointmentLine;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  withoutContainer?: boolean;
}

export const FwAppointmentProductCard = ({
  appointment,
  appointmentLine,
  onDeleteClick,
  onEditClick,
  withoutContainer = false,
}: IFwAppointmentProductCardProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const attributes = formatAttributeObjectIntoArray(appointmentLine.attributes);
  const logyxAttributes = formatLogyxAttributesObjectIntoArray(
    appointmentLine.product?.logyx_configuration_intent?.data
  );
  const allAttributes = [...logyxAttributes, ...attributes];
  const showMeasurementsToggle =
    (appointment?.quotation?.type?.id === QuotationTypeIdsEnum.DRAFT ||
      appointment?.sales_order) &&
    appointment?.status.name === AppointmentStatus.FIELD_WORK_IN_PROGRESS &&
    appointment?.type.name === AppointmentType.MEASURING &&
    !withoutContainer; //Do not show Measurement toggle on a Report form

  const { mutate: editAppointmentLine, isLoading } = useEditFwAppointmentLine(
    id!,
    appointmentLine?.id.toString()
  );

  const handleEditAppointmentLine = (measured: boolean) => {
    const editAppointmentLineData: IEditFwAppointmentLineDTO = {
      quantity: Number(appointmentLine.quantity),
      product_sales_price: appointmentLine.product_sales_price,
      product_attributes: appointmentLine.product.attributes,
      measured: measured,
    };

    editAppointmentLine(editAppointmentLineData);
  };

  const renderProductAttributes = () => {
    return allAttributes.map(
      (attribute: IProductAttribute | ILogyxAttribute, index: number) => {
        return (
          <AttributesWrapper key={index}>
            {logyxAttributes.length && index === 0 ? (
              <>
                <GreenLine />
                <LogyxAttributesLabel>
                  {t('Logyx Attributes')}
                </LogyxAttributesLabel>
              </>
            ) : null}
            <TextColumnContainer>
              <LabelTitle>{attribute.fieldName}</LabelTitle>
              <Label>
                {attribute.type === QuestionType.BOOLEAN
                  ? t(getBooleanAttributeLabel(attribute.value))
                  : attribute.value}
              </Label>
            </TextColumnContainer>
            {logyxAttributes.length && index === logyxAttributes.length - 1 ? (
              <GreenLine />
            ) : null}
          </AttributesWrapper>
        );
      }
    );
  };

  return (
    <Card withoutContainer={withoutContainer}>
      <TitleContainer>
        <TitleWrapper>
          <TitleLabel>{getProductCardTitle(appointmentLine, t)}</TitleLabel>
          <TypeLabel>{getProductCardType(appointmentLine, t)}</TypeLabel>
        </TitleWrapper>
        <ActionsRow lang={i18n.resolvedLanguage} $isExpanded={isExpanded}>
          {onEditClick && canEditAppointmentLine(appointment, appointmentLine) && (
            <EditDeleteWrapperRelative onClick={() => onEditClick()}>
              <EditLabel>{t('Edit')}</EditLabel>
              <EditIcon>
                <Icon
                  svg={PencilSimple}
                  color={COLORS.PRIMARY}
                  size={15}
                  weight="regular"
                />
              </EditIcon>
            </EditDeleteWrapperRelative>
          )}
          {onDeleteClick &&
            canDeleteAppointmentLine(appointment, appointmentLine) && (
              <EditDeleteWrapperRelative onClick={() => onDeleteClick()}>
                <DeleteLabel>{t('Delete')}</DeleteLabel>
                <DeleteIcon>
                  <Icon svg={Trash} size={18} color={COLORS.RED_200} />
                </DeleteIcon>
              </EditDeleteWrapperRelative>
            )}
          <WrapperRelative>
            <Icon
              svg={isExpanded ? CaretUp : CaretDown}
              color={COLORS.PRIMARY}
              size={25}
              weight="regular"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </WrapperRelative>
        </ActionsRow>
      </TitleContainer>
      {showMeasurementsToggle && !appointmentLine.stock_item_line && (
        <SwitchContainer>
          {isLoading && <Spinner size={15} />}
          <SwitchWrapper>
            <Switch
              disabled={isLoading}
              size={'small'}
              checked={appointmentLine.measured}
              onChange={() => {
                handleEditAppointmentLine(!appointmentLine.measured);
              }}
            />
            <SwitchLabel>
              {appointmentLine?.measured ? t('Measured') : t('To measure')}
            </SwitchLabel>
          </SwitchWrapper>
        </SwitchContainer>
      )}
      {appointmentLine.description && (
        <DescriptionContainer>
          <TextContainer>
            <Label>{appointmentLine.description}</Label>
          </TextContainer>
        </DescriptionContainer>
      )}

      <MainContainer $isExpanded={isExpanded}>
        <ColumnContainer $isExpanded={isExpanded}>
          <H4>{t('Internal note').toUpperCase()}</H4>
          <TextContainer>
            <Label>{appointmentLine.placement}</Label>
          </TextContainer>
        </ColumnContainer>
        {isExpanded && (
          <ColumnContainer $isExpanded={isExpanded}>
            <H4>{t('General').toUpperCase()}</H4>
            {appointmentLine?.stock_item?.type && (
              <TextColumnContainer>
                <LabelTitle>{t('Item type')}</LabelTitle>
                <Label>
                  {appointmentLine?.stock_item?.type &&
                    appointmentLine?.stock_item?.type?.name.charAt(0) +
                      appointmentLine?.stock_item?.type?.name
                        .slice(1)
                        .toLowerCase()}
                </Label>
              </TextColumnContainer>
            )}
            <TextColumnContainer>
              <LabelTitle>{t('Supplier')}</LabelTitle>
              <Label>{appointmentLine.supplier?.company_name}</Label>
            </TextColumnContainer>
            <TextColumnContainer>
              <LabelTitle>{t('Quantity')}</LabelTitle>
              <Label>{appointmentLine.quantity}</Label>
            </TextColumnContainer>
          </ColumnContainer>
        )}
        {isExpanded && (
          <ColumnContainer $isExpanded={isExpanded}>
            <H4>{t('Attributes').toUpperCase()}</H4>
            {renderProductAttributes()}
          </ColumnContainer>
        )}
      </MainContainer>
    </Card>
  );
};
