import {
  Container,
  FlexAlignCenter,
  Row,
  TitleContainer,
  RowRightPart,
  FiltersWrapper,
} from './SalesOrdersPage.styled';
import { H3 } from 'assets/styled';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table/Table';
import { formatSalesOrdersTableData } from 'components/Table/tableDataFormatter';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import { tableConfig, SALES_ORDERS_PER_PAGE } from './constants';
import {
  useGetSalesOrdersPageFilters,
  useGetSalesOrders,
  useManageAndSaveFilters,
  useHandleAnalyticsNavigationState,
} from './hooks';
import {
  ISalesOrdersResponseDTO,
  ISalesOrderTableDTO,
} from 'types/SalesOrders.types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { ONGOING_STATUS } from '../SingleSalesOrder/constants';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { useGetSalesOrderAdvancedFilters } from './advancedFilters';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useState } from 'react';
import Button from 'components/Button/Button';
import { IFilterGroup } from 'components/Modal/AdvancedFilterModal/types';
import { RoutesConfig } from 'navigation/routes';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import LabelsFilter from 'components/LabelsFilter/LabelsFilter';
import { formatInitialAdvancedFilters } from 'components/Modal/AdvancedFilterModal/helpers';

const SalesOrdersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const canCreateSalesOrder = useCan(Actions.CREATE_SALES_ORDER);
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);

  const {
    salesOrdersPageFilters,
    statusOptions,
    subStatusOptions,
    paymentStatusOptions,
  } = useGetSalesOrdersPageFilters();

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedSubStatusOptions,
    setSelectedSubStatusOptions,
    selectedPaymentStatusOptions,
    setSelectedPaymentStatusOptions,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
    selectedLabelIds,
    setSelectedLabelIds,
  } = useManageAndSaveFilters(salesOrdersPageFilters);

  const debouncedSearchBy = useDebounce(searchBy);

  const formatStatusOptions = (options: Option[]) => {
    return options?.map((option: Option) => option.value).join(',');
  };

  const { isLoading, data } = useGetSalesOrders(
    page,
    SALES_ORDERS_PER_PAGE,
    formatStatusOptions(selectedStatusOptions),
    formatStatusOptions(selectedPaymentStatusOptions),
    formatStatusOptions(selectedSubStatusOptions),
    debouncedSearchBy,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds
  );
  let tableData: ISalesOrderTableDTO[] = [];
  if (data) {
    tableData = formatSalesOrdersTableData(
      data.sales_orders
        .map((salesOrder: ISalesOrdersResponseDTO[]) => {
          return salesOrder;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: any) => {
    navigate(`${rowData.navigationID}`);
  };

  const { steps } = useRunTour(GuidePages.SALES_ORDERS);

  const { filterTypes } = useGetSalesOrderAdvancedFilters(
    statusOptions,
    subStatusOptions,
    paymentStatusOptions
  );

  const initialFilterGroups: IFilterGroup[] | undefined =
    useHandleAnalyticsNavigationState(
      setAdvancedFilters,
      setSelectedStatusOptions,
      setSelectedSubStatusOptions,
      setSelectedPaymentStatusOptions,
      statusOptions,
      subStatusOptions
    );

  return (
    <Container className="sales-orders-step-1">
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <Button
          className="quotations-step-3"
          onClick={() => navigate(RoutesConfig.NewSalesOrder.fullPath)}
          label={t('Create sales order')}
          width="200rem"
          height="41rem"
          primary
          disabled={!canCreateSalesOrder}
        />
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <RowRightPart>
          <CheckableSelect
            key={JSON.stringify(
              selectedStatusOptions.length
                ? selectedStatusOptions.map((option: Option) => option.value)
                : 'status=select'
            )}
            placeholder={t('Status')}
            isMulti
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => {
              if (!e.find((option) => option.value === ONGOING_STATUS))
                setSelectedSubStatusOptions([]);
              setSelectedStatusOptions(e);
            }}
            defaultValues={selectedStatusOptions}
          />
          <CheckableSelect
            key={JSON.stringify(
              selectedSubStatusOptions.length
                ? selectedSubStatusOptions.map((option: Option) => option.value)
                : 'substatus-select'
            )}
            placeholder={t('Substatus')}
            isMulti
            options={subStatusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedSubStatusOptions(e)}
            disabled={
              !selectedStatusOptions?.find(
                (option) => option.value === ONGOING_STATUS
              )
            }
            defaultValues={selectedSubStatusOptions}
          />
          <CheckableSelect
            key={JSON.stringify(
              selectedPaymentStatusOptions.length
                ? selectedPaymentStatusOptions.map(
                    (option: Option) => option.value
                  )
                : 'payment-select'
            )}
            placeholder={t('Payment Status')}
            isMulti
            options={paymentStatusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedPaymentStatusOptions(e)}
            defaultValues={selectedPaymentStatusOptions}
          />
        </RowRightPart>
      </Row>
      <FiltersWrapper>
        <LabelsFilter
          selectedLabelIds={selectedLabelIds}
          setSelectedLabelIds={setSelectedLabelIds}
        />
        <Button
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </FiltersWrapper>
      <Table
        isLoading={isLoading}
        page={page}
        perPage={SALES_ORDERS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        labelsRow
      />
      <IntroductionGuide steps={steps} />
      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
        initialFilterGroups={
          initialFilterGroups
            ? initialFilterGroups
            : advancedFilters
            ? formatInitialAdvancedFilters(advancedFilters)
            : undefined
        }
      />
    </Container>
  );
};

export default SalesOrdersPage;
