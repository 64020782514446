import { COLORS, H3, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  hasSelect?: boolean;
  width?: string;
}

export const Container = styled.div`
  padding: 28rem;
  display: flex;
  flex-direction: column;
  min-width: 463rem;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  position: relative;
  height: 100%;
  justify-content: space-between;
  :last-child {
    margin-bottom: 0;
  }
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${respondTo.midTablet`
    width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10rem;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20rem;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20rem;
  padding-right: 20rem;
`;

export const Number = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
`;

export const Count = styled.div`
  font-size: 40rem;
  font-weight: 500;
  line-height: 16rem;
  letter-spacing: 0.75rem;
  text-align: center;
  color: ${COLORS.PRIMARY};
  margin-top: ${marginSm};
  height: 30rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 200rem;
  height: 41rem;
`;

export const Headline = styled(H3)`
  line-height: 1.2;
  ${(props: StyledProps) =>
    props.hasSelect &&
    css`
      width: 175rem;
    `};
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;
