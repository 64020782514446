export const RoutesConfig = {
  // Auth
  Auth: {
    path: 'auth',
    fullPath: '/auth',
  },
  Login: {
    path: '/auth/login',
    fullPath: '/auth/login',
  },
  SignUp: {
    path: '/sign-up',
    fullPath: '/sign-up',
  },
  SignUpActivation: {
    path: '/sign-up/activate',
    fullPath: '/sign-up/activate',
  },
  RequestPasswordReset: {
    path: '/auth/request-password-reset',
    fullPath: '/auth/request-password-reset',
  },
  ResetPassword: {
    path: '/auth/reset-password',
    fullPath: '/auth/reset-password',
  },
  // Sign Quotation
  SignQuotation: {
    path: 'sign-quotation',
    fullPath: '/sign-quotation',
  },
  // Appointment Confirmation
  AppointmentConfirmation: {
    path: 'confirm-appointment',
    fullPath: '/confirm-appointment',
  },
  // Home
  Home: {
    path: '/',
    fullPath: '/',
  },
  // Quotations
  Quotations: {
    path: 'quotations',
    fullPath: '/quotations',
  },
  NewQuotation: {
    path: 'new',
    fullPath: '/quotations/new',
  },
  SingleQuotation: {
    path: ':id/*',
    fullPath: '/quotations/:id/*',
  },
  NewQuotationLine: {
    path: 'add-product',
    fullPath: '/quotations/:id/add-product',
  },
  // Sales orders
  SalesOrders: {
    path: 'sales',
    fullPath: '/sales',
  },
  NewSalesOrder: {
    path: 'new',
    fullPath: '/sales/new',
  },
  SingleSalesOrder: {
    path: ':id/*',
    fullPath: '/sales/:id/*',
  },
  NewSalesOrderLine: {
    path: 'add-product',
    fullPath: '/sales/:id/add-product',
  },
  // Purchase orders
  PurchaseOrders: {
    path: 'purchases',
    fullPath: '/purchases',
  },
  NewPurchaseOrder: {
    path: 'new',
    fullPath: '/purchases/new',
  },
  SinglePurchaseOrder: {
    path: ':id/*',
    fullPath: '/purchases/:id/*',
  },
  // Invoices
  Invoices: {
    path: 'invoices',
    fullPath: '/invoices',
  },
  SingleSalesInvoice: {
    path: ':id/*',
    fullPath: '/sale-invoices/:id/*',
  },
  SingleSalesCreditInvoice: {
    path: ':id/*',
    fullPath: '/sale-credit-invoices/:id/*',
  },
  SinglePurchaseInvoice: {
    path: ':id/*',
    fullPath: '/purchase-invoices/:id/*',
  },
  SinglePurchaseCreditInvoice: {
    path: ':id/*',
    fullPath: '/purchase-credit-invoices/:id/*',
  },
  PurchaseCreditInvoice: {
    path: 'purchase-credit-invoices',
    fullPath: '/purchase-credit-invoices',
  },
  // Planning
  Planning: {
    path: 'planning',
    fullPath: '/planning',
  },
  //Customers
  Customers: {
    path: 'customers',
    fullPath: '/customers',
  },
  NewCustomer: {
    path: 'new',
    fullPath: '/customers/new',
  },
  NewAltContact: {
    path: 'new-alternative-contact',
    fullPath: '/customers/new-alternative-contact',
  },
  SingleCustomer: {
    path: ':id/*',
    fullPath: '/customers/:id/*',
  },
  //Suppliers
  Suppliers: {
    path: 'suppliers',
    fullPath: '/suppliers',
  },
  NewSupplier: {
    path: 'new',
    fullPath: '/suppliers/new',
  },
  SingleSupplier: {
    path: ':id/*',
    fullPath: '/suppliers/:id/*',
  },
  // Stock items
  StockItems: {
    path: 'stock',
    fullPath: '/stock',
  },
  SingleStockItem: {
    path: ':id',
    fullPath: '/stock/:id',
  },
  NewStockItem: {
    path: 'new',
    fullPath: '/stock/new',
  },
  // Profile
  Profile: {
    path: 'profile',
    fullPath: '/profile',
  },
  // Notifications
  Notifications: {
    path: 'notifications',
    fullPath: '/notifications',
  },
  // Settings
  Settings: {
    path: 'settings',
    fullPath: '/settings',
  },
  //Email templates
  EmailTemplates: {
    path: 'email-templates',
    fullPath: '/settings/email-templates',
  },
  // User Roles
  UserRoles: {
    path: 'user-roles',
    fullPath: '/settings/user-roles',
  },
  // Labels
  Labels: {
    path: 'labels',
    fullPath: '/settings/labels',
  },
  // Product groups
  ProductGroups: {
    path: 'product-groups',
    fullPath: '/settings/product-groups',
  },
  //Payment conditions
  PaymentConditions: {
    path: 'payment-conditions',
    fullPath: '/payment-conditions',
  },
  // Watermark
  Watermark: {
    path: 'watermark',
    fullPath: '/settings/watermark',
  },
  // Company Configuration
  CompanyConfiguration: {
    path: 'company-configuration',
    fullPath: '/settings/company-configuration',
  },
  //External Integration
  ExternalIntegrations: {
    path: 'external-integrations',
    fullPath: '/settings/external-integrations',
  },
  //Payment
  PaymentSuccessful: {
    path: 'callback',
    fullPath: '/callback',
  },
  // Mollie Mandate Created
  MandateCreated: {
    path: 'mandate-created',
    fullPath: '/mandate-created',
  },
  //Analytics
  Analytics: {
    path: 'analytics',
    fullPath: '/analytics',
  },
  // ToDo
  ToDo: {
    path: 'todo',
    fullPath: '/todo',
  },
  // Field worker
  FwHome: {
    path: 'fw',
    fullPath: '/fw',
  },
  FwAppointments: {
    path: 'appointments',
    fullPath: '/fw/appointments',
  },
  FwSingleAppointment: {
    path: ':id/*',
    fullPath: '/fw/appointments/:id/*',
  },
  FwReportPage: {
    path: 'report',
    fullPath: '/fw/report',
  },
  FwProfile: {
    path: 'profile',
    fullPath: '/fw/profile',
  },
  FwNotifications: {
    path: 'notifications',
    fullPath: '/fw/notifications',
  },
};
