import {
  TitleContainer,
  Container,
  NoContentLabel,
  MainContainer,
  Card,
  ColumnContainer,
  TextContainer,
  LabelTitle,
  Label,
} from './SingleQuotationSalesOrdersPage.styled';
import {
  useGetSingleQuotation,
  useGetSingleQuotationSalesOrder,
} from '../hooks';
import { useParams } from 'react-router-dom';
import { H3, H4 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import Tooltip from 'components/Tooltip/Tooltip';

const SingleQuotationSalesOrdersPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const { data, isFetched, isError, isFetching } =
    useGetSingleQuotationSalesOrder(typeof id === 'string' ? id : '');

  const { data: quotationData } = useGetSingleQuotation(
    typeof id === 'string' ? id : ''
  );

  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_SALES_ORDERS,
    id,
    isFetched
  );

  return (
    <Container className="single-quotation-step-9">
      {!isError && !isFetching ? (
        <Card>
          <TitleContainer>
            <H3>{data?.number}</H3>
            <Button
              onClick={() => navigate(`/sales/${data?.id}`)}
              label={t('View sales order')}
              primary
            ></Button>
          </TitleContainer>
          <MainContainer>
            <ColumnContainer>
              <H4>{t('Details')}</H4>
              <TextContainer>
                <LabelTitle>{t('Date')}</LabelTitle>
                <Label>{`${moment(data?.valid_to)
                  .tz(moment.tz.guess())
                  .format('DD-MM-YYYY')}`}</Label>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Reference')}</LabelTitle>
                <Tooltip
                  content={
                    quotationData?.reference?.length > 15
                      ? quotationData?.reference
                      : undefined
                  }
                  placement="left"
                >
                  <Label>
                    {quotationData?.reference?.length > 15
                      ? `${quotationData?.reference?.substring(0, 15)}...`
                      : quotationData?.reference}
                  </Label>
                </Tooltip>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Customer')}</LabelTitle>
                <Label>{data?.customer.name}</Label>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Amount')}</LabelTitle>
                <Label>
                  {currencySymbol}
                  {formatNumber(data?.total_amount)}
                </Label>
              </TextContainer>
            </ColumnContainer>
            <ColumnContainer>
              <H4>{t('Status')}</H4>
              <TextContainer>
                <LabelTitle>{t('Payment')}</LabelTitle>
                <Label>{t(data?.payment_status?.name)}</Label>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Status')}</LabelTitle>
                <Label>{data?.status?.name}</Label>
              </TextContainer>
            </ColumnContainer>
          </MainContainer>
        </Card>
      ) : (
        <NoContentLabel>
          {t('There are currently no sales orders on this Quotation')}
        </NoContentLabel>
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleQuotationSalesOrdersPage;
