import { RoutesConfig } from 'navigation/routes';
import { useGetSingleSalesInvoice } from 'pages/Manager/SingleInvoice/SingleSalesInvoice/hooks';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';

// Navigating to sales invoices depends if the invoice is a credit invoice or not
// We do not have this info from the todo
// So, before navigating, we check whether the invoice is credit or not, and after navigation the invoice data will already be in the cache
export const useHandleTodoNavigateSalesInvoice = (
  navigate: NavigateFunction
) => {
  const [invoiceId, setInvoiceId] = useState<string>('');
  const handleTodoNavigateSalesInvoice = (relatedToInvoiceId: string) => {
    setInvoiceId(relatedToInvoiceId);
  };

  const { data: salesInvoiceData, isFetching: isFetchingSalesInvoice } =
    useGetSingleSalesInvoice(invoiceId);

  useEffect(() => {
    if (salesInvoiceData) {
      if (salesInvoiceData.sales_invoice.is_credit) {
        navigate(
          RoutesConfig.SingleSalesCreditInvoice.fullPath.replace(
            ':id/*',
            invoiceId
          )
        );
      } else {
        navigate(
          RoutesConfig.SingleSalesInvoice.fullPath.replace(':id/*', invoiceId)
        );
      }
      setInvoiceId('');
    }
  }, [salesInvoiceData]);

  return { handleTodoNavigateSalesInvoice, isFetchingSalesInvoice };
};
