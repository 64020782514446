import * as Yup from 'yup';

export const addNewProductGroupValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(1, 'Name must be at least 1 character')
    .max(50, 'Name must not exceed 50 characters'),
  description: Yup.string().max(
    250,
    'Description must not exceed 250 characters'
  ),
});
