export enum ReactMutationKeys {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  FETCH_USER_INFO = 'FETCH_USER_INFO',
  CREATE_SALES_INVOICE = 'CREATE_SALES_INVOICE',
  CREATE_PURCHASE_INVOICE = 'CREATE_PURCHASE_INVOICE',
  CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',
  CREATE_PURCHASE_ORDER_BATCH = 'CREATE_PURCHASE_ORDER_BATCH',
  CREATE_QUOTATION = 'CREATE_QUOTATION',
  CREATE_STOCK_ITEM = 'CREATE_STOCK_ITEM',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  ADD_PRODUCT = 'ADD_PRODUCT',
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  DELETE_RESOURCE = 'DELETE_RESOURCE',
  ADD_RESOURCE = 'ADD_RESOURCE',
  EDIT_RESOURCE = 'EDIT_RESOURCE',
  DELETE_WORKER = 'DELETE_WORKER',
  EDIT_WORKER = 'EDIT_WORKER',
  ADD_WORKER = 'ADD_WORKER',
  EXPORT_PURCHASE_INVOICE = 'EXPORT_PURCHASE_INVOICE',
  UPLOAD_PURCHASE_INVOICE_FILE = 'UPLOAD_PURCHASE_INVOICE_FILE',
  DELETE_PURCHASE_INVOICE_FILE = 'DELETE_PURCHASE_INVOICE_FILE',
  EXPORT_SALES_INVOICE = 'EXPORT_SALES_INVOICE',
  DELETE_SALES_INVOICE_FILE = 'DELETE_SALES_INVOICE_FILE',
  UPLOAD_SALES_INVOICE_FILE = 'UPLOAD_SALES_INVOICE_FILE',
  CHANGE_PURCHASE_ORDER_STATUS = 'CHANGE_PURCHASE_ORDER_STATUS',
  ADD_PURCHASE_ORDER_COMMENT = 'ADD_PURCHASE_ORDER_COMMENT',
  DELETE_PURCHASE_ORDER_FILE = 'DELETE_PURCHASE_ORDER_FILE',
  UPLOAD_PURCHASE_ORDER_FILE = 'UPLOAD_PURCHASE_ORDER_FILE',
  CHANGE_QUOTATION_STATUS = 'CHANGE_QUOTATION_STATUS',
  ADD_QUOTATION_COMMENT = 'ADD_QUOTATION_COMMENT',
  DELETE_QUOTATION_FILE = 'DELETE_QUOTATION_FILE',
  UPLOAD_QUOTATION_FILE = 'UPLOAD_QUOTATION_FILE',
  UPLOAD_QUOTATION_SIGNATURE = 'UPLOAD_QUOTATION_SIGNATURE',
  UPLOAD_SALES_ORDER_SIGNATURE = 'UPLOAD_SALES_ORDER_SIGNATURE',
  UPDATE_SALES_ORDER_SIGNATURE = 'UPDATE_SALES_ORDER_SIGNATURE',
  CREATE_SALES_ORDER = 'CREATE_SALES_ORDER',
  ADD_SALES_ORDER_COMMENT = 'ADD_SALES_ORDER_COMMENT',
  CHANGE_SALES_ORDER_STATUS = 'CHANGE_SALES_ORDER_STATUS',
  DELETE_SALES_ORDER_FILE = 'DELETE_SALES_ORDER_FILE',
  UPLOAD_SALES_ORDER_FILE = 'UPLOAD_SALES_ORDER_FILE',
  DELETE_STOCK_ITEM = 'DELETE_STOCK_ITEM',
  EXPORT_PURCHASE_ORDER = 'EXPORT_PURCHASE_ORDER',
  EXPORT_SALES_ORDER = 'EXPORT_SALES_ORDER',
  EXPORT_QUOTATION = 'EXPORT_QUOTATION',
  UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE',
  EDIT_USER_DATA = 'EDIT_USER_DATA',
  CHANGE_SALES_INVOICE_STATUS = 'CHANGE_SALES_INVOICE_STATUS',
  CHANGE_SALES_INVOICE_REFERENCE = 'CHANGE_SALES_INVOICE_REFERENCE',
  CHANGE_CREDIT_SALES_INVOICE_REFERENCE = 'CHANGE_CREDIT_SALES_INVOICE_REFERENCE',
  CHANGE_CREDIT_PURCHASE_INVOICE_REFERENCE = 'CHANGE_CREDIT_PURCHASE_INVOICE_REFERENCE',
  CHANGE_PURCHASE_INVOICE_STATUS = 'CHANGE_PURCHASE_INVOICE_STATUS',
  CHANGE_PURCHASE_INVOICE_REFERENCE = 'CHANGE_PURCHASE_INVOICE_REFERENCE',
  ADD_SALES_INVOICE_PAYMENT = 'ADD_SALES_INVOICE_PAYMENT',
  ADD_PURCHASE_INVOICE_PAYMENT = 'ADD_PURCHASE_INVOICE_PAYMENT',
  CREATE_SUPPLIER = 'CREATE_SUPPLIER',
  EDIT_SUPPLIER = 'EDIT_SUPPLIER',
  EXPORT_APPOINTMENT = 'EXPORT_APPOINTMENT',
  CREATE_CREDIT_INVOICE = 'CREATE_CREDIT_INVOICE',
  SEND_QUOTATION_EMAIL = 'SEND_QUOTATION_EMAIL',
  EDIT_STOCK_ITEM_AMOUNT = 'EDIT_STOCK_ITEM_AMOUNT',
  EDIT_QUOTATION_FILE_NAME = 'EDIT_QUOTATION_FILE_NAME',
  EDIT_SALES_ORDER_FILE_NAME = 'EDIT_SALES_ORDER_FILE_NAME',
  EDIT_PURCHASE_ORDER_FILE_NAME = 'EDIT_PURCHASE_ORDER_FILE_NAME',
  EDIT_SALES_INVOICE_FILE_NAME = 'EDIT_SALES_INVOICE_FILE_NAME',
  EDIT_PURCHASE_INVOICE_FILE_NAME = 'EDIT_PURCHASE_INVOICE_FILE_NAME',
  EDIT_SALES_CREDIT_INVOICE_FILE_NAME = 'EDIT_SALES_CREDIT_INVOICE_FILE_NAME',
  EDIT_STOCK_ITEM = 'EDIT_STOCK_ITEM',
  SEND_APPOINTMENT_EMAIL = 'SEND_APPOINTMENT_EMAIL',
  CREATE_QUOTATION_ACTION = 'CREATE_QUOTATION_ACTION',
  CONFIRM_APPOINTMENT = 'CONFIRM_APPOINTMENT',
  FAILED_APPOINTMENT = 'FAILED_APPOINTMENT',
  EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
  SEND_SALES_ORDER_EMAIL = 'SEND_SALES_ORDER_EMAIL',
  SEND_SALES_INVOICE_EMAIL = 'SEND_SALES_INVOICE_EMAIL',
  SEND_PURCHASE_ORDER_EMAIL = 'SEND_PURCHASE_ORDER_EMAIL',
  EDIT_QUOTATION_LINE = 'EDIT_QUOTATION_LINE',
  DELETE_PAYMENT = 'DELETE_PAYMENT',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  GET_CUSTOMER_BY_EMAIL = 'GET_CUSTOMER_BY_EMAIL',
  GENERATE_LOGYX_INTENT = 'GENERATE_LOGYX_INTENT',
  EDIT_PURCHASE_ORDER_LINE_PRICE = 'EDIT_PURCHASE_ORDER_LINE_PRICE',
  ADD_APPOINTMENT_COMMENT = 'ADD_APPOINTMENT_COMMENT',
  LOGOUT_GOOGLE_ACCOUNT = 'LOGOUT_GOOGLE_ACCOUNT',
  LOGOUT_MICROSOFT_ACCOUNT = 'LOGOUT_MICROSOFT_ACCOUNT',
  GET_CITY_AND_ADDRESS = 'GET_CITY_AND_ADDRESS',
  ACTIVATE_STOCK_ITEM = 'ACTIVATE_STOCK_ITEM',
  EDIT_STOCK_ITEM_ATTRIBUTES = 'EDIT_STOCK_ITEM_ATTRIBUTES',
  EDIT_SALES_ORDER_CUSTOMER_DETAILS = 'EDIT_SALES_ORDER_CUSTOMER_DETAILS',
  EDIT_QUOTATION_CUSTOMER_DETAILS = 'EDIT_QUOTATION_CUSTOMER_DETAILS',
  ADD_SALES_ORDER_LINE = 'ADD_SALES_ORDER_LINE',
  DELETE_SALES_ORDER_LINE = 'DELETE_SALES_ORDER_LINE',
  EDIT_SALES_ORDER_SERVICE_LINE = 'EDIT_SALES_ORDER_SERVICE_LINE',
  EDIT_SALES_ORDER_LINE = 'EDIT_SALES_ORDER_LINE',
  EDIT_SALES_ORDER_DETAILS = 'EDIT_SALES_ORDER_DETAILS',
  EDIT_QUOTATION_ORDER_DETAILS = 'EDIT_QUOTATION_ORDER_DETAILS',
  EDIT_SALES_ORDER_WORKING_HOURS_LINE = 'EDIT_SALES_ORDER_WORKING_HOURS_LINE',
  EDIT_SALES_ORDER_PRODUCTS_DEFINITIVE = 'EDIT_SALES_ORDER_PRODUCTS_DEFINITIVE',
  CREATE_APPOINTMENT_INTERVAL = 'CREATE_APPOINTMENT_INTERVAL',
  STOP_APPOINTMENT_INTERVAL = 'STOP_APPOINTMENT_INTERVAL',
  SUCCESSFUL_APPOINTMENT = 'SUCCESSFUL_APPOINTMENT',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  ACTIVATE_CUSTOMER = 'ACTIVATE_CUSTOMER',
  DELETE_SUPPLIER = 'DELETE_SUPPLIER',
  ACTIVATE_SUPPLIER = 'ACTIVATE_SUPPLIER',
  GENERATE_DEALER_PANEL_INTENT = 'GENERATE_DEALER_PANEL_INTENT',
  UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE = 'UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE',
  DELETE_DEFAULT_ATTACHMENT_MAIL_FILE = 'DELETE_DEFAULT_ATTACHMENT_MAIL_FILE',
  EDIT_DOWN_PAYMENT_AMOUNT = 'EDIT_DOWN_PAYMENT_AMOUNT',
  CONFIRM_PURCHASE_ORDER = 'CONFIRM_PURCHASE_ORDER',
  UPLOAD_FW_APPOINTMENT_FILE = 'UPLOAD_FW_APPOINTMENT_FILE',
  ADD_CUSTOMER_COMMENT = 'ADD_CUSTOMER_COMMENT',
  ADD_SUPPLIER_COMMENT = 'ADD_SUPPLIER_COMMENT',
  GET_TWINFIELD_CUSTOMER_DETAILS = 'GET_TWINFIELD_CUSTOMER_DETAILS',
  CONNECT_CUSTOMER_TO_TWINFIELD_CUSTOMER = 'CONNECT_CUSTOMER_TO_TWINFIELD_CUSTOMER',
  START_FW_APPOINTMENT_INTERVAL = 'START_FW_APPOINTMENT_INTERVAL',
  PAUSE_FW_APPOINTMENT_INTERVAL = 'PAUSE_FW_APPOINTMENT_INTERVAL',
  STOP_FW_APPOINTMENT_INTERVAL = 'STOP_FW_APPOINTMENT_INTERVAL',
  FINISH_FW_APPOINTMENT = 'FINISH_FW_APPOINTMENT',
  RESUME_FW_APPOINTMENT_INTERVAL = 'RESUME_FW_APPOINTMENT_INTERVAL',
  ADD_FW_APPOINTMENT_LINE = 'ADD_FW_APPOINTMENT_LINE',
  EDIT_FW_APPOINTMENT_LINE = 'EDIT_FW_APPOINTMENT_LINE',
  DELETE_FW_APPOINTMENT_LINE = 'DELETE_FW_APPOINTMENT_LINE',
  ADD_FW_APPOINTMENT_COMMENT = 'ADD_FW_APPOINTMENT_COMMENT',
  SUBMIT_REPORT = 'SUBMIT_REPORT',
  UPDATE_IS_APPOINTMENT_PROCESSED_IN_OFFICE = 'UPDATE_IS_APPOINTMENT_PROCESSED_IN_OFFICE',
  EDIT_QUOTATION_PRICES_DEFINITIVE = 'EDIT_QUOTATION_PRICES_DEFINITIVE',
  EDIT_FW_APPOINTMENT_FILE_NAME = 'EDIT_FW_APPOINTMENT_FILE_NAME',
  DELETE_FW_APPOINTMENT_FILE = 'DELETE_FW_APPOINTMENT_FILE',
  EXPORT_PURCHASE_ORDER_EXCEL = 'EXPORT_PURCHASE_ORDER_EXCEL',
  EXPORT_SALES_ORDER_EXCEL = 'EXPORT_SALES_ORDER_EXCEL',
  DELETE_QUOTATION_LINE = 'DELETE_QUOTATION_LINE',
  ADD_QUOTATION_LINE = 'ADD_QUOTATION_LINE',
  EDIT_QUOTATION_WORKING_HOURS_LINE = 'EDIT_QUOTATION_WORKING_HOURS_LINE',
  DELETE_CUSTOMER_COMMENT = 'DELETE_CUSTOMER_COMMENT',
  DELETE_SUPPLIER_COMMENT = 'DELETE_SUPPLIER_COMMENT',
  GET_COMPANY = 'GET_COMPANY',
  GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS',
  SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
  GET_PENDING_APPOINTMENTS = 'GET_PENDING_APPOINTMENTS',
  GET_USERS_BY_ID = 'GET_USERS_BY_ID',
  GET_WORKERS_BY_ID = 'GET_WORKERS_BY_ID',
  GET_RESOURCES_BY_ID = 'GET_RESOURCES_BY_ID',
  UPLOAD_CUSTOMER_SIGNATURE = 'UPLOAD_CUSTOMER_SIGNATURE',
  CUSTOMER_EXPORT = 'CUSTOMER_EXPORT',
  CHANGE_QUOTATION_TYPE = 'CHANGE_QUOTATION_TYPE',
  CUSTOMER_CONFIRM_APPOINTMENT = 'CUSTOMER_CONFIRM_APPOINTMENT',
  UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS',
  CREATE_GOOGLE_CALENDAR_PREFERENCE = 'CREATE_GOOGLE_CALENDAR_PREFERENCE',
  CREATE_OUTLOOK_CALENDAR_PREFERENCE = 'CREATE_OUTLOOK_CALENDAR_PREFERENCE',
  CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE_GENERAL_THEME = 'CREATE_EMAIL_TEMPLATE_GENERAL_THEME',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  GET_INVOICE_CREATION_INFO = 'GET_INVOICE_CREATION_INFO',
  CONNECT_SALES_INVOICE_TO_THIRD_PARTY = 'CONNECT_SALES_INVOICE_TO_THIRD_PARTY',
  CREATE_TWINFIELD_CUSTOMER = 'CREATE_TWINFIELD_CUSTOMER',
  GET_LOGYX_ORDER = 'GET_LOGYX_ORDER',
  CREATE_PURCHASE_ORDER_CART = 'CREATE_PURCHASE_ORDER_CART',
  RECONFIGURE_LOGYX = 'RECONFIGURE_LOGYX',
  GET_AVAILABLE_SUPPLIER_ORDERS = 'GET_AVAILABLE_SUPPLIER_ORDERS',
  MOVE_TO_EXISTING_ORDER = 'MOVE_TO_EXISTING_ORDER',
  BULK_BOOK_PURCHASE_ORDER = 'BULK_BOOK_PURCHASE_ORDER',
  TEST_EMAIL_TEMPLATE = 'TEST_EMAIL_TEMPLATE',
  DEACTIVATE_EMAIL_TEMPLATE = 'DEACTIVATE_EMAIL_TEMPLATE',
  EDIT_PURCHASE_ORDER_LINE_STATUS = 'EDIT_PURCHASE_ORDER_LINE_STATUS',
  MOVE_PURCHASE_ORDER_LINE_TO_NEW_ORDER = 'MOVE_PURCHASE_ORDER_LINE_TO_NEW_ORDER',
  REORDER_QUOTATION_LINES = 'REORDER_QUOTATION_LINES',
  REORDER_SALES_ORDER_LINES = 'REORDER_SALES_ORDER_LINES',
  REORDER_PURCHASE_ORDER_LINES = 'REORDER_PURCHASE_ORDER_LINES',
  REORDER_FW_APPOINTMENT_LINES = 'REORDER_FW_APPOINTMENT_LINES',
  REORDER_SALES_INVOICE_LINES = 'REORDER_SALES_INVOICE_LINES',
  RESET_INTRO_GUIDE = 'RESET_INTRO_GUIDE',
  GET_COMPLETED_INTRO_GUIDE_PAGES = 'GET_COMPLETED_INTRO_GUIDE_PAGES',
  ADD_COMPLETED_INTRO_GUIDE_PAGE = 'ADD_COMPLETED_INTRO_GUIDE_PAGE',
  FETCH_COMMON_DATA = 'FETCH_COMMON_DATA',
  GET_COMPANY_LOGO = 'GET_COMPANY_LOGO',
  MARK_SALES_INVOICE_AS_CONNECTED = 'MARK_SALES_INVOICE_AS_CONNECTED',
  SET_PREFERRED_MANDATE = 'SET_PREFERRED_MANDATE',
  DELETE_MANDATE = 'DELETE_MANDATE',
  EDIT_MANDATE = 'EDIT_MANDATE',
  ADD_NEW_MOLLIE_CUSTOMER = 'ADD_NEW_MOLLIE_CUSTOMER',
  SIGN_UP_SEND_CODE = 'SIGN_UP_SEND_CODE',
  SIGN_UP_VERIFY_CODE = 'SIGN_UP_VERIFY_CODE',
  SIGN_UP_ADD_COMPANY_DETAILS = 'SIGN_UP_ADD_COMPANY_DETAILS',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_ACTIVATE_USER = 'SIGN_UP_ACTIVATE_USER',
  GET_INTENT_BY_UUID = 'GET_INTENT_BY_UUID',
  DELETE_SAVED_ADV_FILTERS = 'DELETE_SAVED_ADV_FILTERS',
  ADD_SAVED_ADV_FILTERS = 'ADD_SAVED_ADV_FILTERS',
  MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ',
  MARK_ALL_NOTIFICATION_AS_READ = 'MARK_ALL_NOTIFICATION_AS_READ',
  ADD_LABEL = 'ADD_LABEL',
  DELETE_LABEL = 'DELETE_LABEL',
  EDIT_LABEL = 'EDIT_LABEL',
  REORDER_LABELS = 'REORDER_LABELS',
  ADD_LABEL_TO_ENTITY = 'ADD_LABEL_TO_ENTITY',
  DELETE_LABEL_FROM_ENTITY = 'DELETE_LABEL_FROM_ENTITY',
  CREATE_TODO = 'CREATE_TODO',
  DELETE_TODO = 'DELETE_TODO',
  UPDATE_TODO = 'UPDATE_TODO',
  ADD_TODO_COMMENT = 'ADD_TODO_COMMENT',
  CREATE_MANDATE = 'CREATE_MANDATE',
  CANCEL_RECURRING_QUOTATION = 'CANCEL_RECURRING_QUOTATION',
  ADD_PAYMENT_CONDITION = 'ADD_PAYMENT_CONDITION',
  DELETE_PAYMENT_CONDITION = 'DELETE_PAYMENT_CONDITION',
  EDIT_PAYMENT_CONDITION = 'EDIT_PAYMENT_CONDITION',
  CREATE_CUSTOMER_ALTERNATIVE_CONTACT = 'CREATE_CUSTOMER_ALTERNATIVE_CONTACT',
  DELETE_CUSTOMER_ALTERNATIVE_CONTACT = 'DELETE_CUSTOMER_ALTERNATIVE_CONTACT',
  EDIT_CUSTOMER_ALTERNATIVE_CONTACT = 'EDIT_CUSTOMER_ALTERNATIVE_CONTACT',
  DELETE_WATERMARK_PICTURE = 'DELETE_WATERMARK_PICTURE',
  UPDATE_WATERMARK = 'UPDATE_WATERMARK',
  EDIT_COMPANY_SETTINGS = 'EDIT_COMPANY_SETTINGS',
  UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO',
  EDIT_COMPANY_ADMIN = 'EDIT_COMPANY_ADMIN',
  ADD_TWINFIELD_COMPANY_INFO = 'ADD_TWINFIELD_COMPANY_INFO',
  GET_DOWNPAYMENT_AND_CODES = 'GET_DOWNPAYMENT_AND_CODES',
  GET_GENERAL_DEBTORS = 'GET_GENERAL_DEBTORS',
  EDIT_TWINFIELD_COMPANY_INFO = 'EDIT_TWINFIELD_COMPANY_INFO',
  AUTH_TWINFIELD = 'AUTH_TWINFIELD',
  UPDATE_FRONTEND_SETTINGS = 'UPDATE_FRONTEND_SETTINGS',
  MAKE_DEFAULT_PAYMENT_CONDITION = 'MAKE_DEFAULT_PAYMENT_CONDITION',
  ADD_PRODUCT_GROUP = 'ADD_PRODUCT_GROUP',
  DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP',
  EDIT_PRODUCT_GROUP = 'EDIT_PRODUCT_GROUP',
}
