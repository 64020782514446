import { components } from 'react-select';
import { LoaderWrapper } from './CustomDropdownIndicator.styled';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretDown } from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const CustomDropdownIndicator = ({ isLoading, ...rest }: any) => {
  const { isTablet } = useBreakpointFlag();

  return (
    <components.DropdownIndicator {...rest}>
      {isLoading ? (
        <LoaderWrapper>
          <Spinner size={isTablet ? 15 : 20} />
        </LoaderWrapper>
      ) : (
        <Icon svg={CaretDown} color={COLORS.BLACK} weight="regular" size={20} />
      )}
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
