import {
  Container,
  Heading,
  BackgroundColorWrapper,
  LeftSide,
  RightSide,
  Subtitle,
  LabelValue,
  LabelKey,
  FormWrapper,
  ContentWrapper,
  EditLabel,
  EditIcon,
  ValueWrapperRelative,
  RowSpaceBetween,
  ShowMoreLabel,
  ShowMoreSubtitle,
  LoaderWrapper,
  EyeLabelWrapper,
  Column,
  LinkValue,
} from './SimpleWideCard.styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useLocation, useNavigate } from 'react-router';
import { excludedDataKeys } from './constants';
import { Eye as EyeIcon, PencilSimple } from '@phosphor-icons/react';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import Tooltip from 'components/Tooltip/Tooltip';
import useHasRole from 'utils/hooks/useHasRole';
import { Roles } from 'types/Permissions.types';

interface ISimpleWideCardProps {
  cardTitle: string;
  leftSubtitle: string | null;
  rightSubtitle: string;
  leftData: object;
  rightData: object | null;
  onEditClick?: () => void;
  showMoreData?: object;
  isLoading?: boolean;
  editable?: boolean;
}
const SimpleWideCard = ({
  cardTitle,
  leftSubtitle,
  rightSubtitle,
  leftData,
  rightData,
  onEditClick,
  showMoreData,
  isLoading = false,
  editable = true,
}: ISimpleWideCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isPurchasePriceHidden, setIsPurchasePriceHidden] =
    useState<boolean>(true);
  const location = useLocation();
  const isForQuotationOrSalesOrder =
    location.pathname.includes('/quotations/') ||
    location.pathname.includes('/sales/');

  const canViewPurchasePrice =
    useHasRole(Roles.READ_COMPANY_ADMIN) || useHasRole(Roles.READ_PURCHASE);

  return (
    <Container>
      <BackgroundColorWrapper>
        <RowSpaceBetween>
          <Heading>{cardTitle}</Heading>
          {onEditClick && editable && (
            <ValueWrapperRelative>
              <EditLabel onClick={onEditClick}>{t('Edit')}</EditLabel>
              <EditIcon>
                <Icon
                  data-testid={`${cardTitle}-edit`}
                  onClick={onEditClick}
                  svg={PencilSimple}
                  color={COLORS.PRIMARY}
                  size={15}
                />
              </EditIcon>
            </ValueWrapperRelative>
          )}
        </RowSpaceBetween>
        {isLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <ContentWrapper>
            <LeftSide>
              {leftSubtitle ? <Subtitle>{leftSubtitle}</Subtitle> : null}
              <FormWrapper>
                {Object.keys(leftData)
                  .filter((key) => !excludedDataKeys.includes(key))
                  .map((dataKey: string, index: number) => (
                    <Column key={index}>
                      <LabelKey>{t(dataKey)}</LabelKey>
                      {leftData[dataKey] && (
                        <div>
                          <Tooltip
                            content={
                              dataKey === 'Reference' &&
                              leftData[dataKey]?.length > 15
                                ? leftData[dataKey]
                                : undefined
                            }
                            placement="left"
                          >
                            <LabelValue
                              clickable={
                                (dataKey === 'Name' &&
                                  leftData['customerID']) ||
                                (dataKey === 'Company name' &&
                                  leftData['supplierID'])
                              }
                              onClick={() => {
                                if (
                                  leftData['customerID'] &&
                                  dataKey === 'Name'
                                ) {
                                  navigate(
                                    SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                                      ':id',
                                      leftData['customerID']
                                    )
                                  );
                                } else if (
                                  leftData['supplierID'] &&
                                  dataKey === 'Company name'
                                ) {
                                  navigate(
                                    SingleSupplierRoutes.SingleSupplierGeneral.fullPath.replace(
                                      ':id',
                                      leftData['supplierID']
                                    )
                                  );
                                }
                              }}
                            >
                              {dataKey === 'Reference' &&
                              leftData[dataKey]?.length > 15
                                ? `${leftData[dataKey].substring(0, 15)}...`
                                : leftData[dataKey]}
                            </LabelValue>
                          </Tooltip>
                        </div>
                      )}
                    </Column>
                  ))}
              </FormWrapper>
              {showMoreData && (
                <>
                  {showMore && (
                    <ShowMoreSubtitle>
                      {t('Ship-to address').toUpperCase()}
                    </ShowMoreSubtitle>
                  )}
                  <FormWrapper>
                    {showMore &&
                      Object.keys(showMoreData)?.map(
                        (dataKey: string, index: number) => (
                          <Column key={index}>
                            <LabelKey>{t(dataKey)}</LabelKey>
                            <LabelValue>{showMoreData[dataKey]}</LabelValue>
                          </Column>
                        )
                      )}
                    {
                      <ShowMoreLabel onClick={() => setShowMore(!showMore)}>
                        {showMore ? t('Show less') : t('Show more')}
                      </ShowMoreLabel>
                    }
                  </FormWrapper>
                </>
              )}
            </LeftSide>
            {rightData ? (
              <RightSide>
                <Subtitle>{rightSubtitle}</Subtitle>
                <FormWrapper>
                  {Object.keys(rightData)?.map(
                    (dataKey: string, index: number) => {
                      if (
                        dataKey === 'Expected Total purchase cost exc. VAT' ||
                        dataKey === 'Total purchase cost exc. VAT'
                      ) {
                        return (
                          <Column key={index}>
                            <LabelKey>{t(dataKey)}</LabelKey>
                            <EyeLabelWrapper>
                              <LabelValue
                                secure={
                                  isPurchasePriceHidden &&
                                  isForQuotationOrSalesOrder
                                }
                              >
                                {rightData[dataKey]}
                              </LabelValue>
                              <Tooltip
                                content={
                                  canViewPurchasePrice
                                    ? undefined
                                    : t(
                                        "You don't have enough permission to see this"
                                      )
                                }
                              >
                                {isForQuotationOrSalesOrder ? (
                                  <Icon
                                    svg={EyeIcon}
                                    color={
                                      isPurchasePriceHidden
                                        ? COLORS.GREY_1000
                                        : COLORS.PRIMARY
                                    }
                                    size={20}
                                    onClick={() => {
                                      if (!canViewPurchasePrice) return;
                                      setIsPurchasePriceHidden(
                                        !isPurchasePriceHidden
                                      );
                                    }}
                                  />
                                ) : null}
                              </Tooltip>
                            </EyeLabelWrapper>
                          </Column>
                        );
                      } else if (
                        dataKey === 'E-mail' ||
                        dataKey === 'Phone number'
                      ) {
                        return (
                          <Column key={index}>
                            <LabelKey>{t(dataKey)}</LabelKey>
                            <div>
                              <LinkValue
                                href={`${
                                  dataKey === 'E-mail' ? 'mailto' : 'tel'
                                }:${rightData[dataKey]}`}
                              >
                                {rightData[dataKey]}
                              </LinkValue>
                            </div>
                          </Column>
                        );
                      }
                      return (
                        <Column key={index}>
                          <LabelKey>{t(dataKey)}</LabelKey>
                          {rightData[dataKey] && (
                            <LabelValue>{rightData[dataKey]}</LabelValue>
                          )}
                        </Column>
                      );
                    }
                  )}
                </FormWrapper>
              </RightSide>
            ) : null}
          </ContentWrapper>
        )}
      </BackgroundColorWrapper>
    </Container>
  );
};
export default SimpleWideCard;
