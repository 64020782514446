import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Date', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Customer', flexValue: 2, isBlue: true },
  { header: 'City', flexValue: 2, isBlue: false },
  { header: 'Measuring date', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1, isBlue: false },
  { header: 'Amount', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const QUOTATIONS_PER_PAGE = 10;
export const MACBOOK_WIDTH = 1440;

export enum ACTIVE_FILTER {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}
