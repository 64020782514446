import { COLORS, marginSm } from 'assets/styled';
import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import Input from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddMoreFieldsLabel,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  DeleteProductFieldIcon,
  FieldNamesWrapper,
  InputWrapper,
  RowMarginBottom,
  SelectWrapper,
} from './AddProductFormDTOAttributeModal.styled';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { Row } from '../ProductList.styled';
import { useGetQuickFieldOptions } from './hooks';
import { Level } from '../../../../../../components/Modal/type';
import Icon from 'components/Icon/Icon';
import { Plus, Trash } from '@phosphor-icons/react';

export interface IAddProductFormDTOAttributeModalProps {
  onSubmit: any;
  isOpen: boolean;
  setIsOpen: any;
  level?: Level;
}

export function AddProductFormDTOAttributeModal({
  onSubmit,
  isOpen,
  setIsOpen,
  level,
}: IAddProductFormDTOAttributeModalProps) {
  const { t } = useTranslation();
  const [fieldNames, setFieldNames] = useState<string[]>(['']);

  const handleUpdateFieldName = (
    newFieldName: string,
    indexOfFieldNameToChange: number
  ) => {
    const newFieldNames = fieldNames?.map((fieldName, index) => {
      if (index === indexOfFieldNameToChange) {
        return newFieldName;
      } else {
        return fieldName;
      }
    });
    setFieldNames(newFieldNames);
  };

  const handleRemoveFieldName = (index: number, fieldName: string) => {
    const newFieldNames = fieldNames.filter(
      (fieldName, fieldNameIndex) => fieldNameIndex !== index
    );
    setFieldNames(newFieldNames);
  };

  const quickFieldOptions = useGetQuickFieldOptions();

  return (
    <Modal
      level={level}
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        setIsOpen(boolean);
        setFieldNames(['']);
      }}
      modalStyle={{ position: 'fixed', margin: 'auto', overflow: 'visible' }} // Center positioning
    >
      <Container>
        <AddMoreFieldsLabel>{t('Add fields')}</AddMoreFieldsLabel>
        <Row>
          <SelectWrapper>
            <Select
              name="quickFields"
              placeholder={t('Quick Fields')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setFieldNames([...fieldNames, t(e.key)])}
              options={quickFieldOptions}
            />
          </SelectWrapper>
        </Row>
        <FieldNamesWrapper>
          {fieldNames.map((fieldName, index) => (
            <InputWrapper key={index}>
              <Input
                placeholder={`${t('Field name')} ${index + 1}`}
                value={t(fieldName)}
                onChange={(e) => handleUpdateFieldName(e.target.value, index)}
                height={'41rem'}
                style={{ width: '300rem' }}
                wrapperStyles={{
                  width: '300rem',
                  marginBottom: marginSm,
                }}
              />
              <DeleteProductFieldIcon
                onClick={() => handleRemoveFieldName(index, fieldName)}
              >
                <Icon
                  svg={Trash}
                  color={COLORS.RED_200}
                  size={20}
                  weight="regular"
                />
              </DeleteProductFieldIcon>
            </InputWrapper>
          ))}
        </FieldNamesWrapper>
        <RowMarginBottom>
          <Button
            label={t('Add more')}
            icon={Plus}
            sizeIcon={17}
            colorIcon={COLORS.PRIMARY}
            weightIcon="regular"
            link
            onClick={() => {
              setFieldNames([...fieldNames, '']);
            }}
            fontSize="14rem"
          />
        </RowMarginBottom>

        <ButtonWrapper>
          <Button
            label={t('Cancel')}
            secondary
            width="200rem"
            onClick={() => setIsOpen(false)}
          />
          <Button
            label={t('Add')}
            disabled={
              !!fieldNames.filter(
                (fieldName) => fieldName === '' || fieldName === 'attributes'
              ).length || !fieldNames.length
            }
            primary
            width="200rem"
            onClick={() => {
              setIsOpen(false);
              onSubmit(fieldNames);
              setFieldNames(['']);
            }}
          />
        </ButtonWrapper>
        <CloseAbsoluteWrapper>
          <Close
            onClick={() => {
              setFieldNames(['']);
              setIsOpen(false);
            }}
          />
        </CloseAbsoluteWrapper>
      </Container>
    </Modal>
  );
}
