import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  createProductGroup,
  editProductGroup,
} from 'services/Settings/SettingsService';
import { IAddEditProductGroupData } from './types';

export const useAddProductGroup = () => {
  const { t } = useTranslation();
  return useMutation(
    (addProductGroupData: IAddEditProductGroupData) =>
      createProductGroup(addProductGroupData),
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        toast.success(t('Product group added'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_PRODUCT_GROUPS_INFINITE,
        ]);
      },
      mutationKey: ReactMutationKeys.ADD_PRODUCT_GROUP,
    }
  );
};

export const useEditProductGroup = () => {
  const { t } = useTranslation();
  return useMutation((dto: IAddEditProductGroupData) => editProductGroup(dto), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Product group edited'));
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_PRODUCT_GROUPS_INFINITE,
      ]);
    },
    mutationKey: ReactMutationKeys.EDIT_PRODUCT_GROUP,
  });
};
