import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1.5, isBlue: false },
  { header: 'Payment', flexValue: 1.5, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Sub Status', flexValue: 2, isBlue: false },
  { header: 'Date', flexValue: 2, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Customer', flexValue: 1.5, isBlue: true },
  { header: 'City', flexValue: 1.5, isBlue: false },
  { header: 'Amount', flexValue: 1, isBlue: false },
  { header: 'Unbilled', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const SALES_ORDERS_PER_PAGE = 10;
